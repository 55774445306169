import React, { useState, useEffect } from 'react'

// Api
import { reviewListService, reviewToolService } from '../../api'

//Components
import StarRatings from 'react-star-ratings'
import RatingBar from './RatingBar'

function RatingCard (props) {
    const { count, ratingStars, rating } = props

    let rounded_rating = rating ? rating.toFixed(1) : null

    return (
        <div className='rating-wrapper my-20 flexer'>
            <div className='flexer-3'>
                <div className='center'>
                    <p className='title-xl line-margin-0'>{rounded_rating}</p>
                    {rating && <StarRatings rating={rating} starDimension="20px" starSpacing="3px" starRatedColor="#76CAA0" />}
                </div>
                <div className='mx-30'>
                    <p className='text is-light'><span className='fa fa-user is-light mr-5' ></span> {count} total</p>
                </div>
            </div>
            <div className='flexer-9 my-10'>
                {rating && <RatingBar ratingStars={ratingStars} rating={rating} count={count} />}
            </div>
        </div>
    )
}

export default RatingCard