import icChrome from '../../assets/svg/ic_chrome.svg'
import imgFirefox from '../../assets/img/img_firefox.png'
import icBrave from '../../assets/svg/ic_brave.svg'
import icOpera from '../../assets/svg/ic_opera.svg'
import styled from 'styled-components'

const PluginTutorial = () => <SectionStyled>
		<h1>You don't have the TORCH Toolkit Plugin installed.</h1>
		<article>
			<iframe
					src="https://www.youtube.com/embed/gZkmztUFsoQ"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
			/>

			<aside>
				<h4>Supported browsers</h4>
				<nav>
					<a href={'#'} style={{display: 'flex'}}>
						<img src={icChrome} alt={'Chrome Icon'}/>
					</a>
					<a href={'#'} style={{display: 'flex'}}>
						<img src={imgFirefox} alt={'Firefox Icon'}/>
					</a>
					<a href={'#'} style={{display: 'flex'}}>
						<img src={icBrave} alt={'Brave Icon'}/>
					</a>
					<a href={'#'} style={{display: 'flex'}}>
						<img src={icOpera} alt={'Opera Icon'}/>
					</a>
				</nav>
			</aside>
		</article>

	</SectionStyled>


const SectionStyled = styled.section`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: space-between;
	height: 100%;

  h1 {
    margin-top: 0;
    font-size: 1.25em;
    text-align: center;
    margin-bottom: 1rem;
  }

  article {
    display: flex;
		height: 100%;
    column-gap: 1rem;
    
    iframe {
      width: 75%;
      height: 75%;
    }
    
    h4{
      margin: 0;
      font-size: 1rem;
    }
    
    aside{
      nav{
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(4,1.5rem);
        a {
          img {
            width: 1.5rem;
          }
        }
      }
    }
  }
`

export default PluginTutorial
