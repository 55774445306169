import React, {useState, useEffect} from 'react'

function Textarea(props) {

    const [value, setValue] = useState(null)

    useEffect(() => {
        if (props.value){
            setValue(props.value)
        }
    }, [])

    function handleChange(e){
        setValue(e.target.value)
    }

    var className = 'input-field'
    if (props.class) {
        className = props.class
    }

    var textarea = ""
    if(value){
        textarea = <textarea name={props.name} className="input-field__input" required={props.required? "required" : false} placeholder={props.placeholder} value={value} onChange={handleChange}/>
    }
    else {
        textarea = <textarea name={props.name} className="input-field__input" required={props.required? "required" : false} placeholder={props.placeholder} />
    }

    return (
        <div className={className}>
            <label className="input-field__label" >{props.label}</label>
            {textarea}
            <p className="error-message">{props.error}</p>
        </div>
    )
}

export default Textarea