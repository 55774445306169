import React from 'react'

import { BehaviorSubject } from 'rxjs'

const currentModeSubject = new BehaviorSubject(localStorage.getItem('themeMode'))

export const toggleService = {
    currentModeSubject,
    get currentModeValue () { return currentModeSubject.value }
}

function ThemeToggle(props){

    function handleClick() {
        var body = document.getElementById('body-wrapper')
        body.classList.toggle('light')
        body.classList.toggle('dark')

        var new_mode = currentModeSubject.value === "dark" ? "light" : "dark"
        console.log(new_mode, currentModeSubject.value )
        localStorage.setItem('themeMode', new_mode)
        currentModeSubject.next(new_mode)
        props.onChange(new_mode)
    }

    var checked = false
    if (currentModeSubject.value === "dark"){
        checked = true
    }
    return (
        <div className="toggle-wrapper mobile-hidden">
            <p className="text is-light toggle">Dark Theme:</p>
            <div className="toggle-switch">
                <input type="checkbox" className="toggle-switch-checkbox" name="dark" id="dark-theme" onClick={handleClick} checked={checked}/>
                <label className="toggle-switch-label" htmlFor="dark-theme">
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                </label>
            </div>
        </div>
    )
}

export default ThemeToggle