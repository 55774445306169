import React , { useState} from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import ListDetailCard from '../components/Lists/ListDetailCard'



function Lists() {

  const [refresh, setRefresh] = useState(1)
  
  return (
    <React.Fragment>
      <Header />
        <Content isListDetail={true}>
          <ListDetailCard onUpdate={() => setRefresh(refresh + 1)}/>
        </Content>
    </React.Fragment>
  )
}

export default Lists
