import React, { useState, useEffect } from 'react'

//Components
import Button from '../Elements/Button'
import ReviewForm from './ReviewForm'
import RatingCard from './RatingCard'


function ReviewsSection(props) {
    const { element, element_id, onUpdate, pagination, user, count, ratingStars, rating } = props

    function openForm() {
        var element = document.getElementById('form')
        element.classList.toggle("shown")
        element.classList.toggle("hidden")
    }

    function closeForm(refresh=false) {
        if (refresh){
            onUpdate()
        }
        var element = document.getElementById('form')
        element.classList.toggle("shown")
        element.classList.toggle("hidden")
    }

    let class_name
    if (props.element === 'tool') {
        class_name = 'rev-section__tool'
    } else if (props.element === 'list') {
        class_name = 'rev-section__list'

    }

    return (
        <div className={class_name}>
            <div className="flexer flexer-spaced flexer-mobile mb-15">
                <h1 className="title is-dark">{props.title}</h1>
                {props.allow_edit? null : <Button text={props.button} class={props.btnClass} icon="fa fa-plus is-white" onClick={openForm}/>}
            </div>
            <div id='form' className="form-wrapper hidden">
                <ReviewForm 
                    user={user} 
                    element={props.element} 
                    element_id={props.element_id} 
                    onClose={closeForm}
                />
            </div>
            {  count &&  <RatingCard 
                            rating={rating} 
                            ratingStars={ratingStars} 
                            count={count} 
                            element={element} 
                            element_id={element_id}
                        />}
            {props.children}
        </div>
    )
}

export default ReviewsSection