import React from 'react'

// Containers
import Header from '../containers/Header'
import TagsContent from '../containers/Content/TagsContent'
import Content from '../containers/Content'

function Tags() {
  return (
    <React.Fragment>
      <Header />
        <Content>
          <TagsContent />
        </Content>
    </React.Fragment>
  )
}

export default Tags
