import React, {useState} from 'react'

// Components
import Button from '../../Elements/Button'
import Textarea from '../../Elements/Textarea'
import StarRatings from 'react-star-ratings'

// API
import {profileService, authenticationService} from '../../../api'

function ReviewForm(props) {
    const [errors, setErrors] = useState({})
    const [rating, setRating] = useState(0)

    const logged_user = authenticationService.basicInfoValue

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('reviews-form')
        var form_data = new FormData(form_element)

        profileService.add_review(form_data)
        .then((response) => {
            props.onClose(true)
        })
        .catch(error => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        })

    }

    function handleChange(new_rating){
        setRating(new_rating)
    }

    return (
        <form id="reviews-form" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Evaluate the work of the user</h1>
            <div className="row reviewer-wrapper flexer flexer-vcenter">
                <div className="col-5 flexer flexer-vcenter ">
                    <img className="profile-menu__image" src='https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png' alt="profile"  />
                    <div className="mx-15">
                        <p className="heading is-dark line-margin-0" >{logged_user.name}</p>    
                        <p className="subheading is-dark line-margin-0" >{logged_user.occupation}</p>
                    </div>
                </div>
                <div className="col-7 rating-box center flexer flexer-vcenter">
                    <StarRatings rating={rating} starDimension="24px" starSpacing="8px" starRatedColor="#44C568" error={errors.rating} changeRating={handleChange}/>
                    <input type="hidden" name="rating" value={rating}/>
                    <span className="rating-text rating-text-setter mx-10">{`${rating}.0`}</span>
                </div>
            </div>
            <input type="hidden" name="profile" value={props.user.profile.id}/>
            <p className="subtitle is-dark mt-60">Write a review please</p>
            <Textarea name="review" label="Review" class="input-field line-margin-0" required={true} error={errors.review || errors.non_field_errors} />
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button"  onClick={props.onClose}/>
                <Button text="Add review" class="blue-btn mt-30 subheading is-white" type="submit"/>
            </div>
        </form>
    )
}

export default ReviewForm