import React from 'react'

function RatingBar (props) {
    const { ratingStars, count } = props

    let star_ratio = {}
    Object.entries(ratingStars).forEach((element) => {
        star_ratio[element[0]] = element[1] * 100 / count
    })

    let barLength = {}
    Object.entries(star_ratio).forEach(element => {
        if (element[1] > 90) {
            barLength[element[0]] = 'flexer-12'
        }
        else if (element[1] > 80) {
            barLength[element[0]] = 'flexer-11'
        }
        else if (element[1] > 70) {
            barLength[element[0]] = 'flexer-10'
        }
        else if (element[1] > 60) {
            barLength[element[0]] = 'flexer-9'
        }
        else if (element[1] > 50) {
            barLength[element[0]] = 'flexer-8'
        }
        else if (element[1] > 40) {
            barLength[element[0]] = 'flexer-7'
        }
        else if (element[1] > 30) {
            barLength[element[0]] = 'flexer-6'
        }
        else if (element[1] > 20) {
            barLength[element[0]] = 'flexer-4'
        }
        else if (element[1] > 10) {
            barLength[element[0]] = 'flexer-2'
        }
        else if (element[1] > 0) {
            barLength[element[0]] = 'flexer-1'
        }
        else if (element[1] === 0) {
            barLength[element[0]] = 'flexer-0'
        }
    })

    return (
        <React.Fragment>
            <div className='flexer flexer-vcenter mb-5 mx-10'>
                <p className='text is-light line-margin-0 mx-10'>5</p>
                <div className={`rating-bar-5 line-margin-0 ${barLength.fiveStar}`}></div>
            </div>
            <div className='flexer flexer-vcenter mb-5 mx-10'>
                <p className='text is-light line-margin-0 mx-10'>4</p>
                <div className={`rating-bar-4 line-margin-0 ${barLength.fourStar}`}></div>
            </div>
            <div className='flexer flexer-vcenter mb-5 mx-10'>
                <p className='text is-light line-margin-0 mx-10'>3</p>
                <div className={`rating-bar-3 line-margin-0 ${barLength.threeStar}`}></div>
            </div>
            <div className='flexer flexer-vcenter mb-5 mx-10'>
                <p className='text is-light line-margin-0 mx-10'>2</p>
                <div className={`rating-bar-2 line-margin-0 ${barLength.twoStar}`}></div>
            </div>
            <div className='flexer flexer-vcenter mb-5 mx-10'>
                <p className='text is-light line-margin-0 mx-10'>1</p>
                <div className={`rating-bar-1 line-margin-0 ${barLength.oneStar}`}></div>
            </div>
        </React.Fragment>
    )
}

export default RatingBar