import React from 'react'

// Containers
import Header from '../containers/Header'
import ListsContent from '../containers/Content/ListsContent'
import Content from '../containers/Content'

function Lists() {
return (
  <React.Fragment>
    <Header />
      <Content>
        <ListsContent />
      </Content>
  </React.Fragment>
  )
}

export default Lists
