import React, { useState, useEffect } from "react";

// Api
import { toolService } from "../../../api";
// Components
import Tool from "../../Elements/Tool";
import Checkbox from "../../Elements/Checkbox";

function StepTwoForm(props) {
  let obj = {};
  if (props.edit) {
    props.editData.tools.forEach((tool) => (obj[tool.id] = tool));
  }
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [selectedTools, setSelectedTools] = useState({});
  const [currentTools, setCurrentTools] = useState(obj);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setSelectedTools(currentTools);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  function handleSubmit(e) {
    e.preventDefault();

    var form_element = document.getElementById("create-list-form");
    var form_data = new FormData(form_element);

    var body = props.data; // from StepOneFormCustomList
    form_data.forEach((value, key) => {
      if (value) {
        body[key] = value;
      }
    });
    props.onUpdate({ ...body });
  }

  function handleSearch(e) {
    if (e.target.value.length > 1) {
      setInputValue(e.target.value);
      toolService
        .filter({ keyword: inputValue, approved: true })
        .then((response) => {
          setSearchResults(response.results);
          setNext(response.next && response.next.replace("8000", "3001"));
          setPrev(
            response.previous && response.previous.replace("8000", "3001")
          );
        })
        .catch((error) => {
          console.error("Not found", error);
        });
    }
  }

  function handlePagination(url) {
    toolService
      .filterByUrl(url, { approved: true, keyword: inputValue })
      .then((response) => {
        setSearchResults(response.results);
        setNext(response.next && response.next.replace("8000", "3001"));
        setPrev(response.previous && response.previous.replace("8000", "3001"));
      })
      .catch((error) => {
        console.log("Error loading tools", error);
      });
  }

  function onSelect(e) {
    let new_obj = selectedTools;
    let is_selected = selectedTools && Object.keys(selectedTools).includes(e.id.toString());
    if (is_selected) {
      delete new_obj[e.id];
      setCurrentTools(new_obj);
      setRefresh(refresh + 1);
    } else {
      setCurrentTools({ ...new_obj, [e.id]: e });
      setRefresh(refresh + 1);
    }
  }

  let filtered_tools = searchResults.filter(tool => {
    return !Object.keys(selectedTools).includes(tool.id.toString()) ? true : false;
  });

  let tools = filtered_tools.map((tool) => {
    let is_checked = selectedTools
      ? Object.keys(selectedTools).includes(tool.id.toString())
      : false;

    return (
      <div className="flexer wcc-tool" key={tool.id}>
        <div className="mt-10">
          <Checkbox
            name="tools"
            key={tool.id}
            value={tool.id}
            checked={is_checked}
            onSelect={() => onSelect(tool)}
          />
        </div >
        <Tool data={tool} />
      </div>
    );
  });

  const handleDelete = (tool_id) => {
    let new_obj = selectedTools
    delete new_obj[tool_id]
    setCurrentTools(new_obj)
    setRefresh(refresh + 1)
  }

  let show_selected = currentTools && Object.values(currentTools).map((tool) => {
      return (
          <div className="flexer flexer-vcenter mr-40" key={tool.id + 100000}>
            <i className='fa fa-trash is-light cursor mr-10 mb-30' onClick={() => handleDelete(tool.id)} />
            <Tool data={tool} key={tool.id + 100000} />
            <input type="hidden" name="tools" value={tool.id} />
          </div>
      );
    });

  let errors_ = props.errors ? (
    <p className="error-message">{props.errors.errors}</p>
  ) : null;

  return (
    <div className="center my-20">
      <div className="form-wrapper">
        <p className="heading line-margin-0 left">
          {props.edit ? "Select new tools" : "Select tools for list"}
        </p>
        <div className="input-wrapper input-field__input my-20">
          <input
            className="search-input-2 text is-dark"
            type="text"
            placeholder={"Enter keywords for quick search..."}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div className="my-20">
          <div className="flexer flexer-wrapper wcc-tools">
            {searchResults.length ? (
              tools
            ) : inputValue ? (
              <p className="text line-margin-0">Tool not found</p>
            ) : null}
          </div>
        </div>
        <div className="flexer right">
          {prev ? (
            <p
              className="subheading is-dark button blue-btn cursor"
              onClick={() => handlePagination(prev)}
            >
              <i className="fa fa-arrow-left mr-10 is-white"></i>Previous
            </p>
          ) : null}
          <p className="subheading is-light mx-10"></p>
          {next ? (
            <p
              className="subheading is-dark button blue-btn cursor"
              onClick={() => handlePagination(next)}
            >
              Next<i className="fa fa-arrow-right ml-10 is-white"></i>
            </p>
          ) : null}
        </div>
        <form id="create-list-form" className="left" onSubmit={handleSubmit}>
          <p className="subheading is-light">Selected tools:</p>
          <div className="flexer flexer-wrapper wcc-tools">{show_selected}</div>
        </form>
        {selectedTools && Object.keys(selectedTools).length === 0 && (
          <div className="left">{errors_}</div>
        )}
      </div>
    </div>
  );
}

export default StepTwoForm;