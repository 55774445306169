import React from 'react'

// Containers
import Header from '../containers/Header'
import Content from '../containers/Content'
import ProfileContent from '../containers/Content/ProfileContent'

function Profile() {

  return (
    <React.Fragment>
      <Header />
        <Content>
          <ProfileContent />
        </Content>
    </React.Fragment>
  )
}

export default Profile
