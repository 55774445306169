import { useState, useEffect }from 'react'
import ReactCountryFlag from "react-country-flag"
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Logo from '../../assets/images/picture.png'

import Tooltip from '../Elements/Tooltip'
import ReviewContent from '../../containers/Content/ReviewContent'
import Modal from '../Elements/Modal'
//mixpanel
import { mixpanelService } from '../../api/mixpanel-service.js'



function Tool(props) {
    const { data: { country, country_patronage, description } } = props

    const tool_pic = props.data.tool_pic ?? Logo

    const tool_rating = props.data.static_rating ?? '-'

    const top_tool = props.isTop ? <span className="text-xs is-light tool-text-setter mx-10">Reviews: {props.data.reviews_count}</span> : null


    const [isOpen, setIsOpen] = useState(false)
    const [refresh, setRefresh] = useState(1)
  
    const [toolId, setToolId] = useState(null)
    
    function handleClick(tool_id) {
        mixpanelService.ReviewOpened(props.data)
        setToolId(tool_id)
        setIsOpen(true)
    }

    const handleUpdate = () => {
        setRefresh(refresh + 1)
        setIsOpen(false)
        refresh > 1 && props.onUpdate() // only call service if the tool was updated
    }

    let country_flag =
        country_patronage === null ?
            <Tooltip content='Unknown Country of Patronage' direction='top'>
                <p className='tool-noFlag line-margin-0'>?</p>
            </Tooltip>
            : <Tooltip content={country ? country : country_patronage} direction='top'>
                <ReactCountryFlag countryCode={country_patronage} svg />
            </Tooltip>


    if (props.data.active) {
        return (
            <div className='flexer mb-30 mr-10'  >
                <a href={`${props.data.url}`} target="_blank" rel='noreferrer' >
                    <div className="tool-icons flexer flexer-vcenter" onClick={() => mixpanelService.OpenTool(props.data)}>
                        <LazyLoadImage className='center' src={tool_pic} alt="Logo" title={description}/>
                    </div>
                </a>
                <div className='mx-15 mt-5'>
                    <a href={`${props.data.url}`} target="_blank" rel='noreferrer' >
                        <div className='flexer flexer-vcenter tool-name' >
                            <p className=' text is-bold line-margin-0 mr-10'> {props.data.name} {country_flag}</p>
                        </div>
                    </a>
                    <div className='flexer left mt-5'>
                        <p className='text is-green line-margin-0 is-bold' onClick={() => handleClick(props.data.id)} title='Reviews' >{tool_rating}<i className='is-green line-margin-0 fa fa-star ml-5'/></p>
                        {top_tool}
                    </div>
                </div>
                <Modal reviews={true} isOpen={isOpen} handleClose={handleUpdate}>
                    <ReviewContent
                    element={'tool'}
                    element_id={toolId}
                    onUpdate={() => setRefresh(refresh + 1)}
                    onClose={handleUpdate} />
                </Modal>
            </div>
        )
    }

    return (
        <div className='flexer mb-30 mr-10'>
            <div className="tool-icons tool-icons__inactive flexer flexer-vcenter">
                <img className='center' src={tool_pic} alt="Logo" title={description}/>
            </div>
            <div className='mx-15 mt-5'>
                <div className='flexer flexer-vcenter tool-name' >
                    <p className=' text is-bold line-margin-0 mr-10'> {props.data.name} </p>
                    {country_flag}
                </div>
                <div className='flexer left mt-5'>
                    <p className='text is-grey line-margin-0 is-bold' onClick={() => handleClick(props.data.id)} title='Reviews'>{tool_rating}<i className='is-grey line-margin-0 fa fa-star ml-5' /></p>
                    {top_tool}
                </div>
            </div>
            <Modal reviews={true} isOpen={isOpen} handleClose={handleUpdate}>
                <ReviewContent
                element={'tool'}
                element_id={toolId}
                onUpdate={() => setRefresh(refresh + 1)}
                onClose={handleUpdate} />
            </Modal>
        </div>
    )
}

export default Tool