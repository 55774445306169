import {Box, Button, Modal} from '@mui/material'
import {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {toast} from 'react-toastify'

// Containers
import ToolsContent from '../containers/Content/ToolsContent'
import Content from '../containers/Content'
import Header from '../containers/Header'
import {showCidVideo} from '../api'
import {UserContext} from '../components/Hooks/UserContext'

const Tools = () => {
	const {handleSubmit, register} = useForm()

	const {currentUser, setCurrentUser} = useContext(UserContext)

	const [showModal, setShowModal] = useState(false)

	const hdlCloseModal = ({dont_show}) => {
		if (dont_show) {
			showCidVideo(currentUser.cognito_id, false).then((resp) => {
				setCurrentUser(resp)
				setShowModal(false)
			}).catch(({message}) => {
				toast.error(`${message}`)
			})
		} else {
			setShowModal(false)
		}
	}

	useEffect(() => {
		if (currentUser?.show_cid_video === true) setShowModal(true)
	}, [])

	return (<>
		<Header/>
		<div className="flexer mt-30">
			<div className="flexer flexer-spaced flexer-10 flexer-vcenter center">
				<p className="title flexer-8 left">TORCH - Toolkit</p>
				<p className="text is-bold flexer-4 right">
					Please send all feedback, questions, or requests for more
					information to{' '}
					<a className="is-blue" href="mailto:platformsupport@echoanalyticsgroup.com">
					platformsupport@echoanalyticsgroup.com
					</a>
				</p>
			</div>
		</div>
		<Content>
			<ToolsContent/>
		</Content>
		<Modal open={showModal} style={{display: 'grid'}}>
			<BoxStyled>
				<h1>Welcome to Torch Toolkit</h1>
				<iframe
						src="https://www.youtube.com/embed/hrFX6ugW32s"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
				/>
				<form onSubmit={handleSubmit(hdlCloseModal)}>
					<FieldsetStyled>
						<input
								type="checkbox"
								id="dont_show"
								{...register('dont_show')}
						/>
						<label htmlFor="dont_show">Don’t show this again</label>
					</FieldsetStyled>

					<Button type={'submit'} variant={'contained'} fullWidth={true}>
						Next
					</Button>
				</form>
			</BoxStyled>
		</Modal>
	</>)
}

const BoxStyled = styled(Box)`
  inset: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem;
  width: fit-content;
  border-radius: 5px;
  background-color: var(--card-bg);

  h1 {
    margin-top: 0;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 10px;
  }

  iframe {
    width: 25vw;
    height: 30vh;
  }
`

const FieldsetStyled = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 10px 0;
`

export default Tools
