import config from './config'
import {handleResponse} from './handle-response'
import {fixBody} from './helpers'
import axios from 'axios'

export const userService = {
	details,
	get_lms_url,
	search,
	list,
	update,
	request_verification,
	deny_verification,
	accept_terms_cid,
	delete_user,
	request_org,
	verify_attribute,
	create_feedback,
}

async function details() {
	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(`${config.apiUrl}/users/details/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}
async function get_lms_url () {
	const requestOptions = {
		method     : 'GET',
		credentials: 'include'
	}

	return fetch(
		`${ config.apiUrl }/users/lsm_url/`,
		requestOptions
	)
		.then(handleResponse)
		.then((url) => {
			return url
		})
}
async function search(keyword, org_id) {
	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(
			`${config.apiUrl}/organization/${org_id}/users/search/?keyword=${keyword}`,
			requestOptions).then(handleResponse).then((user) => {
		return user
	})
}

async function list(org_id) {
	// migrada a memberService, confirmar si la elimino

	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(`${config.apiUrl}/organization/${org_id}/members/`,
			requestOptions).then(handleResponse).then((user) => {
		return user
	})
}

async function update(body, user_id) {
	body = fixBody(body)
	const requestOptions = {
		method: 'PATCH',
		credentials: 'include',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(body),
	}
	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}

async function request_verification(user_id) {
	const requestOptions = {method: 'POST', credentials: 'include'}
	return fetch(`${config.apiUrl}/users/${user_id}/request_verification/`,
			requestOptions).then(handleResponse).then((user) => {
		return user
	})
}

async function deny_verification(user_id, body) {
	body = fixBody(body)

	const requestOptions = {method: 'POST', credentials: 'include', body}
	return fetch(`${config.apiUrl}/users/${user_id}/deny_verification/`,
			requestOptions).then(handleResponse).then((user) => {
		return user
	})
}

async function accept_terms_cid(body, user_id) {
	body = fixBody(body)
	const requestOptions = {
		method: 'PATCH',
		credentials: 'include',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(body),
	}
	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}

export const showCidVideo = async (userId, show_cid_video) => {
	try {
		const {data} = await axios.patch(`/users/${userId}/`, {
			show_cid_video: show_cid_video,
		})

		return data
	} catch (err) {
		return err
	}
}

async function delete_user(user_id) {
	const requestOptions = {method: 'DELETE', credentials: 'include'}
	return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}

async function request_org(body) {
	body = fixBody(body)
	const requestOptions = {method: 'POST', credentials: 'include', body}
	return fetch(`${config.apiUrl}/users/invitations/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}

async function verify_attribute(body) {
	const bodyFixed = fixBody(body)
	const requestOptions = {
		method: 'POST',
		credentials: 'include',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(bodyFixed),
	}

	return fetch(`${config.apiUrl}/users/verify_user_attribute/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}

async function create_feedback(body) {
	let object = {}
	body.forEach((value, key) => (object[key] = value))

	const requestOptions = {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(object),
		credentials: 'include',
	}

	return fetch(`${config.apiUrl}/jira/create_issue/`, requestOptions).
			then(handleResponse).
			then((user) => {
				return user
			})
}
