import React from 'react'

// Components
import WizardResultsForm from './WizardResultsForm'

function WizardResults(props) {
    
    return (
        <React.Fragment>
            <div className="flexer">
                <h1 className="title-l line-margin-0">Hunting wizard</h1>
            </div>
            <WizardResultsForm onUpdate={props.onUpdate} data={props.data} toolsSelected={props.toolsSelected} errors={props.errors} />
        </React.Fragment>
    )
}

export default WizardResults