import { BehaviorSubject } from 'rxjs'

import config from './config'
import { handleResponse } from './handle-response'
import { mixpanelService } from './mixpanel-service'



const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))
const basicInfoSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('basicInfo')))
const organizationSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('orgMember')))

export const authenticationService = {
    login,
    twoFA,
    logout,
    refreshToken,
    currentUser: currentUserSubject.asObservable(), 
    get currentUserValue () { return currentUserSubject.value },
    basicInfo: basicInfoSubject.asObservable(),
    get basicInfoValue () { return basicInfoSubject.value },
    orgMember: organizationSubject.asObservable(),
    get organizationsMemberValue () { return organizationSubject.value}, 
    updateBasicInfo,
    updateOrgMember
}

function updateBasicInfo(value){
    localStorage.setItem('basicInfo', JSON.stringify(value))
    basicInfoSubject.next(value)
}

function updateOrgMember(response) {
    localStorage.setItem('orgMember', JSON.stringify(response))
    organizationSubject.next(response)
}

function login(body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}/auth/initiate_auth/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
        
}

function twoFA(body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(`${config.apiUrl}/auth/respond_to_auth_challenge/`, requestOptions)
        .then(handleResponse)
        .then(tokens => {
            // organizationService.listByUser(tokens.user_id)  // org are now in global context
            return tokens
        })
}


async function refreshToken() {

    const requestOptions = {
        method: 'GET',
        credentials: 'include',
    }

    await fetch(`${config.apiUrl}/auth/refresh_token/`, requestOptions)
        .then((response) => {
            return handleResponse(response)
        })
        .then(user => {
            return user
        })
        .catch(error => {
            console.log("failing refreshing token", error)
            logout()
        })
    }
    
    async function logout() {
        await fetch(`${config.apiUrl}/auth/logout/`)
        .then((response) => {
            mixpanelService.Logout()
            console.log('Logging out')
        })
    }
    