import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {UserContext} from '../../components/Hooks/UserContext'
import Modal from '../Elements/Modal'
import FeedbackForm from '../Profile/FeedbackForm'

// API
import {authenticationService} from '../../api'
import config from '../../api/config'
 

const ProfileMenu = () => {

	const {currentUser} = useContext(UserContext)
	const [isOpen, setIsOpen] = useState(false)

	if (currentUser.cognito_id) {
		var user_id = '/profile/' + currentUser.cognito_id
	}

	function ToggleProfileMenu(e) {
		var element = document.getElementById('profile-list')
		if (element.classList.contains('hidden')) {
			element.classList.remove('hidden')
			element.classList.add('shown')
		} else {
			element.classList.add('hidden')
			element.classList.remove('shown')
		}
	}

	function handleLogout() {
		authenticationService.logout().then(response => {
			window.location.assign(`${config.home}/login`)
			console.log(response)
		}).catch(error => {
			console.error('Logout failed', error)
		})
	}

	var name = currentUser.public_name
	var initials = Array.prototype.map.call(name.split(' '), function(x){ return x.substring(0,1).toUpperCase();}).join('');
 
	return <>

		<div className='ml-10'>{currentUser?.profile?.profile_pic ? (<img
					 className='MenuProfileImage'
					 src={currentUser?.profile?.profile_pic}
					 />) : (<div className='MenuProfileImage'>{initials}</div>)}</div>
		<div className="right cursor center-mobile my-10"
				 onClick={ToggleProfileMenu}>
			<input className="profile-menu__checkbox" type="checkbox"
						 id="profile2"/>
	 
			<span 
					className="profile-menu__name subheading is-dark">{name}</span>
			<i className="fas fa-angle-down text-icon"/>
			<ul id="profile-list"
					className="profile-menu__list hidden subheading is-dark">
				<Link to={user_id}>
					<li className="profile-menu__item cursor">Profile</li>
				</Link>
				<li
						className="profile-menu__item cursor"
						onClick={() => {
							setIsOpen(true)
						}}
				>
					Leave Feedback
				</li>
				<li className="profile-menu__item cursor"
						onClick={handleLogout}>Logout
				</li>
			</ul>
		</div>
		<Modal
				isOpen={isOpen}
				handleClose={() => {
					setIsOpen(false)
				}}
		>
			<FeedbackForm
					onClose={() => {
						setIsOpen(false)
					}}
			/>
		</Modal>

	</>

}

export default ProfileMenu
