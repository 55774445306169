import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

// Api
import { listService } from '../../../api'

// Components
import Modal from '../../Elements/Modal'
import StepOne from './StepOne'
import StepTwo from './StepTwo'

function CreateCustomList(props) {
    const [stage, setStage] = useState("start")
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [toolsSelected, setToolsSelected] = useState([])

    useEffect(() => {
        if (props.id) {
        const { name, description, tags } = props.data
        let current_data = { name : name, description: description, tags: tags }
            setData(current_data)
        }
    },[props.id, props.data])


    function changeData(new_data){
        setData({...data, ...new_data})
    }

    function handleSubmit(){
        var form_element = document.getElementById('create-list-form')
        var form_data = new FormData(form_element)

        var body = data
        let tags = false
        let tools = []

        form_data.forEach((value, key) => {
            if(value) {
                if (key === 'tags'){
                    if (!('tags' in body) || !tags){
                        body['tags'] = []
                    }
                    body['tags'].push(value)
                    tags = true
                }
                else if (key === 'tools'){
                    tools.push(parseInt(value))
                }
                else{
                    body[key] = value
                }
            }
        })
        setData({...body, tools})

        if (tools.length) {
            setToolsSelected(tools)
        }

        if(stage === 'start'){
            
            let errors_ = {}
            let requiredFields = ['name', 'description', 'tags']
            for(const field of requiredFields){
                if (field == "tags" && form_data["tags"] && !form_data["tags"].length) {
                    errors_[field] = 'This field is required'
                } else {
                    if(!(field in body)){
                        errors_[field] = 'This field is required'
                    }
                }
            }
            if (Object.values(errors_).length === 0){
                setStage("stepTwo")
            }
            else{
                setErrors({...errors_})
            }
        }
        else if(stage === 'stepTwo'){
            if (tools.length) {
                if (props.edit) {
                    listService.update(body, props.id)
                    .then(response => {
                        setErrors({})
                        setData(response)
                        listService.add_tools(response.id, {tools})
                        .then(response => {
                            setStage("completed")
                        })
                        .catch(error => {
                            console.error("There was a problem updating the list", error)
                            if(Array.isArray(error)){
                                error = {non_field_errors: error[0]}
                            }
                            setErrors({...error})
                        })
                    })
                    .catch(error => {
                        console.error("There was a problem updating the list", error)
                        if(Array.isArray(error)){
                            error = {non_field_errors: error[0]}
                        }
                        setErrors({...error})
                    })

                }
                else {
                    listService.create(body)
                    .then(response => {
                        setErrors({})
                        setData(response)
                        listService.add_tools(response.id, {tools})
                        .then(response => {
                            setStage("completed")
                        })
                        .catch(error => {
                            console.error("There was a problem creating the list", error)
                            if(Array.isArray(error)){
                                error = {non_field_errors: error[0]}
                            }
                            setErrors({...error})
                        })
                    })
                    .catch(error => {
                        console.error("There was a problem creating the list", error)
                        if(Array.isArray(error)){
                            error = {non_field_errors: error[0]}
                        }
                        setErrors({...error})
                    })
                }
            } else {
                setErrors({errors:'At least one tool is required'})
            }
        }
    }

    var button1 = {
        'start': {text: 'Cancel', funct: props.handleClose},
        'stepTwo': {text: 'Previous Step', funct: () => {setStage("start")}}
    }

    let button_text =  props.edit ? 'Save' : 'Create list'
    
    var button2 = {
        'start': {text: 'Next Step', funct: handleSubmit},
        'stepTwo': {text: button_text, funct: handleSubmit}
    }

    var stageComponent
    if (stage === "start"){
        stageComponent = <StepOne 
                            onUpdate={changeData} // pending to display data when going to the previous step
                            data={data}
                            errors={errors}
                            edit={props.edit ?? false}
                            editData={props.data}
                            />
    } else if (stage === "stepTwo"){
        stageComponent = <StepTwo
                            onUpdate={changeData} // pending to display data when going to the previous step
                            data={data}
                            errors={errors}
                            toolsSelected={toolsSelected}
                            edit={props.edit ?? false}
                            editData={props.data}
                            />
    }
    else if (stage === "completed"){
        props.edit && props.onUpdate()
        return <Redirect to={{pathname: `/lists/detail/${data.id}`, state: {first: true}, key: Math.random()}}></Redirect>
    }

    return (
        <Modal isOpen={props.isOpen} fullScreen={true} buttons={{button1: button1[stage], button2: button2[stage]}} handleClose={props.handleClose}>
            {stageComponent}
        </Modal>
    )

}

export default CreateCustomList