import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'

// Api
import { listService, toolService, tagService } from '../../api'

// Components
import TagsFilters from '../../components/Tags/TagsFilters'
import TagsDashboard from '../../components/Tags/TagsDashboard'



function TagsContent() {

    const { tag } = useParams()
    const [tagsResult, setTagsResult] = useState([])

    const [selectedCategories, setSelectedCategories] = useState([])
    const [isFilterActive, setIsFilterActive] = useState(false)
    const [refresh, setRefresh] = useState(1)

    const [dataLists, setDataLists] = useState([])
    const [nextLists, setNextLists] = useState(null)
    const [prevLists, setPrevLists] = useState(null)


    const [dataTools, setDataTools] = useState([])
    const [nextTools, setNextTools] = useState(null)
    const [prevTools, setPrevTools] = useState(null)

    useEffect(() => {
        tagService.search(tag)
        .then(response => {
            let tag_id = response.map(e => e.id)
            setTagsResult(tag_id)
        })
        .catch(error=>{
            console.log("Error loading tools", error)
        })
    }, [tag])

    useEffect(() => {

        let filters = {
            categories: selectedCategories,
            tags: tagsResult
        }

        if (filters.categories.length === 0) {
            delete filters['categories']
        }

        listService.filter(filters)
        .then(response => {
            setDataLists(response.results)
            setNextLists(response.next)
            setPrevLists(response.previous)
        })
        .catch(error => {
            console.error('Not found',error)
        })
        toolService.filter({...filters, approved: true})
        .then(response => {
            setDataTools(response.results)
            setNextTools(response.next)
            setPrevTools(response.previous)
        })
        .catch(error => {
            console.error('Not found',error)
        })
    }, [refresh, selectedCategories, tagsResult])

    function handlePaginationTools(url){  
        toolService.listByUrl(url)
        .then(response=>{
            let approved = response.results.filter(tool => tool.approved)
            setDataTools(approved)
            setNextTools(response.next)
            setPrevTools(response.previous)
            setRefresh(refresh + 1)
        })
        .catch(error=>{
            console.log("Error loading tools", error)
        })
    }

    function handlePaginationLists(url){  
        listService.listByUrl(url)
        .then(response=>{
            setDataLists(response.results)
            setNextLists(response.next)
            setPrevLists(response.previous)
            setRefresh(refresh + 1)
        })
        .catch(error=>{
            console.log("Error loading lists", error)
        })
    }

    function handleCategories(categories) { 
        setSelectedCategories(categories);
        setIsFilterActive(true)
    }

    function handleReset() {
        setSelectedCategories([])
        setIsFilterActive(false)
        setRefresh(refresh + 1)
    }
    
    return (
        <React.Fragment>
            <div className="flexer flexer-mobile">
                <div className="flexer-3 center">
                    <div className="simple-profile">
                        <TagsFilters
                        handleCategories={handleCategories}
                        isFilterActive={isFilterActive}
                        handleReset={handleReset}
                        />
                    </div>
                </div>
                <div className="flexer-9 center">
                    <div className="flexer center-mobile">
                        <TagsDashboard
                        dataLists={dataLists}
                        dataTools={dataTools}
                        nextLists={nextLists}
                        nextTools={nextTools}
                        prevLists={prevLists}
                        prevTools={prevTools}
                        handlePaginationLists={handlePaginationLists}
                        handlePaginationTools={handlePaginationTools}
                        onUpdate={() => setRefresh(refresh + 1)}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TagsContent
