import React from 'react'

function Content(props) {
    if (props.isListDetail) {
        return (
            <div className="flexer">
                <div className="content-wrapper flexer-8">
                    {props.children}
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="content-wrapper col-10">
                {props.children}
            </div>
        </div>
    )
}

export default Content