import React from 'react'

// Components
import SearchTag from './SearchTag'

const SearchBar = (props) => {
  const {
    handleActive,
    handleClose,
    handleOpen,
    isActive,
    placeholder,
    noBg,
    tags,
    onChange,
    searchValue,
    setSearchValue,
  } = props

  const handleChange = (e) => {
    if(setSearchValue){
      setSearchValue(e.target.value)
    }else{
      // Added to surrpor legacy searchs       
        if (e.target.value.trim().length !== 0) {          
          onChange(e);           
        }
    }
  }


  if (noBg) {
    return (
      <div className="input-wrapper">
        <input className="search-input-2 text is-dark"
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          value={searchValue}
        />
      </div>
    )
  }

  else if (tags) {
    return (
      <div className="input-wrapper search-input-tags flexer">
        <i className="fa fa-search icon input-wrapper-tags__icon-left search-icon" />

        <input
          className="subheading is-light input-field__search"
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          value={searchValue}
        />

        <div className='flexer flexer-vcenter remaining-width right'>
          <SearchTag label='Tools' onClick={() => handleActive('tools')} active={isActive === 'tools' ? true : false} />
          <SearchTag label='Tags' onClick={() => { handleActive('tags') }} active={isActive === 'tags' ? true : false} />
          <SearchTag label='Lists' onClick={() => { handleActive('lists') }} active={isActive === 'lists' ? true : false} />
          <p className="line-margin-0" onClick={handleClose}><i className="fa fa-close  cursor is-light" /></p>
        </div>
      </div>
    )
  }

  return (
    <div className="input-wrapper search-input  flexer">
      <i className="fa fa-search icon input-wrapper-tags__icon-left search-icon"></i>
      <input className="subheading is-light input-field__search"
        type="text"
        placeholder={placeholder}
        onClick={handleOpen}
        onChange={handleChange}
        value={searchValue}
      />
    </div>
  )
}

export default SearchBar
