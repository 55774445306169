import React from 'react'

// Components
import StartPointForm from './StartPointForm'

function StartPoint(props) {
    return (
        <React.Fragment>
            <div className="flexer">
                <h1 className="title-l line-margin-0">Hunting wizard</h1>
            </div>
            <StartPointForm onUpdate={props.onUpdate} data={props.data} userInput={props.userInput}  errors={props.errors} wizardId={props.wizardId}/>
        </React.Fragment>
    )
}

export default StartPoint