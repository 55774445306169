import React from 'react'
import ReactDOM from 'react-dom'

// Components
import Button from './Button'

function Modal(props) {

	var body = document.getElementById('body-wrapper')
	if (!props.isOpen){
		body.classList.remove('modal-open')
		return null
	} else {
		body.classList.add('modal-open')
	}

	var className = 'Modal__container screen-center'
	var buttons
	if (props.fullScreen) {
		className = 'Modal__container Modal__full-screen-container'

		buttons = <div className="Modal__steps center full-width">
			<Button class="white-btn mx-10 width-10" text={ props.buttons.button1.text } onClick={ props.buttons.button1.funct }/>
			<Button class="blue-btn mx-10 width-10" text={ props.buttons.button2.text } onClick={ props.buttons.button2.funct }
							disabled={ props.buttons.button2.disabled }/>
		</div>
	}

	if(props.reviews) {
		className = 'Modal__container-reviews'
		// className = 'Modal__container Modal__full-screen-container'
	}

	return (
		ReactDOM.createPortal(
			<div className="Modal wcc-modal">
				<div className={className} style={props.style}>
					{props.handleClose ? <i className="fa fa-close Modal__close cursor is-dark" onClick={props.handleClose}></i> : null}
					{props.children}
				</div>
				{buttons}
			</div>,
	        document.getElementById('modal')
	    )
    )

}

export default Modal
