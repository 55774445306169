import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../components/Hooks/UserContext'
import { useParams } from 'react-router'

import { toolService, tagService } from '../../api'

// Components
import ToolsFilters from '../../components/Tools/ToolsFilters'
import ToolsDashboard from '../../components/Tools/ToolsDashboard'
import Tool from '../../components/Elements/Tool'
import Spinnerr from '../../components/Elements/Spinner'

//mixpanel
import { mixpanelService } from '../../api/mixpanel-service.js'

//import _ from 'lodash'
/* import MenuTabItem from '../../components/Header/MenuTabItem' */

function ToolsContent() {
	const { currentUser } = useContext(UserContext)
	const { keyword, tool_id } = useParams() // redirect from  header searchBar
	const [searchKeyword, setsearchKeyword] = useState('')

	const [topTools, setTopTools] = useState(null)
	const [searchResults, setSearchResults] = useState([])
	const [data, setData] = useState([])
	const [allData, setAllData] = useState([])

	const [isActive, setIsActive] = useState('')
	const [refresh, setRefresh] = useState(1)

	// filters
	const [allTags, setAllTags] = useState([])
	const [searchTags, setSearchTags] = useState([])
	const [selectedTags, setSelectedTags] = useState([])
	const [selectedCategories, setSelectedCategories] = useState([])
	const [isFilterActive, setIsFilterActive] = useState(false)

	const [isLoading, setLoading] = useState(false)

  const [updateOrCreate, setUpdateOrCreate] = useState(1)

	function processData(toolsRaw) {
		let tools = {}

		function addSection(sectionKey, tool, tag=''){
			if (!(sectionKey in tools)) {
				tools[sectionKey] = []
			}
			try {
				tools[sectionKey].push(
					<div className='cursor wcc-tool' key={`${tool.id}_${tag}`}>
						<Tool data={tool} onUpdate={handleUpdateOrCreate} />
					</div>
				)
			} catch {}
		}

		for (const tool of toolsRaw) {
			if(tool.tags.length > 0) {
				for (const tag of tool.tags) {
					addSection(`${tool.categories} - ${tag}`, tool, tag)
				}
			}
			else if(tool.categories){
				addSection(tool.categories, tool)
			}

		}

		setData(tools)
		return tools
	}

	useEffect(() => {
		setLoading(true)
		toolService
			.list()
			.then(async (response) => {
				processData(response)
				setAllData(response)
        setIsActive('approved')
			})
			.then(() => {
				setLoading(false)
			})
			.catch((error) => {
				console.error('Error loading tools', error)
			})
	}, [updateOrCreate])


	useEffect(() => {
		if (allData.length === 0) {
			return
		}

		setLoading(true)

		let filteredData = allData
		let filtered = false

		if (selectedCategories.length > 0) {
			filteredData = filteredData.filter((tool) => selectedCategories.includes(tool.categories))
			filtered = true
		}
		if (selectedTags.length > 0) {
			filteredData = filteredData.filter((tool) =>
				tool.tags.some((element) => selectedTags.indexOf(element) !== -1)
			)
			filtered = true
		}
		if (searchKeyword) {
			filteredData = filteredData.filter((tool) =>
				tool.name.toLowerCase().includes(searchKeyword.toLowerCase())
			)
			filtered = true
		}
    if (isActive === 'approved') {
      filteredData = filteredData.filter((tool) => tool.approved)
    }
		if (isActive === 'to-approve') {
			filteredData = filteredData.filter((tool) => !tool.approved)
		}
    

		setIsFilterActive(filtered)

		processData(filteredData)

		setLoading(false)
	}, [isActive, searchKeyword, selectedCategories, selectedTags, keyword])

	useEffect(() => {
		toolService
			.topTools()
			.then((response) => {
				setTopTools(response.results)
			})
			.catch((error) => {
				console.error('Error loading top tools', error)
			})
	}, [])

	useEffect(() => {		
		tagService
			.list()
			.then((response) => {
				tagService
					.toolsListTags()
					.then((listResponse) => {
						const listTags = response.results.filter( tag1 => {
							return !listResponse.results.some( tag2 => {
								return tag1.id === tag2.id
							})						
						})
						setSearchTags(listTags)
						setAllTags(listTags)
					})
			})
			.catch((error) => {
				console.error('Error loading top tools', error)
			})
	}, [updateOrCreate])

	function handleSearch(e) {
		if (e.target.value.length) {
			setsearchKeyword(e.target.value)
			setIsFilterActive(true)
		} else {
			setsearchKeyword('')
		}
	}

	function handleActive(active_menu) {
		setIsActive(active_menu)
		setRefresh(refresh + 1)
	}

	function handleCategories(categories) {
		setLoading(true)
		setSelectedCategories(categories)
		//setIsFilterActive(true)
	}

	function handleTagSearch(keyword) {
		if (keyword.length) {
			let tags = allTags.filter((tag) => tag.name.toLowerCase().includes(keyword.toLowerCase()))
			setSearchTags(tags)
		} else {
			setSearchTags(allTags)
		}
	}

	// Multiselect
	function handleSelect(selected) {
		let tag_ = selected.map((e) => e.name)
		setSelectedTags(tag_)
		setIsFilterActive(true)
	}

	function handleReset() {
		setSelectedTags([])
		setSelectedCategories([])
		setSearchTags([])
		setSearchResults([])
		setIsFilterActive(false)
		setsearchKeyword('')

	}

  const handleUpdateOrCreate = () => {
    setUpdateOrCreate(updateOrCreate + 1)
  }

	return (
		<>
			<div className='flexer flexer-mobile '>
				<div className='flexer-3 center '>
					<ToolsFilters
						currentUser={currentUser}
						isActive={isActive}
						handleSearch={handleSearch}
						handleActive={handleActive}
						handleCategories={handleCategories}
						handleSelect={handleSelect}
						handleTagSearch={handleTagSearch}
						searchTags={searchTags}
						selectedTags={selectedTags}
						handleReset={handleReset}
						isFilterActive={isFilterActive}
            onUpdateOrCreate={handleUpdateOrCreate}

					/>
				</div>
				<div className='flexer-9 center'>
					<div className='flexer center-mobile'>
						{isLoading === true ? (
							<div className='flexer-3 center'>{Spinnerr()}</div>
						) : (
							<ToolsDashboard
								header_keyword={keyword}
								currentUser={currentUser}
								data={data}
								top={topTools}
								isActive={isActive}
								isFilterActive={isFilterActive}
                onUpdate={handleUpdateOrCreate}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default ToolsContent
