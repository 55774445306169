import React, { useState, useEffect } from 'react'

// Components
import InputField from '../../Elements/InputField'
import Textarea from '../../Elements/Textarea'
import Switch from '../../Elements/Switch'
import Tag from '../../Elements/Tag'

function StepOneForm (props) {
    const [errors, setErrors] = useState({})
    const [tags, setTags] = useState([]);
    const [tagInputValue, setTagInputValue] = useState("");

    useEffect(() => {
        setErrors(props.errors);
    });

    if(Object.keys(tags).length === 0 && props.data.tags && Object.keys(props.data.tags).length > 0){
        setTags(props.data.tags);
    }

    function handleDeleteTag(tag){
        let new_tags = tags.filter(t => t !== tag);
        setTags(new_tags);
    }

    function handleKeyDown(e){
        if(e.key === 'Enter'){
            let tagInputEl = document.querySelector('input[name="input-tags"]');

            if (e.target.value && e.target.value.replace(/\s/g, '').length) {
                let new_tags = [...tags, e.target.value];
                setTags(new_tags);
            }

            tagInputEl.value = "";
        }
    }

    let tag_elements = []
    for (const tag of Object.values(tags)){
        tag_elements.push(
        <React.Fragment>
            <Tag tag={tag} delete={true} onDelete={handleDeleteTag}/>
            <input type="hidden" name="tags" value={tag}/>
        </React.Fragment>)
    }

    return ( 
        <div className="center">
            <form id="create-list-form" className="left mt-20">
                <div className="form-wrapper">
                    <InputField label="Name" name='name' placeholder={props.data.name} required={true} error={errors.name} />
                    <Textarea label="Description" name='description' placeholder={props.data.description} error={errors.description}/>
                    <div className="mt-30">
                        <Switch label="Private list" name="private"/>
                    </div>
                </div>
                <div className="form-wrapper">
                    <p className="heading is-dark">Add tags for your list</p>
                    <label className="input-field__label">Add a tag and then press ENTER. You are able to add several tags.</label>
                    <InputField 
                        name="input-tags"
                        placeholder="Enter a tag name" 
                        value={tagInputValue} 
                        error={errors.tags} 
                        onKeyDown={handleKeyDown} 
                        // onChange={handleChange} 
                        // forceValue={true}
                    />
                    <div className="flexer flexer-wrapper my-20">
                        {tag_elements}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default StepOneForm
