import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../Elements/Button'
import ThreeDots from '../Elements/ThreeDots'
import Tag from '../Elements/Tag'


function ListCard (props) {
    
    const { id, created_by, description, name, tags } = props.data

    let list_tags = tags && tags.map((e, idx) => 
        <Tag name={e} key={idx} />
        )

    return (
            <div className="list-card my-40">
                <div className="flexer mr-20">
                    <div className='flexer flexer-spaced full-width'>
                        <div className="">
                            <Link to={`/lists/detail/${id}`}>
                                <p className="subheading is-bold line-margin-s cursor">{name} </p>
                            </Link>
                                <p className="text is-light">Author: <span className="text is-bold">{created_by.public_name} </span></p>
                        </div>
                        <div className="flexer flexer-spaced">
                            <div className='mx-10'>
                                <ThreeDots 
                                    id={props.data.id} 
                                    data={props.data} 
                                    currentUser={props.currentUser} 
                                    onUpdate={props.onUpdate}
                                    // onAdd={props.onAdd}
                                />
                            </div>
                            <Link to={`/lists/detail/${id}`}>
                            <div className=''>
                                <Button text="View Details" class="list-btn is-bold" position='text-icon' icon='fa fa-angle-right' type="button" />
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="mr-20 my-20">
                    <p className="text is-light line-margin-0">{description} </p>
                </div>
                <div className=' flexer flexer-wrapper my-20'>
                    {list_tags}
                </div>
            </div>
    )
}

export default ListCard
