import React from 'react'

//Components
import StarRatings from 'react-star-ratings'

function ReviewsItem(props) {
    const {comment, rating, created_by, date } = props

    var profilePic = props.profile ? props.profile : null
    profilePic = profilePic ? profilePic : 'https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png'

    return (
        <div className="full-width">
            <div className="content-wrapper my-10 bubble">
                <p className="subheading is-light">{comment}</p>
            </div>
            <div className="reviewer-wrapper flexer flexer-vcenter">
                <img className="profile-menu__image" src={profilePic} alt="profile"  />
                <div className="reviewer-wrapper__info flexer flexer-vcenter full-width ">
                    <div className='flexer flexer-9 flexer-vcenter'>
                    <p className="heading is-dark line-margin-0" >{created_by}<span className="text is-light mx-10">left a review</span></p>
                        <span className="fa fa-circle icon-small is-light mx-10"></span>
                        <span className='text is-light mx-10'> {date}</span>
                    </div>
                    <div className='flexer flexer-vcenter flexer-3 right'>
                    {rating && <StarRatings rating={rating} starDimension="15px" starSpacing="0px" starRatedColor="#FFD600" />}
                    {rating && <span className="text is-bold is-yellow mx-10">{rating}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewsItem