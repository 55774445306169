import React, { useState, useEffect } from 'react'
import { Pagination } from '@mui/material'

import { toolService } from '../../api'

// Components
import Checkbox from '../Elements/Checkbox'
import Tool from '../Elements/Tool'

function WizardResultsForm(props) {

  const [tools, setTools] = useState([])
  const [selectedTools, setSelectedTools] = useState({})

  const [refresh, setRefresh] = useState(1)
  const [paginator, setPaginator] = useState({ offset: 0 })

  const target = props.data.tool_target

  const tools_ids = props.toolsSelected

  useEffect(() => {
    if (props.toolsSelected.length) {  // from previous step
      toolService.filter({ id: tools_ids })
        .then(response => {
          let obj = {}
          response.map(e => obj[e.id] = e)
          setSelectedTools(obj)
        })
        .catch(error => {
          console.error('Not found', error)
        })
    }
  }, [props.toolsSelected.length, tools_ids])



  useEffect(() => {
    toolService.filter({ approved: true, target: target }, paginator.offset)  // Many si es mas de dos o Real Name si es solo uno
      .then(response => {
        setTools(response)
      })
      .catch(error => {
        console.error('Not found', error)
      })
  }, [target, paginator.offset])



  const handlePagination = (e, page) => {
    e.preventDefault()

    if (page > 1) {
      setPaginator({ ...paginator, offset: tools.limit * (page - 1) })
    } else {
      setPaginator({ ...paginator, offset: 0 })
    }
  }

  function onSelect(e) {
    let new_obj = selectedTools
    let is_selected = Object.keys(selectedTools).includes(e.id.toString())
    if (is_selected) {
      delete new_obj[e.id]
      setSelectedTools(new_obj)
      setRefresh(refresh + 1)
    } else {
      setSelectedTools({ ...new_obj, [e.id]: e })
    }
  }

  let tools_ = tools.results && tools.results?.map(e => {
    let is_checked = selectedTools && Object.keys(selectedTools).includes(e.id.toString())
    let is_loaded = tools_ids && tools_ids.includes(e.id)

    return (
      <>
        <div className='flexer wcc-tool'>
          <div className='mt-10'>
            <Checkbox name="tools" key={e.id} value={e.name.toLowerCase()} checked={is_loaded ? is_loaded : is_checked} onSelect={() => onSelect(e)} />
          </div>
          <Tool data={e} />
        </div>
      </>)
  })

  let show_selected = selectedTools && Object.values(selectedTools).map(e => {
    return (
      <React.Fragment>
        <div className='flexer wcc-tool'>
          <Tool data={e} key={e.id + 100000} />
          <input type="hidden" name="tools" value={e.name.toLowerCase()} />
          <input type="hidden" name="tools_ids" value={e.id} />
        </div>
      </React.Fragment>
    )
  })

  let errors_ = props.errors ? <p className='error-message'>{props.errors.errors}</p> : null


  return (
    <div className="center">
      <div className="form-wrapper">
        <p className='special-text is-bold is-blue left' >WIZARD RESULTS</p>
        <p className='title-4 left'>Based on your starting points, we suggest you use the following tools:</p>
        <div className='flexer flexer-wrapper wcc-tools'>
          {tools_}
        </div>

        <div className="wcc-pagination">
          <div className="wcc-info">
            {/* Showing {showing} of {count} */}
          </div>

          <Pagination
            count={tools.page_count}
            page={tools.pages}
            onChange={handlePagination}
            boundaryCount={4}
            shape="rounded"
            size="small"
          />
        </div>


        <form id="run-wizard-form" className="left mt-20">
          <p className='subheading is-light'>Selected tools:</p>
          <div className='flexer flexer-wrapper wcc-tools'>
            {show_selected}
          </div>

        </form>
        {(!props.data.tools && Object.keys(selectedTools).length === 0) && errors_}
      </div>
    </div>
  )
}

export default WizardResultsForm
