import React from 'react'

function Tag (props) {

    function ToggleTag() {   
        var element = document.getElementById('tag-options-list') 
        element.classList.toggle("shown")
        element.classList.toggle("hidden")
    }

    if (props.delete) {
        return (
            <React.Fragment>
                <div className='tag flexer mr-20 mb-15 cursor'>
                    <p className='text center is-bold is-light mx-15'> {props.tag} <i className='is-light fa fa-close' onClick={()=>{props.onDelete(props.tag)}}/></p>
                </div>
            </React.Fragment>
        )
    }

    if (props.options) {
        return (
            <React.Fragment>
                <div className='tag flexer mr-20 mb-15 cursor' onClick={ToggleTag}>
                    <p className='text center is-bold is-light mx-15' > Tag-tag 2 </p>
                    <ul id='tag-options-list' className="tag-menu__list heading hidden">
                        <li className="three-dots-menu__item cursor is-light" onClick={''}><i className='mx-10 is-light fas fa-pen-alt'/> Show list with this tag</li>
                        <li className="three-dots-menu__item cursor is-light" onClick={''}><i className='mx-10 is-light fas fa-star'/>Rate this tag</li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className='tag flexer mr-20 mb-15 cursor'>
                <p className='text center is-bold is-light mx-15'> {props.name}</p>
            </div>
        </React.Fragment>
    )
}

export default Tag