import React, { useContext, useState } from 'react'
import { UserContext } from '../../components/Hooks/UserContext'

// Components
import Button from '../Elements/Button'
import Textarea from '../Elements/Textarea'
import StarRatings from 'react-star-ratings'
import Tooltip from '../Elements/Tooltip'

// API
import { reviewListService, reviewToolService} from '../../api'

function ReviewForm(props) {
    const { currentUser } = useContext(UserContext)

    const [errors, setErrors] = useState({})
    const [rating, setRating] = useState(0)

    const logged_user = currentUser

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('reviews-form')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })

        if (body['rating'] === '0') {
            setErrors({...errors, rating: 'You have to at least give 1 star.'})
        }
        else if (props.element === 'list') {
            reviewListService.create(body)
            .then((response) => {
                props.onClose(true)
            })
            .catch(error => {
                if(Array.isArray(error)){
                    error = {non_field_errors: error[0]}
                }
                setErrors({...error})
            })
        }
        else if (props.element === 'tool') {
            reviewToolService.create(body)
            .then((response) => {
                props.onClose(true)
            })
            .catch(error => {
                if(Array.isArray(error)){
                    error = {non_field_errors: error[0]}
                }
                setErrors({...error})
            })
        }

    }

    function handleChange(new_rating){
        setRating(new_rating)
    }

    let element_name
    if (props.element === 'tool') {
        element_name = 'tool'
    } else if (props.element === 'list') {
        element_name = 'tool_list'
    }

    return (
        <form id="reviews-form" onSubmit={handleSubmit}>
            <div className="mx-20">
            <h1 className="subtitle is-dark mb-30 ">{`Add Review for this ${props.element}`}</h1>
                <div className="flexer flexer-vcenter ">
                    <img className="profile-menu__image" src='https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png' alt="profile"  />
                    <div className="mx-15">
                        <p className="heading is-dark line-margin-0" >{logged_user.public_name}</p>    
                        <p className="subheading is-dark line-margin-0" >{logged_user.occupation}</p>
                    </div>
                </div>
                <Tooltip class='flexer ml-50 mt-20 flexer-vcenter' direction='right' content='Enter your evaluate here'>
                <div className="">
                    <StarRatings rating={rating} starDimension="24px" starSpacing="8px" starRatedColor="#FFD600" changeRating={handleChange}/>
                    <input type="hidden" name="rating" value={rating}/>
                    <span className="rating-text rating-text-setter mx-10">{`${rating}.0`}</span>
                    <p className="error-message">{errors.rating}</p>
                </div>
                </Tooltip>
            <div className='ml-50'>
            <input type="hidden" name={element_name} value={props.element_id}/>
            <p className="subtitle is-dark mt-20">Write a review please</p>
            <Textarea name="comment" label="Review" class="input-field line-margin-0" required={true} error={errors.review || errors.non_field_errors} />
            </div>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type="button"  onClick={props.onClose}/>
                <Button text="Add review" class="blue-btn mt-30 subheading is-white" icon='fa fa-plus is-white' type="submit"/>
            </div>
            </div>
        </form>
    )
}

export default ReviewForm