import React from 'react'

// Containers
import Header from '../containers/Header'
import TutorialsContent from '../containers/Content/TutorialsContent'
import Content from '../containers/Content'

function Tutorials() {
  return (
    <React.Fragment>
      <Header />
      <Content>
        <TutorialsContent />
      </Content>
    </React.Fragment>
    )
}

export default Tutorials
