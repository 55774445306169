import React, { useState, useEffect } from "react";

//api
import { toolService } from "../../api";

function StepTwoWizardForm(props) {
  const [options, setOptions] = useState([]);
  const [userInput, setUserInput] = useState({});
  const [toolTargetInput, setToolTargetInput] = useState([]);

  useEffect(() => {
    setUserInput(props.userInput);
  }, []);

  useEffect(() => {
    props?.data?.tool_target_inputs &&
      setToolTargetInput(props.data.tool_target_inputs);
  }, [props.data.tool_target_inputs]);

  useEffect(() => {
    toolService
      .suggestions({
        tool_target: props.data.tool_target,
        tool_target_input: props.userInput,
      })
      .then((response) => {
        setOptions(response);
      })
      .catch((error) => {
        console.error("Error loading suggestions", error);
      });
  }, []);

  useEffect(() => {
    if (props.data.tool_target_inputs) {
      // check if the user has changed the original input
      if (
        props.data.tool_target === "Phone" ||
        props.data.tool_target === "Email"
      ) {
        let originalInput =
          props?.originalUserInput && JSON.stringify(props.originalUserInput);
        let currentInput = props?.userInput && JSON.stringify(props.userInput);
        let sameInput = originalInput === currentInput;
        !sameInput && setToolTargetInput([]); // if so, delete the chosen options from the old input
      }
      if (props.data.tool_target === "Real Name") {
        // check if the user has changed the original input
        let sameInput =
          props?.userInput &&
          props?.data?.tool_target_inputs?.filter((item) => {
            return Object.keys(item).every((key) => {
              let user_input_empty = props?.userInput[key] ?? "";
              return item[key].toLowerCase() === user_input_empty.toLowerCase();
            });
          }).length > 0;
        !sameInput && setToolTargetInput([]); // if so, delete the chosen options from the old input
      }
      if (props.data.tool_target === "Many") {
        // check if the user has changed the original input
        let sameInput =
          props?.userInput &&
          Object.keys(props?.originalUserInput)?.every((key) => {
            return (
              props?.originalUserInput[key]?.toLowerCase() ===
              props?.userInput[key]?.toLowerCase()
            );
          });
        !sameInput && setToolTargetInput([]); // if so, delete the chosen options from the old input
      }
    }
  }, [props.data.tool_target, props.data.tool_target_inputs]);

  const handleClickOptions = (value) => {
    let alreadyExist =
      toolTargetInput.filter((selected) => {
        // checks if the option clicked by the user already exist inside toolTargetInput
        return JSON.stringify(selected) === JSON.stringify(value);
      }).length > 0;
    if (alreadyExist) {
      let newToolTargetInput = toolTargetInput.filter((selected) => {
        return JSON.stringify(selected) !== JSON.stringify(value);
      });
      setToolTargetInput(newToolTargetInput); // if so, it is deleted
    } else {
      setToolTargetInput([...toolTargetInput, value]);
    }
  };

  const handleClickRealName = (current) => {
    let alreadyExist =
      toolTargetInput.filter((option) => {
        // checks if the option clicked by the user already exist inside toolTargetInput
        return Object.keys(option).every((key) => option[key] === current[key]);
      }).length > 0;

    if (alreadyExist) {
      let newToolTargetInput = toolTargetInput.filter((option) => {
        return Object.keys(option).some((key) => option[key] !== current[key]);
      });

      setToolTargetInput(newToolTargetInput); // if so, it is deleted
    } else {
      setToolTargetInput([...toolTargetInput, current]);
    }
  };

  let options_;
  if (props.data.tool_target === "Real Name" || props.data.tool_target === "Many") {
    options_ =
      options &&
      options.map((e, idx) => {
        let labels = Object.values(e).map((item) => `${item} `);
        return (
          <div className="my-20 mr-40" key={idx}>
            <label className="subheading is-light">
              <input
                name="options"
                type="checkbox"
                className="checkbox"
                value={JSON.stringify(e)}
                checked={
                  toolTargetInput &&
                  toolTargetInput.filter((selected) => {
                    return Object.keys(selected).every(
                      (key) => selected[key] === e[key]
                    );
                  }).length > 0
                }
                onClick={() => handleClickRealName(e, idx)}
              />
              {labels}
            </label>
          </div>
        );
      });
  } else if (
    props.data.tool_target === "Phone" ||
    props.data.tool_target === "Email"
  ) {
    options_ =
      options &&
      options.map((e, idx) => {
        return (
          <div className="my-20 mr-40" key={idx}>
            <label className="subheading is-light">
              <input
                className="checkbox"
                name="options"
                type="checkbox"
                value={JSON.stringify(e)}
                checked={
                  toolTargetInput &&
                  toolTargetInput.filter((selected) => {
                    return JSON.stringify(selected) === JSON.stringify(e);
                  }).length > 0
                }
                onClick={() => handleClickOptions(e)}
              />
              {Object.values(e)[0]}
            </label>
          </div>
        );
      });
  }

  let user_option = (
    <div className="my-20 mr-40">
      <label className="subheading is-light">
        <input className="checkbox" type="checkbox" checked={true} />
        {props.data.tool_target === "Real Name" ||
        props.data.tool_target === "Many"
          ? Object.values(userInput).map((item) => `${item} `)
          : Object.values(userInput)}
      </label>
    </div>
  );

  let chosen_options =
    toolTargetInput &&
    toolTargetInput.map((item) => (
      <input type="hidden" name="options" value={JSON.stringify(item)} />
    ));

  return (
    <div className="center">
      <div className="form-wrapper">
        <p className="special-text is-bold is-blue left">SELECT OPTIONS</p>
        <p className="title-4">
          Choose your options for starting Hunting Wizard
        </p>
        <form id="run-wizard-form" className="left mt-20">
          <div className="my-20">
            {user_option}
            {chosen_options}
            <input type="hidden" name="options" value={""} />
          </div>
        </form>
        <div className="left mt-20">{options_}</div>
        {!options.length && (
          <p className="text is-light left my-20">
            There is no other options to choose
          </p>
        )}
      </div>
    </div>
  );
}

export default StepTwoWizardForm;
