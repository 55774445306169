import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const toolService = {
    list,
    topTools,
    listByUrl,
    retrieve,
    search,
    searchByTag,
    filter,
    filterByUrl,
    create,
    approve_tool,
    active_tool,
    update,
    remove,
    suggestions
}


async function list() {
    const requestOptions = { method: 'GET', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/raw_list/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function topTools() {
    const requestOptions = { method: 'GET', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/top/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function listByUrl(url) {
    const requestOptions = { method: 'GET', credentials: 'include' }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function retrieve(tool_id) {
    const requestOptions = { method: 'GET', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/${tool_id}/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function search(keyword) {
    const requestOptions = { method: 'GET', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/search/?keyword=${keyword}`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function searchByTag(tag) {
    const requestOptions = { method: 'GET', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/search_by_tag/?tag=${tag}`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}


async function filter(body, offset = 0, limit) {
    body = fixBody(body)
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    }
    return fetch(`${config.apiUrl}/cid/tools/filters/?limit=${limit}&offset=${offset}`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function filterByUrl(url, body) {
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}


async function create(body) {
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', body }
    return fetch(`${config.apiUrl}/cid/tools/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function approve_tool(tool_id) {
    const requestOptions = { method: 'POST', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/${tool_id}/approve_tool/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function active_tool(tool_id, body) {
    const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    return fetch(`${config.apiUrl}/cid/tools/${tool_id}/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}


async function update(body, tool_id) {
    body = fixBody(body)
    const requestOptions = { method: 'PATCH', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    return fetch(`${config.apiUrl}/cid/tools/${tool_id}/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function remove(tool_id) {
    const requestOptions = { method: 'DELETE', credentials: 'include' }
    return fetch(`${config.apiUrl}/cid/tools/${tool_id}/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}

async function suggestions(body) {
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    return fetch(`${config.apiUrl}/cid/tools/suggestions/`, requestOptions)
        .then(handleResponse)
        .then(tool => {
            return tool
        })
}
