import { useState, useEffect, useContext } from 'react'

//Components
import StarRatings from 'react-star-ratings'
import Modal from '../Elements/Modal'
import EditProfileForm from '../../components/Profile/Forms/EditProfileForm'
import VerifyAttribute from './Forms/VerifyAttribute'
import ProfileIdsCard from './ProfileIdsCard'
import { UserContext } from '../../components/Hooks/UserContext'

function SimpleProfile(props) {
	const [isOpen, setIsOpen] = useState(false)
	const [toVerify, setToVerify] = useState({
		phone_number: false,
		email: false,
	})
	const [profileInfo, setProfileInfo] = useState({})
	const { currentUser } = useContext(UserContext)

	useEffect(() => {
		if (props.user) {
			setProfileInfo(props.user)
		}
	}, [props.user])

	function openModal() {
		setIsOpen(true)
	}
	function closeModal(refresh = false) {
		if (refresh) {
			props.onUpdate()
		}
		setIsOpen(false)
		props.onUpdate()
	}

	let rating = profileInfo?.profile ? profileInfo?.profile.rating : 0
	rating = rating ? rating : 0
	let ratingString = rating ? rating.toString() : null
	let numberReviews = profileInfo?.profile ? profileInfo.profile.reviews.length : null

	var name = props.user?.first_name + ' ' + props.user?.last_name  
	var initials = Array.prototype.map.call(name.split(' '), function(x){ return x.substring(0,1).toUpperCase();}).join('');


	if (profileInfo?.profile) {
		var facebook = profileInfo?.profile?.facebook_link ?? null
		var instagram = profileInfo?.profile?.instagram_link ?? null
		var linkedin = profileInfo?.profile?.linkedin_link ?? null
		var youtube = profileInfo?.profile?.youtube_link ?? null
	}

	// Attributes verification
	function handleNewVerification(phoneNumber, email) {
		if (!props.as_admin) {
			let toVerifyCurrent = toVerify
			if (phoneNumber) {
				toVerifyCurrent['phone_number'] = true
			}

			if (email) {
				toVerifyCurrent['email'] = true
			}

			setToVerify({ ...toVerifyCurrent })
		}
	}

	function handleVerification(attribute) {
		setToVerify({ ...toVerify, [attribute]: false })
	}

	let verifications = []
	if (!props.as_admin && toVerify.phone_number) {
		verifications.push(
			<VerifyAttribute
				name='phone_number'
				displayName='Phone number'
				onVerification={handleVerification}
				userId={props.user.cognito_id}
			/>
		)
	}

	if (!props.as_admin && toVerify.email) {
		verifications.push(
			<VerifyAttribute
				name='email'
				displayName='Email'
				onVerification={handleVerification}
				userId={props.user.cognito_id}
			/>
		)
	}

	return (
		<div className='simple-profile__sections center'>
				 {profileInfo?.profile?.profile_pic ? (<img
					 className='simple-profile__picture'
					 src={profileInfo?.profile?.profile_pic}
					 />) : (<div className='profileImage'>{initials}</div>)}
			<div className='simple_profile__upper-sections'>
				<p className='subtitle is-dark line-margin-s'>
        {profileInfo && profileInfo.public_name}
					{props.allow_edit ? (
						<span className='fa fa-pencil cursor is-dark ml-10' onClick={openModal} />
					) : null}
				</p>  
				<div className='flexer flexer-vcenter center'>
					<StarRatings
						rating={rating}
						starDimension='15px'
						starSpacing='0px'
						starRatedColor='#44C568'
					/>
					<span className='rating-text mx-10'>
						{ratingString} <span className='text is-light'>({numberReviews} reviews)</span>
					</span>
				</div>
			</div>
			<div className='simple_profile__upper-sections'>
				<p className='text is-light line-margin-s icon-text'>
					<i className='fa fa-envelope mr-10 text-icon' />
					{profileInfo?.email ?? null}
				</p>
				<p className='text is-light line-margin-s'>
					<i className='fa fa-phone mr-10 text-icon' />
					{profileInfo?.phone_number ?? null}
				</p>
				<p className='text is-light line-margin-s'>{profileInfo?.profile?.occupation ?? null}</p>
				<ProfileIdsCard profileInfo={profileInfo}/>
			</div>
			<div className='simple_profile__upper-sections'>
				<a
					href={instagram}
					target='_blank'
					rel='noreferrer'
					disabled={instagram ?? 'disabled'}
				>
					<i className='fab fa-instagram social-icons' />
				</a>
				<a
					href={facebook}
					target='_blank'
					rel='noreferrer'
					disabled={facebook ? facebook : 'disabled'}
				>
					<i className='fab fa-facebook-f social-icons' />
				</a>
				<a
					href={youtube}
					target='_blank'
					rel='noreferrer'
					disabled={youtube ?? 'disabled'}
				>
					<i className='fab fa-youtube social-icons' />
				</a>
				<a
					href={linkedin}
					target='_blank'
					rel='noreferrer'
					disabled={linkedin ?? 'disabled'}
				>
					<i className='fab fa-linkedin-in social-icons' />
				</a>
			</div>
			{verifications}
			<Modal isOpen={isOpen} handleClose={closeModal}>
				<EditProfileForm
					onClose={closeModal}
					profilePic={profileInfo?.profile?.profile_pic}
					user={profileInfo && profileInfo}
					onNewVerification={handleNewVerification}
					initials = {initials}
				/>
			</Modal>
		</div>
	)
} 

export default SimpleProfile
