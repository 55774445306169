import React, { useState, useEffect } from 'react'

// Api
import { wizardService } from '../../api'

// Components
import Button from '../../components/Elements/Button'
import Modal from '../../components/Elements/Modal'
import RunWizard from '../../components/Wizard/RunWizard'
import WizardCard from '../../components/Wizard/WizardCard'

function WizardContent () {

    const [wizards, setWizards] = useState([])

    const [next, setNext] = useState(null)
    const [prev, setPrev] = useState(null)

    const [isOpenRunWizard, setIsOpenRunWizard] = useState(false)
    const [wizardDetail, setWizardDetail] = useState(null)
    const [wizardId, setWizardId] = useState(null)
    const [wizardInput, setWizardInput] = useState(null)
    const [wizardTools, setWizardTools] = useState(null)
    const [wizardTarget, setWizardTarget] = useState(null)
    const [wizardOptions, setWizardOptions] = useState(null)
    const [refresh, setRefresh] = useState(1)
    const [hasProps, setHasProps] = useState(false)

    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [toDelete, setToDelete] = useState(null)


    useEffect(() => {
        wizardService.list()
        .then(response => {
            setWizards(response.results)
            setNext(response.next && response.next.replace('8000', '3001'))
            setPrev(response.previous && response.previous.replace('8000', '3001'))
        })
        .catch(error=>{
            console.log("Error loading wizards", error)
        })

    },[refresh])

    function handlePagination(url) {
        wizardService.listByUrl(url)
        .then(response=>{
            setWizards(response.results)
            setNext(response.next && response.next.replace('8000', '3001'))
            setPrev(response.previous && response.previous.replace('8000', '3001'))
        })
        .catch(error=>{
            console.log("Error loading wizards", error)
        })
    }

    function handleClick(wizard_id) {
        wizardService.retrieve(wizard_id)
        .then(response=>{
            setWizardDetail(response)
            setWizardTarget(response.target)
            setWizardInput(response.user_input)
            setWizardTools(response.tools.map(e => e.id))
            setWizardOptions(response.user_chosen_suggestions)
            setIsOpenRunWizard(true)
            setWizardId(response.id)
            setHasProps(true)
        })
        .catch(error=>{
            console.log("Error loading wizard detail", error)
        })
    }

    function handleClickDelete(wizard_id) {
        setToDelete(wizard_id)
        setIsOpenDelete(true)
    }
    
    function handleDelete() {
        wizardService.remove(toDelete)
        .then(response => {
            console.log(response)
            setRefresh(refresh + 1)
            setIsOpenDelete(false)
        })
        .catch(error=>{
            console.log("Error deleting wizard", error)
        })
    }

    function handleClose () {
        setRefresh(refresh + 1)
        setWizardDetail(null)
        setWizardTarget(null)
        setWizardInput(null)
        setWizardTools(null)
        setWizardOptions(null)
        setIsOpenRunWizard(false)
        setWizardId(null)
    }

    let wizards_ = wizards && wizards.map(e => 
        <div className='my-30' key={e.id}>
            <WizardCard key={e.id} data={e} onClick={handleClick} onDelete={handleClickDelete}/>
        </div>
        )

    return (
        <React.Fragment>
            <div className='center my-30'>
                <div className='wizard-section'>
                    <p className='title line-margin-0 left'>Wizard sessions saved</p>
                    {wizards_}
                </div>
                <div className="flexer right mx-10">
                        {prev? <p className="subheading is-dark button blue-btn cursor" onClick={()=>handlePagination(prev)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
                        <p className="subheading is-light mx-10"></p>
                        {next? <p className="subheading is-dark button blue-btn cursor mr-20" onClick={()=>handlePagination(next)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
                    </div>
            </div>
            <Modal isOpen={isOpenDelete} handleClose={()=>{setIsOpenDelete(false)}}>
                <div className="center">
                    <h1 className="title is-dark mb-30">Delete Wizard</h1>
                    <p className="text is-light mx-30">Are you sure that you want to delete the wizard number <span className="is-dark is-bold">{toDelete}</span>?</p>
                    <Button text="Delete" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleDelete} />
                </div>
            </Modal>
            <RunWizard 
                isOpen={isOpenRunWizard} 
                handleClose={handleClose}
                id={wizardId}
                userInput={wizardInput}
                tools={wizardTools}
                wizardTarget={wizardTarget}
                wizardOptions={wizardOptions}
                hasProps={hasProps}
                /> 
        </React.Fragment>
    )
}

export default WizardContent
