import React, { useState, useEffect } from 'react'

// Components
import Checkbox from '../Elements/Checkbox'

function FilterByCategories(props) {

    const [categories, setCategories] = useState({
        discover: false,
        develop: false, 
        monitor: false,
        resources: false,
        training_support: false
    })

    const categoriesMap = {
        discover: 'Discover',
        develop: 'Develop', 
        monitor: 'Monitor',
        resources: 'Resources',
        training_support: 'Training & Support'
    }
    useEffect(() => {
        if(!props.isFilterActive) {
            setCategories({
                discover: false,
                develop: false, 
                monitor: false,
                resources: false,
                training_support: false
            })
        }
    },[props.isFilterActive])

    function handleClick(e) {
        let newCategories = {...categories, [e.target.name] : !categories[e.target.name]}
        setCategories(newCategories)
        let selected = []
        for (var key in newCategories) {
            if (newCategories[key]) {
                selected.push(categoriesMap[key])
            }
        }
        props.handleCategories(selected)
    }

    return (
        <React.Fragment>
            <p className='text left is-bold'>Categories</p>
            <div className="left" >

                <div className='my-10'>
                    <label className="subheading is-light">
                        <input className='mr-10' type='checkbox' name={'discover'} value={categories.discover}  checked={ categories.discover } onClick={(e) => handleClick(e)}/>
                        Discover tools</label>
                </div>
                <div className='my-10'>
                    <label className="subheading is-light">
                        <input className='mr-10' type='checkbox' name={'develop'} value={categories.develop}  checked={ categories.develop } onClick={(e) => handleClick(e)} />
                        Develop tools</label>
                </div>
                <div className='my-10'>
                    <label className="subheading is-light">
                        <input className='mr-10' type='checkbox' name={'monitor'} value={categories.monitor} checked={ categories.monitor } onClick={(e) => handleClick(e)} />
                        Monitor tools</label>
                </div>
                <div className='my-10'>
                    <label className="subheading is-light">
                        <input className='mr-10' type='checkbox' name={'resources'} value={categories.resources} checked={ categories.resources } onClick={(e) => handleClick(e)}/>
                        Resources</label>
                </div>
                <div className='my-10'>
                    <label className="subheading is-light">
                        <input className='mr-10' type='checkbox' name={'training_support'} value={categories.training_support}  checked={ categories.training_support } onClick={(e) => handleClick(e)} />
                        Training & Support</label>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FilterByCategories
