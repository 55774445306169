import React, { useState } from 'react'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Api
import { listService } from '../../api'

// Component
import Button from './Button'
import InputField from './InputField'
import Modal from './Modal'
import CreateCustomList from '../Lists/CreateList/CreateCustomList';

function ThreeDots (props){

    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [toDelete, setToDelete] = useState({})
    
    const [isOpenSubscribe, setIsOpenSubscribe] = useState(false)
    const [toSubscribe, setToSubscribe] = useState({})

    const [isOpenUnsubscribe, setIsOpenUnsubscribe] = useState(false)
    const [toUnsubscribe, setToUnsubscribe] = useState({})

    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenShare, setIsOpenShare] = useState(false)
    const [errors, setErrors] = useState({})

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let is_superadmin = props.currentUser && props.currentUser.role === 'SUPER_ADMIN' ? true : false

    let list_creator = props.data ? props.data.created_by.cognito_id : null

    let allow_edit = props.currentUser && (props.currentUser.cognito_id === list_creator) ? true : false


    let list_subscribers = props.data ? props.data.subscribers : []

    let is_subscribed = (props.currentUser && props.data.subscribers) && list_subscribers.some(e => e === props.currentUser.cognito_id)

    
    // Delete
    function handleClickDelete(list_id, list_name) {
        handleClose()
        setIsOpenDelete(true)
        setToDelete({ id: list_id, name: list_name})
    }

    function handleDelete() {  
        listService.remove(toDelete.id)
        .then(response => {
            setIsOpenDelete(false)
            props.onUpdate()
        })
        .catch(error => {
            console.error("Error deleting list", error)
        })
    }

    // Subscribe
    function handleClickSubscribe(list_id, list_name) {
        handleClose()
        setIsOpenSubscribe(true)
        setToSubscribe({ id: list_id, name: list_name})
    }

    function handleSubscribe() {
        listService.subscribe(toSubscribe.id)
        .then(response => {
            setIsOpenSubscribe(false)
            props.onUpdate()
        })
    }

    // Unubscribe
    function handleClickUnsubscribe(list_id, list_name) {
        handleClose()
        setIsOpenUnsubscribe(true)
        setToUnsubscribe({ id: list_id, name: list_name})
    }

    function handleUnsubscribe() {
        listService.unsubscribe(toUnsubscribe.id)
        .then(response => {
            setIsOpenUnsubscribe(false)
            props.onUpdate()
        })
    }
    // Edit
    function handleClickEdit() {
        handleClose()
        setIsOpenEdit(true)
    }
    
    // Tags
    function handleAddTags () {
        handleClose()
        props.onAdd(props.id)
    }

    // Share
    const handleClickShare = () => {
        handleClose()
        setIsOpenShare(true)
    }
    const handleShare = (e) => {
        e.preventDefault()
        let form_element = document.getElementById("share-form");
        let form_data = new FormData(form_element);
    
        let body = {}
        form_data.forEach((value, key) => {
            if (value) {
            body[key] = value;
            } 
        });

        listService.listSharing(body)
        .then((response) => {
            setIsOpenShare(false);
            setErrors({})
        })
        .catch((error) => {
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        });
    }


    return (
        <React.Fragment>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className='MuiButtonBase-root-MuiIconButton-root'
            >
            <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{'aria-labelledby': 'basic-button'}}
                className='MuiList-root-MuiMenu-list'
            >
                { (allow_edit || is_superadmin) && 
                    <MenuItem className='MenuItem' onClick={() => handleClickEdit()}><i className='mx-10 is-light fas fa-pen-alt'/>Edit List</MenuItem>
                    }
                { !is_subscribed 
                    ?
                    <MenuItem className='MenuItem' onClick={() => handleClickSubscribe(props.id, props.data.name)}><i className='mx-10 is-light fas fa-check'/>Subscribe me</MenuItem> 
                    : 
                    <MenuItem className='MenuItem' onClick={() => handleClickUnsubscribe(props.id, props.data.name)}><i className='mx-10 is-light fa fa-undo'/>Unsubscribe</MenuItem>}
                { (props.isDetail && (allow_edit || is_superadmin)) && 
                    <MenuItem className='MenuItem' onClick={() => handleAddTags(props.id)}><i className='mx-10 is-light fas fa-tags'/>Add tags</MenuItem>}
                    <MenuItem className='MenuItem' onClick={() => handleClickShare(props.id)}><i className='mx-10 is-light fa fa-share-square-o'/>Share list</MenuItem>
                { (!props.isTop && (allow_edit || is_superadmin)) && 
                    <MenuItem className='MenuItem' onClick={() => handleClickDelete(props.id, props.data.name)}><i className='mx-10 is-light fas fa-trash-alt'/>Delete List</MenuItem>}
            </Menu>

            <Modal isOpen={isOpenDelete} handleClose={()=>{setIsOpenDelete(false)}}>
                <div className="center">
                    <h1 className="title mb-30">Delete List</h1>
                    <p className="text is-dark mx-30">Are you sure that you want to delete <br></br> <span className="is-bold">{toDelete.name}</span>?</p>
                    <Button text="Delete" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleDelete} />
                </div>
            </Modal>

            <Modal isOpen={isOpenSubscribe} handleClose={()=>{setIsOpenSubscribe(false)}}>
                <div className="center">
                    <h1 className="title mb-30">List Subscription</h1>
                    <p className="text is-dark mx-30">You will be subscribed to <br></br> <span className="is-bold">{toSubscribe.name}</span></p>
                    <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleSubscribe} />
                </div>
            </Modal>

            <Modal isOpen={isOpenUnsubscribe} handleClose={()=>{setIsOpenUnsubscribe(false)}}>
                <div className="center">
                    <h1 className="title mb-30">List Subscription</h1>
                    <p className="text is-dark mx-30">Are you sure that you want to cancel your subscription to <br></br> <span className="is-bold">{toUnsubscribe.name}</span>?</p>
                    <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleUnsubscribe} />
                </div>
            </Modal>

            <Modal isOpen={isOpenShare} handleClose={()=>{setIsOpenShare(false)}}>
                <form id='share-form'>
                    <h1 className="title mb-30">Share this list</h1>
                    <InputField name="email"  label='User email' type="email" required={true} />
                    <input type="hidden" name="tools_list" value={props.id} error />
                    {Object.values(errors) ? <p className='error-message'>{Object.values(errors)[0]}</p> : null}
                    <div className="center">
                        <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleShare} />
                    </div>
                </form>
            </Modal>

            <CreateCustomList edit={true} id={props.id} data={props.data} isOpen={isOpenEdit} handleClose={()=>{setIsOpenEdit(false)}} onUpdate={props.onUpdate}/>

        </React.Fragment>
    )
}

export default ThreeDots
