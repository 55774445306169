import {ToastContainer} from 'react-toastify'
import {StrictMode} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import config from './api/config'
import App from './App'

import 'react-toastify/dist/ReactToastify.css'

axios.defaults.baseURL = config.apiUrl
axios.defaults.withCredentials = true

ReactDOM.render(<StrictMode>
	<App/>
	<ToastContainer
			autoClose={3000}
			draggable={false}
			closeOnClick={true}
			pauseOnHover={true}
			hideProgressBar={false}
			position={'bottom-right'}
	/>
</StrictMode>, document.getElementById('root'))

