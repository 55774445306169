import React from 'react'

// Components
import StepTwoForm from './StepTwoForm'

function StepTwo(props) {
    return (
        <React.Fragment>
            <div className="flexer flexer-spaced">
                <h1 className="title is-dark line-margin-0">{props.edit ? 'Edit List' : 'List Creation'}</h1>
                <p className="special-text is-blue line-margin-0">Step 2</p>
            </div>
            <StepTwoForm editData={props.editData} edit={props.edit} onUpdate={props.onUpdate} data={props.data} errors={props.errors} toolsSelected={props.toolsSelected}/>
        </React.Fragment>
    )
}

export default StepTwo