import React from 'react'

// Components
import StepTwoWizardForm from './StepTwoWizardForm'

function StepTwoWizard(props) {
    return (
        <React.Fragment>
            <div className="flexer">
                <h1 className="title-l line-margin-0">Hunting wizard</h1>
            </div>
            <StepTwoWizardForm onUpdate={props.onUpdate} data={props.data}  userInput={props.userInput}  errors={props.errors} originalUserInput={props.originalUserInput}/>
        </React.Fragment>
    )
}

export default StepTwoWizard