import React, { useState, useEffect } from 'react'

function InputField(props) {
    const [value, setValue] = useState(null)

    // If any value from props, set value to input field
    useEffect(() => {
        if (props.value) {
            setValue(props.value)
        }
    }, [props.value])


    function handleChange(e) {
        setValue(e.target.value)
        try {
            props.onChange(e)
        } catch (exception_var) {
            console.log("Function does not exist")
        }
    }

    function handleKeyDown(e) {
        try {
            props.onKeyDown(e)
        } catch (exception_var) {
            console.log("Function does not exist")
        }
    }

    var className = props.class ? props.class : ''

    function TogglePassword(e) {
        e.target.classList.toggle("fa-eye-slash")
        e.target.classList.toggle("fa-eye")

        var input = e.target.previousSibling
        if (input.getAttribute("type") === "password") {
            input.setAttribute("type", "text")
        } else {
            input.setAttribute("type", "password")
        }
    }

    if (props.type === "password") {
        return (
            <div className={`${className} input-field`}>
                <label className="input-field__label" >{props.label}</label>
                <div className="input-wrapper">
                    <input minLength="8" name={props.name} className="input-field__input" type={props.type} required={props.required ? "required" : false} />
                    <span id={props.id} className="fa fa-eye icon input-wrapper__icon-right" onClick={TogglePassword}></span>
                </div>
                <p className="error-message">{props.error}</p>
            </div>
        )
    }

    let inputElement = ""
    if (props.value || props.forceValue) {
        inputElement = <input name={props.name} className="input-field__input" type={props.type} value={value} placeholder={props.placeholder} required={props.required ? "required" : false} onChange={handleChange} onKeyDown={handleKeyDown} />
    } else {
        inputElement = <input name={props.name} className="input-field__input" type={props.type} placeholder={props.placeholder} required={props.required ? "required" : false} onKeyUp={handleKeyDown} />
    }

    return (
        <div className={`${className} input-field`}>
            <label className="input-field__label" >{props.label}</label>
            {inputElement}
            <p className="error-message">{props.error}</p>
        </div>
    )
}

export default InputField
