import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../components/Hooks/UserContext";

import {
  reviewListService,
  reviewToolService,
  listService,
  toolService,
} from "../../api";

// Components
import ReviewsSection from "../../components/Reviews/ReviewsSection";
import ReviewsItem from "../../components/Reviews/ReviewItem";
import ToolDetail from "../../components/Tools/ToolDetail";

function ReviewContent(props) {
  const { currentUser } = useContext(UserContext);

  const [refresh, setRefresh] = useState(1);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);

  const [element, setElement] = useState(props.element);
  const [element_id, setElement_id] = useState(props.element_id);

  const [rating, setRating] = useState(0);
  const [ratingStars, setRatingStars] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [count, setCount] = useState(0);

  const [toolDetail, setToolDetail] = useState({});

  useEffect(() => {
    if (element === "list") {
      reviewListService
        .list_reviews(element_id)
        .then((response) => {
          setReviews(response.results);
          setCount(response.count);
          setNext(response.next);
          setPrev(response.previous);
        })
        .catch((error) => {
          console.error("List reviews not found", error);
        });
      listService
        .retrieve(element_id)
        .then((response) => {
          setRatingStars(response.ratings_per_star);
          setRating(response.rating);
        })
        .catch((error) => {
          console.error("List reviews not found", error);
        });
    } else if (element === "tool") {
      reviewToolService
        .tool_reviews(element_id)
        .then((response) => {
          if (response.count > 0) {
            setReviews(response.results);
            setCount(response.count);
            setNext(response.next);
            setPrev(response.previous);
          }
        })
        .catch((error) => {
          console.error("Tools reviews not found", error);
        });
      toolService
        .retrieve(element_id)
        .then((response) => {
          setToolDetail(response);
          if (response.static_rating) {
            setRatingStars(response.ratings_per_star);
            setRating(response.static_rating);
          }
        })
        .catch((error) => {
          console.error("Tools reviews not found", error);
        });
    }
  }, [refresh]);

  function handlePagination(url) {
    if (element === "list") {
      reviewListService
        .listByUrl(url)
        .then((response) => {
          setReviews(response.results);
          setNext(response.next);
          setPrev(response.previous);
        })
        .catch((error) => {
          console.log("Error loading users", error);
        });
    } else if (element === "tool") {
      reviewToolService
        .listByUrl(url)
        .then((response) => {
          setReviews(response.results);
          setNext(response.next);
          setPrev(response.previous);
        })
        .catch((error) => {
          console.log("Error loading users", error);
        });
    }
  }

  var ReviewItem = [];
  if (reviews) {
    for (var review of reviews) {
      ReviewItem.push(
        <ReviewsItem
          comment={review.comment}
          rating={review.rating}
          created_by={review.created_by.public_name}
          date={review.proper_created}
          profile={review.created_by.profile.profile_pic}
        />
      );
    }
  } else {
    <ReviewsItem
      comment={null}
      rating={0}
      created_by={""}
      date={""}
      profile={""}
    />;
  }

  return (
    <>
      {props.element === "tool" &&
        <ToolDetail
          data={toolDetail}
          toolId={props.element_id}
          count={count}
          currentUser={currentUser}
          onUpdate={props.onUpdate}
          onClose={props.onClose}
        />
      }

      <ReviewsSection
        title="Reviews"
        button="Add Review"
        btnClass="blue-btn"
        btnIcon="fa fa-star"
        element={element}
        element_id={element_id}
        onUpdate={() => setRefresh(refresh + 1)}
        pagination={handlePagination}
        allow_edit={false}
        user={currentUser}
        count={count ? count : null}
        ratingStars={ratingStars}
        rating={rating}
      >
        {ReviewItem}
        <div className="flexer right">
          {prev ? (
            <p className="subheading is-dark button blue-btn cursor" onClick={() => handlePagination(prev)}>
              <i className="fa fa-arrow-left mr-10 is-white"></i>Previous
            </p>
          ) : null}
          <p className="subheading is-light mx-10"></p>
          {next ? (
            <p className="subheading is-dark button blue-btn cursor" onClick={() => handlePagination(next)}>
              Next<i className="fa fa-arrow-right ml-10 is-white"></i>
            </p>
          ) : null}
        </div>
      </ReviewsSection>
    </>
  );
}

export default ReviewContent;
