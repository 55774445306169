import React, { useState, useEffect } from "react";

// Components
import InputField from "../Elements/InputField";
import Dropdown from "../Elements/Dropdown";

function StartPointForm({ data, wizardId, userInput, errors }) {
  const [startPoint, setStartPoint] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [newField, setNewField] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([
    "Real Name",
    "Email",
    "User Name",
    "Phone",
    "Address",
  ]);
  const [inputsOrder, setInputsOrder] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    if (!startPoint && data?.tool_target) {
      setStartPoint(data.tool_target);
    }

    if (data?.tool_target && data?.tool_target !== startPoint) {
      setStartPoint(data.tool_target);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.tool_target]);

  useEffect(() => {
    if (wizardId) {
      setNewField([]);
    }
  }, [wizardId]);

  useEffect(() => {
    if (!wizardId && data.tool_target) {
      let many_targets = Object.entries(userInput).map(
        (item) =>
          (item[0] === "email" && "Email") ||
          (item[0] === "phoneNumber" && "Phone") ||
          (item[0] === "address" && "Address") ||
          (item[0] === "username" && "User Name") ||
          (item[0] === "firstName" && "Real Name")
      );

      data.tool_target === "Email" && setNewField(() => [email_field]);
      data.tool_target === "Phone" && setNewField(() => [phone_field]);
      data.tool_target === "User Name" && setNewField(() => [user_name_field]);
      data.tool_target === "Address" && setNewField(() => [address_field]);
      data.tool_target === "Real Name" && setNewField(() => [real_name_field]);
      data.tool_target === "Many" &&
        many_targets.map((item) =>
          item === "Email"
            ? setNewField((prevState) => [...prevState, email_field])
            : item === "Phone"
            ? setNewField((prevState) => [...prevState, phone_field])
            : item === "User Name"
            ? setNewField((prevState) => [...prevState, user_name_field])
            : item === "Address"
            ? setNewField((prevState) => [...prevState, address_field])
            : item === "Real Name" &&
              setNewField((prevState) => [...prevState, real_name_field])
        );
    }
    if (!wizardId && !data.tool_target) {
      setNewField(() => [dropdown]);
    }
  }, [wizardId]);

  useEffect(() => {
    if (inputsOrder?.length > 1) {
      setStartPoint("Many");
    }
    if (inputsOrder?.length === 1) {
      setStartPoint(inputsOrder[0]);
    }
  }, [inputsOrder]);

  let dropdown = (
    <div className="my-30">
      <Dropdown
        // name="tool_target"
        class="full-width line-margin-xs"
        onChange={handleStartPoint}
      >
        <option value="">Select Start Point</option>
        {dropdownOptions.includes("Email") && (
          <option
            value="Email"
            selected={data.tool_target === "Email" ? true : false}
          >
            Email
          </option>
        )}
        {dropdownOptions.includes("User Name") && (
          <option
            value="User Name"
            selected={data.tool_target === "User Name" ? true : false}
          >
            Username
          </option>
        )}
        {dropdownOptions.includes("Phone") && (
          <option
            value="Phone"
            selected={data.tool_target === "Phone" ? true : false}
          >
            Phone
          </option>
        )}
        {dropdownOptions.includes("Address") && (
          <option
            value="Address"
            selected={data.tool_target === "Address" ? true : false}
          >
            Address
          </option>
        )}
        {dropdownOptions.includes("Real Name") && (
          <option
            value="Real Name"
            selected={data.tool_target === "Real Name" ? true : false}
          >
            Real Name
          </option>
        )}
      </Dropdown>
      {errors.startPoint && (
        <p className="error-message">{errors.startPoint}</p>
      )}
    </div>
  );

  let email_field = (
    <>
      <div className="mt-30">
        <Dropdown
          // name="tool_target"
          class="full-width line-margin-xs"
          onChange={handleStartPoint}
        >
          {dropdownOptions.includes("Email") && (
            <option value={"Email"} selected={true}>
              Email
            </option>
          )}
        </Dropdown>
        {errors.startPoint && (
          <p className="error-message">{errors.startPoint}</p>
        )}
      </div>
      <div className="my-10">
        <InputField
          class="full-width line-margin-xs"
          type="text"
          required={true}
          isWizard={true}
          name="email"
          value={userInput.email ?? null}
          placeholder={userInput.email ?? "Enter an email"}
          error={errors.email || errors.errors}
        />
      </div>
    </>
  );
  let user_name_field = (
    <>
      <div className="mt-30">
        <Dropdown
          // name="tool_target"
          class="full-width line-margin-xs"
          onChange={handleStartPoint}
        >
          {dropdownOptions.includes("User Name") && (
            <option value={"User Name"} selected={true}>
              User Name
            </option>
          )}
        </Dropdown>
        {errors.startPoint && (
          <p className="error-message">{errors.startPoint}</p>
        )}
      </div>
      <div className="my-10">
        <InputField
          class="full-width line-margin-xs"
          type="text"
          required={true}
          isWizard={true}
          name="username"
          value={userInput.username ?? null}
          placeholder={userInput.username ?? "Enter an username"}
          error={errors.username || errors.errors}
        />
      </div>
    </>
  );

  let phone_field = (
    <>
      <div className="mt-30">
        <Dropdown
          // name="tool_target"
          class="full-width line-margin-xs"
          onChange={handleStartPoint}
        >
          {dropdownOptions.includes("Phone") && (
            <option value="Phone" selected={true}>
              Phone
            </option>
          )}
        </Dropdown>
        {errors.startPoint && (
          <p className="error-message">{errors.startPoint}</p>
        )}
      </div>
      <div className="my-10">
        <InputField
          class="full-width line-margin-xs"
          type="text"
          required={true}
          isWizard={true}
          name="phoneNumber"
          value={userInput.phoneNumber ?? null}
          placeholder={userInput.phoneNumber ?? "Enter a phone number"}
          error={errors.phoneNumber || errors.errors}
        />
      </div>
    </>
  );
  let address_field = (
    <>
      <div className="mt-30">
        <Dropdown
          // name="tool_target"
          class="full-width line-margin-xs"
          onChange={handleStartPoint}
        >
          {dropdownOptions.includes("Address") && (
            <option value="Address" selected={true}>
              Address
            </option>
          )}
        </Dropdown>
        {errors.startPoint && (
          <p className="error-message">{errors.startPoint}</p>
        )}
      </div>
      <div className="my-10">
        <InputField
          class="full-width line-margin-xs"
          type="text"
          required={true}
          isWizard={true}
          name="address"
          value={userInput.address ?? null}
          placeholder={userInput.address ?? "Enter an address"}
          error={errors.address || errors.errors}
        />
      </div>
    </>
  );

  let real_name_field = (
    <>
      <div className="mt-30">
        {wizardId ? (
          <>
            <p className="text is-light is-bold line-margin-0">
              {data.tool_target}
            </p>
            <input type="hidden" name="tool_target" value={data.tool_target} />
          </>
        ) : (
          <Dropdown
            // name="tool_target"
            class="full-width line-margin-xs"
            onChange={handleStartPoint}
          >
            {dropdownOptions.includes("Real Name") && (
              <option value="Real Name" selected={true}>
                Real Name
              </option>
            )}
          </Dropdown>
        )}
        {errors.startPoint && (
          <p className="error-message">{errors.startPoint}</p>
        )}
      </div>
      <div className="my-10">
        <InputField
          class="full-width line-margin-xs"
          type="text"
          name="firstName"
          required={true}
          isWizard={true}
          value={userInput.firstName ?? null}
          placeholder={userInput.firstName ?? "Enter a first name"}
          error={errors.firstName || errors.errors}
        />
        <InputField
          class="full-width line-margin-xs"
          type="text"
          name="middleName"
          isWizard={true}
          value={userInput.middleName ?? null}
          placeholder={data.middleName ?? "Enter a middle name"}
          error={errors.middleName}
        />
        <InputField
          class="full-width line-margin-xs"
          type="text"
          name="lastName"
          isWizard={true}
          value={userInput.lastName ?? null}
          placeholder={data.lastName ?? "Enter a last name"}
          error={errors.errors}
        />
      </div>
    </>
  );

  let input_name;
  let input_value;
  let input_placeholder;
  if (startPoint === "Real Name") {
    input_name = "firstName";
    input_value = userInput.firstName ?? null;
    input_placeholder = userInput.firstName
      ? userInput.firstName
      : "Enter a first name";
  } else if (startPoint === "Email") {
    input_name = "email";
    input_value = userInput.email ?? null;
    input_placeholder = userInput.email ?? "Enter an email";
  } else if (startPoint === "User Name") {
    input_name = "username";
    input_value = userInput.username ?? null;
    input_placeholder = userInput.username
      ? userInput.username
      : "Enter an username";
  } else if (startPoint === "Phone") {
    input_name = "phoneNumber";
    input_value = userInput.phoneNumber ?? null;
    input_placeholder = userInput.phoneNumber
      ? userInput.phoneNumber
      : "Enter a phone number";
  } else if (startPoint === "Address") {
    input_name = "address";
    input_value = userInput.address ?? null;
    input_placeholder = userInput.address
      ? userInput.address
      : "Enter an address";
  }

  const handleAddField = () => {
    setNewField((prevState) => [...prevState, dropdown]);
    setShowAdd(false);
  };

  function handleStartPoint(e) {
    let selected = e.target.value;
    let dropdown_options = dropdownOptions?.filter((item) => item !== selected);
    setDropdownOptions(dropdown_options);
    setInputsOrder((prevState) => [...prevState, selected]);
    setShowAdd(true);

    if (selected === "Real Name") {
      let aux = [...newField, real_name_field];
      setNewField(aux);
    }
    if (selected === "Email") {
      let aux = [...newField, email_field];
      setNewField(aux);
    }
    if (selected === "User Name") {
      let aux = [...newField, user_name_field];
      setNewField(aux);
    }
    if (selected === "Phone") {
      let aux = [...newField, phone_field];
      setNewField(aux);
    }
    if (selected === "Address") {
      let aux = [...newField, address_field];
      setNewField(aux);
    }

    setRefresh(refresh + 1);
  }

  return (
    <div className="center">
      <form id="run-wizard-form" className="left mt-20">
        <div className="form-wrapper">
          <p className="special-text is-bold is-blue left">START POINT</p>
          <p className="title-4">
            Choose your Start Point for starting Hunting Wizard
          </p>
          {wizardId && data.tool_target !== "Many" ? (
            <>
              <p className="text is-light is-bold line-margin-0">
                {data.tool_target}
              </p>
              <input
                type="hidden"
                name="tool_target"
                value={data.tool_target}
              />
              <div className="my-10">
                <InputField
                  class="full-width line-margin-xs"
                  type="text"
                  name={input_name}
                  required={true}
                  isWizard={true}
                  value={input_value}
                  placeholder={input_placeholder}
                  // error={errors.errors}
                />
                {startPoint === "Real Name" && (
                  <>
                    <InputField
                      class="full-width line-margin-xs"
                      type="text"
                      name="middleName"
                      isWizard={true}
                      value={userInput.middleName ?? null}
                      placeholder={data.middleName ?? "Enter a middle name"}
                      // error={errors.errors}
                    />
                    <InputField
                      class="full-width line-margin-xs"
                      type="text"
                      name="lastName"
                      isWizard={true}
                      value={userInput.lastName ?? null}
                      placeholder={data.lastName ?? "Enter a last name"}
                      // error={errors.errors}
                    />
                  </>
                )}
              </div>
            </>
          ) : wizardId && data.tool_target === "Many" ? (
            <>
              <input
                type="hidden"
                name="tool_target"
                value={data.tool_target}
              />

              {Object.entries(userInput).map((key) => {
                return (
                  <>
                    <p className="text is-light is-bold mt-20">
                      {(key[0] === "email" && "Email") ||
                        (key[0] === "phoneNumber" && "Phone") ||
                        (key[0] === "address" && "Address") ||
                        (key[0] === "username" && "User Name") ||
                        (key[0] === "firstName" && "Real Name")}
                    </p>

                    {key[0] !== "middleName" && key[0] !== "lastName" && (
                      <InputField
                        class="full-width line-margin-xs"
                        type="text"
                        name={key[0]}
                        isWizard={true}
                        value={key[1] ?? null}
                        // placeholder={"Enter a first name"}
                        error={errors.errors}
                      />
                    )}
                    {key[0] === "firstName" && (
                      <>
                        <InputField
                          class="full-width line-margin-xs"
                          type="text"
                          name="middleName"
                          isWizard={true}
                          value={userInput.middleName ?? null}
                          placeholder={"Enter a middle name"}
                          error={errors.errors}
                        />
                        <InputField
                          class="full-width line-margin-xs"
                          type="text"
                          name="lastName"
                          isWizard={true}
                          value={userInput.lastName ?? null}
                          placeholder={"Enter a last name"}
                          error={errors.errors}
                        />
                      </>
                    )}
                  </>
                );
              })}

              {!userInput.firstName &&
                (userInput.middleName || userInput.lastName) && (
                  <>
                    <p className="text is-light is-bold mt-20">Real Name</p>
                    <InputField
                      class="full-width line-margin-xs"
                      type="text"
                      name="firstName"
                      isWizard={true}
                      value={userInput.firstName ?? null}
                      placeholder={"Enter a first name"}
                      error={errors.errors}
                    />
                    <InputField
                      class="full-width line-margin-xs"
                      type="text"
                      name="middleName"
                      isWizard={true}
                      value={userInput.middleName ?? null}
                      placeholder={"Enter a middle name"}
                      error={errors.errors}
                    />
                    <InputField
                      class="full-width line-margin-xs"
                      type="text"
                      name="lastName"
                      isWizard={true}
                      value={userInput.lastName ?? null}
                      placeholder={"Enter a last name"}
                      error={errors.errors}
                    />
                  </>
                )}
            </>
          ) : (
            <input
              className="hidden"
              name="tool_target"
              value={startPoint}
            ></input>
          )}
          {newField.length > 0 && newField}

          {errors.firstName && (
            <p className="error-message">{errors.firstName}</p>
          )}
          {errors.phoneNumber && (
            <p className="error-message">{errors.phoneNumber}</p>
          )}
          {errors && <p className="error-message">{errors.errors}</p>}

          {newField.length < 5 && showAdd && (
            <div className="mt-30">
              <p className="text is-blue cursor" onClick={handleAddField}>
                + Add another Start Point
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default StartPointForm;
