import config from '../api/config'
import mixpanel from 'mixpanel-browser'
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable'

export const mixpanelService = {
	OpenTool,
    ReviewOpened,
    ToolReviewAdded,
    ToolListAdded,
    Logout,
}

async function SignUp(body) {
	mixpanel.init(config.mixpanelProjectId, {debug: true})
        			mixpanel.identify(body["email"])
					mixpanel.track('Sign up for Home')
					mixpanel.people.set({"$first_name":body["first_name"],
										"$last_name":body["last_name"],
                                        "$phone_number":body["phone_number"],
										"$dob":body["dob"],

					})
}

async function OpenTool(tool) {
	mixpanel.init(config.mixpanelProjectId, {debug: true})
    mixpanel.track(
        "Open CID Tool",
        {"Tool": tool.name,
         "Category" : tool.categories,
         "Tool ID" : tool.id,
         "URL": tool.url
    })
}

async function ReviewOpened(tool) {
	mixpanel.init(config.mixpanelProjectId, {debug: true})
    mixpanel.track(
        "CID Tool Review Opened",
        {"Tool": tool.name,
         "Category" : tool.categories,
         "Tool ID" : tool.id,
    })
}

async function ToolReviewAdded(tool) {
	mixpanel.init(config.mixpanelProjectId, {debug: true})
    mixpanel.track(
        "CID Tool Review Added",
        {"Tool ID" : tool.tool,
         "Comment" : tool.comment,
         "Rating" : tool.rating,
       
         
    })
}

async function ToolListAdded(list) {
	mixpanel.init(config.mixpanelProjectId, {debug: true})
    mixpanel.track(
        "CID Tool List Added",
        {
            "Description" : list.description,
            "Name" : list.name,
            "Private" : list.private,
            "Tags" : list.tags,
            "Tools" : list.tools,
    })
}

async function Logout() {
	mixpanel.init(config.mixpanelProjectId, {debug: true})
	mixpanel.track('Logout')
}