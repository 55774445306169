import React from 'react'

// Containers
import Header from '../containers/Header'
import WizardContent from '../containers/Content/WizardContent'
import Content from '../containers/Content'

function Wizard() {
  return (
    <React.Fragment>
      <Header />
      <Content>
        <WizardContent />
      </Content>
    </React.Fragment>
    )
}

export default Wizard
