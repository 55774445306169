import React, { useState, useEffect } from 'react'
import StarRatings from 'react-star-ratings'
import SwitchButton from "react-switch"
import { useHistory } from 'react-router'

// Api
import { listService , toolService } from '../../api'

// Assets
import Logo from '../../assets/images/picture.png'


// Components
import Button from '../Elements/Button'
import InputField from '../Elements/InputField'
import Modal from '../Elements/Modal'
import Tag from '../Elements/Tag'
import Tooltip from '../Elements/Tooltip'

function ToolDetail(props) {

    const history = useHistory()

    const {id, name, description, tool_pic, active, url } = props.data
    const [errors, setErrors] = useState({})
    const [toolDetail, setToolDetail] = useState({})
    const [rating, setRating] = useState(0)
    const [ratingStars, setRatingStars] = useState(null)

    const [isApproved, setIsApproved] = useState(null)
    const [toApprove, setToApprove] = useState(null)
    const [toDelete, setToDelete] = useState(null)

	const [isOpenApprove, setIsOpenApprove] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)

    const [isOpenEditTags, setIsOpenEditTags] = useState(false)
    const [isOpenEditTools, setIsOpenEditTools] = useState(false)
    const [tags, setTags] = useState([])
    const [pendingTags, setPendingTags] = useState([]);
    
    const [tagInputValue, setTagInputValue] = useState("")
    
    const [refresh, setRefresh] = useState(1)
    const [checkbox, setCheckbox] = useState(null)

    const [myLists, setMyLists] = useState([])
    const [selectedLists, setSelectedLists] = useState(null)
    const [isOpenList, setIsOpenList] = useState(false)
    
    const is_admin = props.currentUser.role ==='SUPER_ADMIN'
    const current_user = props.currentUser.cognito_id

    useEffect(() => {
        toolService.retrieve(props.toolId)
        .then((response) => {
            setToolDetail(response)
            setTags(response.tags)
            if(response.static_rating) {
                setRatingStars(response.ratings_per_star)
                setRating(response.static_rating)
                setIsApproved(response.approved)
                setCheckbox(response.active)
            }
            setIsApproved(response.approved)
            setCheckbox(response.active)
        })
        .catch(error => {
            console.error("Tools reviews not found", error)
        })
        listService.filter({created_by: current_user})
        .then(response => {
            setMyLists(response.results)
        })
        .catch(error => {
            console.error("Error loading my lists", error)
        })
    },[current_user, props.toolId, refresh])

    function handleChange(e){
        setCheckbox(!checkbox)
        let body = {active: !checkbox}

        toolService.active_tool(id, body)
        .then((response) => {
            console.log(response, 'Tool Active State')
            setRefresh(refresh + 1)
            props.onUpdate()
        })
    }

    // approve 
    function handleClick (tool_id) {
        setToApprove(tool_id)
        setIsOpenApprove(true)
    }

    function handleApprove () {
        toolService.approve_tool(toApprove)
        .then((response) => {
            setIsApproved(true)
            setIsOpenApprove(false)
            setRefresh(refresh + 1)
            props.onUpdate()
        })
    }

    // delete
    function handleClickDelete(tool_id) {
        setToDelete(tool_id)
        setIsOpenDelete(true)
        props.onUpdate()
    }

    function handleDelete() {
        toolService.remove(toDelete)
        .then(response => {
            setRefresh(refresh + 1)
            setIsOpenDelete(false)
            props.onClose()
        })
    }

    // edit tags
    function handleClickEditTags() {
        setIsOpenEditTags(true);
        setPendingTags([...tags]);
    }

    function handleEditTags() {
        let body = {tags: pendingTags};
        toolService.update(body, id)
        .then(response => {
            setIsOpenEditTags(false)
            setRefresh(refresh + 1)
            props.onUpdate()
        });
    }

    // edit tools
    function handleClickEditTools() {
        setIsOpenEditTools(true)
    }
    
    function handleEditTools(e) {
        e.preventDefault()

        var form_element = document.getElementById('edit-tool')
        var form_data = new FormData(form_element)

        var body = {}
        form_data.forEach((value, key) => {
            if(value) {
                body[key] = value
            }
        })

            
        toolService.update(body, id)
        .then(response => {
            props.onUpdate()
            setErrors({})
            setIsOpenEditTools(false)
            setRefresh(refresh + 1)
        })
        .catch(error => {
            console.log("Error editing list", error)
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        })
    }

    function handleDeleteTag(tag){
        const newTags = pendingTags.filter(t => t !== tag);
        setPendingTags([...newTags]);
    }
    
    function handleChangeTags(e){
        setTagInputValue(e.value)
    }
    
    function handleKeyDown(e){
        if(e.key === 'Enter'){
            setPendingTags([...pendingTags, e.target.value]);
            setTagInputValue("");
        }
    }

 
    // add to a list

    function handleClickAddTools(e) {

        var show_lists = document.getElementById('lists')
        show_lists.classList.toggle("hidden")
        show_lists.classList.toggle("shown")
    }

    function handleClickSelectedList(idList, nameList, tools) {
        let toolsIds = tools.map(tool => tool.id)

        setSelectedLists({id: idList, name: nameList, tools: toolsIds})
        setIsOpenList(true)
    }

    function handleAddToList() {
        listService.add_tools(selectedLists.id, {tools:[...selectedLists.tools , props.toolId]})
        .then(response =>{
            setIsOpenList(false)
            setSelectedLists(null)
            setRefresh(refresh + 1)
            history.push(`/lists/detail/${selectedLists.id}`)
        })
        .catch(error => {
            console.error("Error adding tool", error)
        })
    }

    let tag_elements = pendingTags && pendingTags.map((e, idx) => 
    <React.Fragment>
        <Tag tag={e} delete={true} onDelete={handleDeleteTag} key={idx}/>
        <input type="hidden" name="tags" value={e}/>
    </React.Fragment>

  
)
    let tool_name = name ? name : null
    let tool_url = url ? url : null
    let tool_description = description ? description : null
  
    let logo = tool_pic ? tool_pic : Logo

    let rating_ = rating ? rating : 0

    let tags_ = tags && tags.map((e, idx) => <Tag name={e} key={idx} />)

    let edit_icons = <React.Fragment>
                        <div className='flexer'>
                            {/* <Tooltip content='Edit tags'> */}
                            <Tooltip content='Add to a list'>
                                <div className='mr-30 cursor'><i className='fa fa-plus-square-o' onClick={()=>handleClickAddTools()}/></div>
                            </Tooltip>
                        </div>
                    </React.Fragment>

    if (props.currentUser.role ==='SUPER_ADMIN') {
        if(isApproved) {
            edit_icons = <React.Fragment>
                            <div className='flexer'>
                                <Tooltip content='Delete tool'>
                                    <div className='mr-30 cursor'><i className='fa fa-trash' onClick={()=>handleClickDelete(id)}/></div>
                                </Tooltip>
                                <Tooltip content='Edit tools'>
                                    <div className='mr-30 cursor'><i className='fa fa-wrench' onClick={()=>handleClickEditTools()}/></div>
                                </Tooltip>
                                <Tooltip content='Edit tags'>
                                    <div className='mr-30 cursor'><i className='fa fa-tags' onClick={()=>handleClickEditTags()}/></div>
                                </Tooltip>
                                <Tooltip content='Add to a list'>
                                    <div className='mr-30 cursor'><i className='fa fa-plus-square-o' onClick={()=>handleClickAddTools()}/></div>
                                </Tooltip>
                            </div>
                        </React.Fragment>
        } else {
            edit_icons = <React.Fragment>
            <div className='flexer'>
                <Tooltip content='Delete tool'>
                    <div className='mr-30 cursor'><i className='fa fa-trash' onClick={()=>handleClickDelete(id)}/></div>
                </Tooltip>
                <Tooltip content='Edit tools'>
                    <div className='mr-30 cursor'><i className='fa fa-wrench' onClick={()=>handleClickEditTools()}/></div>
                </Tooltip>
                <Tooltip content='Edit tags'>
                    <div className='mr-30 cursor'><i className='fa fa-tags' onClick={()=>handleClickEditTags()}/></div>
                </Tooltip>
            </div>
        </React.Fragment>
        }
    }


    let lists_ = myLists && myLists.map((e) =>
    <li className="add-tool-menu__item cursor" id={e.id} onClick={()=>handleClickSelectedList(e.id, e.name, e.tools)}><i className='mx-10 is-light fas fa-check'/>{e.name}</li>
    )

    let lists = <React.Fragment>
                    <ul id='lists' className="add-tool-menu__list heading hidden">
                        {lists_}
                        {!myLists.length && <li className="add-tool-menu__item cursor" id={500000} ><i className='mx-10 is-light fas fa-minus'/>No lists available</li>}
                    </ul>
                </React.Fragment>

    return (
        <div className='tool-detail__wrapper'>
            <div className='tool-detail__container flexer flexer-vcenter'>
                <div className='flexer-3 center detail__picturec_content'>
                    <img className="tool-detail__picture" src={logo} alt="tool" />
                </div>
                <div className='flexer-9'>
                    <div className='flexer flexer-spaced flexer-vcenter'>
                    <a href={`${url}`} target="_blank" rel='noreferrer'><p className='title-3 is-bold line-margin-s'>{toolDetail?.name}</p></a>
                       
                        <div className='flexer'>
                            {edit_icons}
                            {(props.currentUser.role ==='SUPER_ADMIN' && !isApproved) ?
                                <Button text={'Approve Tool'} class={'blue-btn mr-10'} icon={"fas fa-check is-white"} onClick={()=> handleClick(id)} /> 
                                : null}
                        </div>
                    </div>
                        { lists }

                    <div className='flexer flexer-vcenter'>
                                <div className='flexer flexer-vcenter'>
                                    <StarRatings rating={rating_} starDimension="15px" starSpacing="3px" starRatedColor="#76CAA0" /><p className='text is-green is-bold mx-10 line-margin-0'>{rating_}</p>
                                    <p className='text line-margin-0'>({props.count} reviews)</p>
                                </div>
                    </div>
                    <p className='text is-light mr-10'>{toolDetail?.description}</p>
                    <div className='flexer flexer-spaced' >
                        <div className='flexer flexer-wrapper'>
                            {tags_}
                        </div>

                    </div>
                    <div className='flexer right mr-10'>
                    {is_admin && isApproved ? 
                        (<div className="my-10 mr-20 is-light">
                            <label className="flexer flexer-vcenter">
                                <SwitchButton onChange={handleChange} checked={checkbox} height={20} width={40} handleDiameter={15} onColor="#0065FD" />
                                <span className="text is-dark mx-10">Active</span>
                            </label>
                        </div>) 
                        : null} 
                    </div>
                    <div className=''>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpenApprove} handleClose={()=>{setIsOpenApprove(false)}}>
                <div className="center">
                    <h1 className="title mb-30">Approve Tool</h1>
                    <p className="text is-dark mx-30">Are you sure that you want to approve <span className="is-bold">{name}</span>?</p>
                    <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={() => handleApprove({id})} />
                </div>
            </Modal>
            <Modal isOpen={isOpenDelete} handleClose={()=>{setIsOpenDelete(false)}}>
                <div className="center">
                    <h1 className="title mb-30">Delete Tool</h1>
                    <p className="text is-dark mx-30">Are you sure that you want to delete <span className="is-bold">{name}</span>?</p>
                    <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={() => handleDelete({id})} />
                </div>
            </Modal>
            <Modal isOpen={isOpenEditTags} handleClose={()=>{setIsOpenEditTags(false)}}>
                <div className="left">
                    <p className="title mb-30">Edit tags</p>
                    <label className="input-field__label">Add a tag and then press ENTER. You are able to add several tags.</label>
                    <InputField placeholder="Enter a tag name" value={tagInputValue} onKeyDown={handleKeyDown}  onChange={handleChangeTags} forceValue={true}/>
                    <div className="flexer flexer-wrapper my-20">
                        {tag_elements}
                    </div>
                    <div className='center'><Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light center" type="button" onClick={handleEditTags} /></div>
                </div>
            </Modal>
            <Modal isOpen={isOpenEditTools} handleClose={()=>{setIsOpenEditTools(false)}}>
                <React.Fragment>
                    <form id="edit-tool"  onSubmit={handleEditTools}>
                        <div className="left">
                            <p className="title mb-30">Edit tools</p>
                            <InputField name="name"  label='Tool Name' type="text" value={toolDetail?.name} placeholder="Enter a name" error={errors.name}/>
                            <InputField name="url"  label='URL' type="text" value={toolDetail?.url} placeholder="Enter a URL" error={errors.url}/>
                            <InputField name="description"  label='Desrciption' type="text" value={toolDetail?.description} placeholder="Enter a description" error={errors.description}/>
                            <div className='center'><Button text="Save Changes" class="mt-30 one-third-width blue-btn subheading-light center" type="submit" /></div>
                        </div>
                    </form>
                </React.Fragment>
            </Modal>
            <Modal isOpen={isOpenList} handleClose={()=>{setIsOpenList(false)}}>
                <div className="center">
                    <h1 className="title mb-30">Add Tool</h1>
                    <p className="text is-dark mx-30">You are adding <span className="is-bold">{toolDetail?.name}</span> to <span className="is-bold">{selectedLists && selectedLists.name}</span></p>
                    <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={() => handleAddToList(id)} />
                </div>
            </Modal>
        </div>
    )
}

export default ToolDetail
