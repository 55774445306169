import React from 'react'

function SearchTag (props) {

    if (props.active) {
        return (
            <p className='text is-bold search-tags search-tags__active cursor' onClick={props.onClick}>{props.label}</p>
        )
    }

    return (
        <p className='text is-bold search-tags cursor' onClick={props.onClick}>{props.label}</p>
    )
}

export default SearchTag


