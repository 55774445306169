import React, { useState } from 'react'

import FilterByCategories from '../Lists/FilterByCategories'

function TagsFilters(props) {

    return (
        <div className="simple-profile__sections">
            {props.isFilterActive ?
            <div onClick={ props.handleReset}><p className="text is-light mt-30 mr-20 right cursor"> Reset Filters<i className='is-light fa fa-refresh cursor mx-10' onClick={ props.handleReset}/></p></div>
            : null}
            <FilterByCategories
                handleCategories={props.handleCategories}
                isFilterActive={props.isFilterActive}
            />
        </div>
    )
}

export default TagsFilters