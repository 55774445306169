import React, { useState } from 'react'

// Components
import SearchBar from '../Elements/SearchBar'
import CreateCustomList from './CreateList/CreateCustomList'
import FilterByCategories from './FilterByCategories'
import FilterByTags from './PopularTags'
import Button from '../Elements/Button'
import MenuButton from '../Elements/MenuButton'


function ListsFilters(props) {
	const { handleActive, isActive } = props
	const [isOpen, setIsOpen] = useState(false)

	return (
		<React.Fragment>
			<div className="simple-profile__sections">
				<div className='my-20'>
					{isActive !== 'shared' && <SearchBar placeholder={"Search by keywords..."} onChange={props.handleSearch} />}
				</div>

				<MenuButton text='All Lists' icon="far fa-list-alt" onClick={() => { handleActive('all-lists') }} active={isActive === 'all-lists' ? true : false} />
				<MenuButton text='Created by me' icon="far fa-edit" onClick={() => { handleActive('by-me') }} active={isActive === "by-me" ? true : false} />
				<MenuButton text='Subscribed lists' icon="fas fa-tasks" onClick={() => { handleActive('subscribed') }} active={isActive === "subscribed" ? true : false} />
				<MenuButton text='Shared with me' icon="fa fa-share-square-o" onClick={() => { handleActive('shared') }} active={isActive === "shared" ? true : false} />

				<div className="my-20" >
					<Button class="blue-btn full-width" type="button" text="Create Custom List" icon="fa fa-plus is-white" onClick={() => { setIsOpen(true) }} />
				</div>
			</div>

			{isActive !== 'shared' &&
				<>
					<div className="simple-profile__sections">

						{props.isFilterActive ?
							<div onClick={props.handleReset}><p className="text is-light mt-30 mr-20 right cursor"> Reset Filters<i className='is-light fa fa-refresh cursor mx-10' onClick={props.handleReset} /></p></div>
							: null}
						<FilterByCategories
							handleCategories={props.handleCategories}
							isFilterActive={props.isFilterActive}
						/>
					</div>

					<div className="simple-profile__sections">
						<FilterByTags
							handleTagSearch={props.handleTagSearch}
							searchTags={props.searchTags}
							handleSelect={props.handleSelect}
							selectedTags={props.selectedTags}
							isFilterActive={props.isFilterActive}
						/>
					</div>
				</>}
			<CreateCustomList isOpen={isOpen} handleClose={() => { setIsOpen(false) }} />
		</React.Fragment>
	)
}

export default ListsFilters
