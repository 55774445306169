import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'
import { mixpanelService } from './mixpanel-service';

export const reviewToolService = {
    list,
    tool_reviews,
    listByUrl,
    retrieve,
    create,
    remove
}

async function list() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_rate/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
}

async function tool_reviews(tool_id) {   
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools/${tool_id}/rating/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
}

async function listByUrl(url) {
	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(url, requestOptions)
	.then(handleResponse)
	.then(response => {
			return response
	})
}

async function retrieve(review_id) {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_rate/${review_id}/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
}

async function create(body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_rate/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            mixpanelService.ToolReviewAdded(body)
            return response
        })
}

async function remove(review_id) {
    const requestOptions = { method: 'DELETE', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_rate/${review_id}/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response
        })
}
