import React, { useState, useEffect } from 'react'
import Title from '../../components/Elements/Title'

function TutorialsContent () {

    return (
      
        <div className=" regist-card center " height='auto'>   
          <div>     
            <div >
              <div >
                <Title text="TORCH - All your research needs. One single platform" />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/beKbtVF98wc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>              
              </div>
              <div>
                <Title text="How to setup your TORCH Profile"  />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/xukfQJAmiCo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>  
              </div>
              <div>
                <Title text="How to use TORCH Toolkit Tools"  />
                <iframe height="315" width="600" src="https://www.youtube.com/embed/hrFX6ugW32s" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>  
              </div>
              <div>
                <Title text="How to use a TORCH Toolkit List"  />
                <iframe height="315" width="600" src="https://www.youtube.com/embed/CyL8cXkhujg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>          
              </div>
              <div>
              <Title text="How to download the TORCH Plug-in"  />
                <iframe height="315" width="600" src="https://www.youtube.com/embed/gZkmztUFsoQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              <div><Title text="How to use the TORCH Toolkit Wizard"  />
                <iframe height="315" width="600"  src="https://www.youtube.com/embed/h-lr3tyQ_tk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      )
}

export default TutorialsContent

