import React from 'react'
import { Link } from 'react-router-dom'
import ThreeDot from '../Elements/ThreeDots'

function TopLists (props) {
    const { data } = props


    return (
            <div className="top-list__sections  mr-30 flexer-4 ">
                <div className="flexer flexer-spaced">
                    <div className="left">
                        <Link to={`/lists/detail/${data.id}`}>
                            <p className="subheading is-bold line-margin-s">{data.name}</p>
                        </Link>
                        <p className="text-xs is-light line-margin-0">Author: <span className="text-xs is-bold">{data.created_by.public_name}</span></p>
                    </div>
                    <div className="remaining-width right">
                        <ThreeDot id={props.data.id} isTop={true} data={props.data} currentUser={props.currentUser} onUpdate={props.onUpdate}/>
                    </div>
                </div>
            </div>
    )
}

export default TopLists
