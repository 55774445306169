import config from './config';
import { handleResponse } from './handle-response';


export const tagService = {
    list,
    listByUrl,
    search,
    toolsListTags,
}

async function list() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tags/?limit=1000&offset=0`, requestOptions)
        .then(handleResponse)
        .then(tag => {
            return tag
        })
}

async function toolsListTags() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tags_tools_list/`, requestOptions)
        .then(handleResponse)
        .then(tag => {
            return tag
        })
}

async function listByUrl(url) {
	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(url, requestOptions)
	.then(handleResponse)
	.then(tag => {
			return tag
	})
}

async function search(keyword) {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tags/search/?tag=${keyword}`, requestOptions)
        .then(handleResponse)
        .then(tag => {
            return tag
        })
}
