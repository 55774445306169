import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from '../components/Hooks/UserContext'
import usePermissions from '../components/Hooks/usePermissions'
import config from '../api/config'


const PrivateRoute = ({ component: Component, ...rest }) => {

    const { currentUser, isLoading } = useContext(UserContext)
    const allowed = usePermissions('cid::web_access', true, null, isLoading)
    return (
        <Route {...rest} render={props => {
            if(isLoading) {
                return <h1>Loading...</h1>
            }
            else if (!currentUser) {
                // not logged in so redirect to login page with the return url
                // return <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
                window.location.assign(`${config.home}/login`)
            }  
            else if (!allowed){
                window.location.assign(`${config.home}/`)
            }
        // authorised so return component
        return <Component {...props} {...rest} />
    }} />)
}

export default PrivateRoute
