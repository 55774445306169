import React, { useState } from "react";
import { Link } from 'react-router-dom'

// Api
import { listService } from "../../api";

// Components
import Button from "../Elements/Button";
import Modal from "../Elements/Modal";
import TopLists from "./TopLists";
import ListCard from "./ListCard";

function ListsDashboard(props) {
  const {
    header_keyword,
    data,
		shared,
    searchResults,
    isActive,
    top,
    next,
    prev,
    handlePagination,
    count,
		onUpdate
  } = props;
  const [searchResultsHeader, setSearchResultsHeader] = useState([])
  const [keyword, setKeyword] = useState(header_keyword)
  const [isOpenReject, setIsOpenReject] = useState(false)
  const [isOpenSubscribe, setIsOpenSubscribe] = useState(false)
  const [toChange, setToChange] = useState({})

  if (header_keyword) {
    listService
      .search(keyword)
      .then((response) => {
        setSearchResultsHeader(response);
        setKeyword("");
      })
      .catch((error) => {
        console.error("Not found", error);
      });
  }

	let display_data
  if (data) {
    display_data = data.map((e) => (
      <ListCard
        data={e}
        key={e.id}
        currentUser={props.currentUser}
        onUpdate={props.onUpdate}
        onAdd={props.onAdd}
      />
    ));
  }

	const handleAction = (action, invitation_id, list_name, shared_by, list_id) => {
		action === 'subscribe' ? setIsOpenSubscribe(true) : setIsOpenReject(true)
		setToChange({invitation_id: invitation_id, list_name: list_name, shared_by: shared_by, list_id: list_id })
	}

	const handleSubscribe = () => {
		listService.acceptShared(toChange.invitation_id)
		.then(response => {
			listService.subscribe(toChange.list_id)
			.then(response => {
					setIsOpenSubscribe(false)
					setToChange({})
					onUpdate()
			})
		})
	}

	const handleReject = () => {
		listService.denyShared(toChange.invitation_id)
		.then( response => {
			setIsOpenReject(false)
			setToChange({})
			onUpdate()
		})
	}

	let shared_data
	if (shared) {
			shared_data = shared
      .map(list => (<tr className="table-rows flexer">
        <td className="text is-light flexer-3 left side-margin-0 mt-10">
					<Link to={`/lists/detail/${list.tools_list?.id}/`} className="text is-light is-bold">{list.tools_list?.name}</Link>
				</td>
        <td className="text is-light flexer-3 left side-margin-0 mt-10">{list.issued_by?.public_name}</td>
        <td className="text is-light flexer-3 left side-margin-0 mt-10">{list.join_at}</td>
        <td className="text remaining-width right side-margin-0 cursor">
          <Button type="button" text={'Subscribe'} class={"blue-btn mr-10"} 
						onClick={() => handleAction( 'subscribe', list.id, list.tools_list.name, list.issued_by.public_name, list.tools_list.id)} />
          <Button type="button" text={'Reject'} class={"white-btn "} 
						onClick={() => handleAction( 'reject', list.id, list.tools_list.name, list.issued_by.public_name)} />
          </td>
      </tr>))
	}

  if (top) {
    var top_lists = top
      .slice(0, 6)
      .map((e) => (
        <TopLists
          data={e}
          key={e.id}
          currentUser={props.currentUser}
          onUpdate={props.onUpdate}
        />
      ));
  }

  let display_length = count;

  let list_title = "";
  if (isActive === "by-me") {
    list_title = "created by me";
  } else if (isActive === "subscribed") {
    list_title = "subscribed";
  }

  return (
    <React.Fragment>
      <div className="full-width">
        {isActive === "all-lists" && !props.isFilterActive ? (
          <div className="simple-profile__sections">
            <p className="subtitle left is-bold">Top lists</p>
            <div className="flexer flexer-wrapper flexer-mobile">
              {top_lists}
            </div>
          </div>
        ) : null}



				<div className="simple-profile__sections">

					{ isActive === 'shared'
						?
							<>
							<p className="subtitle left is-bold">Lists shared</p>
							<table className="table" cellSpacing="0">
								<thead>
									<tr className="table-header flexer mt-15">
										<th className="text is-light flexer-3 left side-margin-0">Name</th>
										<th className="text is-light flexer-3 left side-margin-0">Shared by</th>
										<th className="text is-light flexer-3 left side-margin-0">Shared at</th>
										<th className="text is-light remaining-width left side-margin-0"></th>
									</tr>
								</thead>
								<tbody>
								{shared_data}
								</tbody>
							</table>
							</>
						:
						<div className="flexer left flexer-mobile">
							<div className="full-width">
								<p className="subtitle left is-bold">Lists
									<span className="text is-light ml-10">Found {display_length} lists {list_title}</span>{" "}
								</p>
								{display_data}
							</div>
						</div>
							}


          <div className="flexer right">
            {prev ? (
              <p className="subheading is-dark button blue-btn cursor" onClick={() => handlePagination(prev)} >
                <i className="fa fa-arrow-left mr-10 is-white"></i>Previous
              </p>
            ) : null}
            <p className="subheading is-light mx-10"></p>
            {next ? (
              <p className="subheading is-dark button blue-btn cursor" onClick={() => handlePagination(next)} >
                Next<i className="fa fa-arrow-right ml-10 is-white"></i>
              </p>
            ) : null}
          </div>
        </div>
      </div>

			<Modal isOpen={isOpenSubscribe} handleClose={() => { setIsOpenSubscribe(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">List subscription</h1>
          <p className="text is-light mx-30">You will be subscribed to the list 
					<span className="is-dark is-bold">{` ${toChange.list_name}`}</span> shared by 
					<span className="is-dark is-bold">{` ${toChange.shared_by}`}</span></p>
          <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleSubscribe} />
        </div>
      </Modal>

			<Modal isOpen={isOpenReject} handleClose={() => { setIsOpenReject(false) }}>
        <div className="center">
          <h1 className="title is-dark mb-30">Reject shared list</h1>
          <p className="text is-light mx-30">Are you sure that you want to reject the list  
					<span className="is-dark is-bold">{` ${toChange.list_name}`}</span> shared by  
					<span className="is-dark is-bold">{` ${toChange.shared_by}`}</span>?</p>
          <Button text="Confirm" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleReject} />
        </div>
      </Modal>

    </React.Fragment>
  );
}

export default ListsDashboard;
