import {Link, useHistory} from 'react-router-dom'
import {useEffect, useState} from 'react'

import CidTorchMenu from '../components/Elements/CidTorchMenu'
import {listService, tagService, toolService} from '../api'
import MenuTabItem from '../components/Header/MenuTabItem'
import ProfileMenu from '../components/Header/ProfileMenu'
import SearchBar from '../components/Elements/SearchBar'
import RunWizard from '../components/Wizard/RunWizard'

import Logo from '../assets/images/torch-logo.png'

const Header = () => {
  const [searchValue, setSearchValue] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchResultsLists, setSearchResultsLists] = useState([])
  const [searchResultsTools, setSearchResultsTools] = useState([])
  const [isOpenRunWizard, setIsOpenRunWizard] = useState(false)

  const [hasTags, setHasTags] = useState(false)
  const [keyword, setKeyword] = useState(null)

  const [isActive, setIsActive] = useState('tools')
  const [refresh, setRefresh] = useState(1)
  const history = useHistory()
  var path = window.location.pathname

  const handleExpandable = () => {
    var element = document.getElementById('expandable-menu')
    element.classList.toggle('mobile-hidden')
    element.classList.toggle('mobile-shown')
  }

  useEffect(() => {
    if (searchValue.length > 0) {
      switch (isActive) {
        case 'tags':
          tagService.search(searchValue)
            .then(response => {
              let tag_id = response.map(e => e.id)
              listService.filter({ tags: tag_id })
                .then(response => {
                  setSearchResultsLists(response.results)
                })
                .catch(error => {
                  console.error('Not found', error)
                })
              toolService.filter({ tags: tag_id, approved: true })
                .then(response => {
                  setSearchResultsTools(response.results)
                })
                .catch(error => {
                  console.error('Not found', error)
                })
              setKeyword(searchValue)
            })
            .catch(error => {
              console.error('Not found', error)
            })
          break;

        case 'lists':
          listService.search(searchValue)
            .then(response => {
              setSearchResults(response)
              setKeyword(searchValue)
            })
            .catch(error => {
              console.error('Not found', error)
            })
          break;

        case 'tools':
          toolService.filter({ keyword: searchValue })
            .then(response => {
              setSearchResults(response.results)
              setKeyword(searchValue)
            })
            .catch(error => {
              console.error('Not found', error)
            })
          break;

        default:
          setSearchResults([])
          break;
      }
    } else {
      setSearchResults([])
      setSearchResultsLists([])
      setSearchResultsTools([])
    }
  }, [searchValue, isActive])

  const closeTagsSearchBar = () => {
    setHasTags(false)
    setSearchResults([])
    setKeyword(null)
    setIsActive('tags')
    setSearchValue('')
  }

  const handleActive = (active_tag) => {
    setIsActive(active_tag)
    setRefresh(refresh + 1)
  }

  let redirect_to
  if (isActive === 'lists') {
    redirect_to = '/lists/detail/'
  } else if (isActive === 'tools') {
    redirect_to = '/tools/detail/'
  }

  const handleLink = (e, url) => {
    e.preventDefault()

    if (url === path) {
      window.location = path
    } else {
      history.push(url)
    }
  }

  let results = null
  let view_more
  let lists_results
  let tools_results

  if (hasTags) {
    if (isActive === 'tags') {
      lists_results = searchResultsLists.slice(0, 3).map((e, idx) =>
        <div className="search-results cursor" id={idx}>
          <a href={`/lists/detail/${e.id}`} className="flexer">
            <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
            <p className="text is-light line-margin-0"><span className="text is-light"> List </span>{e.name}</p>
          </a>
        </div>
      )
      tools_results = searchResultsTools.slice(0, 3).map((e, idx) =>
        <div className="search-results cursor" id={idx}>
          <a href={`/tools/detail/${e.id}`} className="flexer">
            <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
            <p className="text is-light line-margin-0"><span className="text is-light"> Tool </span>{e.name}</p>
          </a>
        </div>
      )
    }

    results = searchResults.slice(0, 3).map((item, idx) => {
      const url = `${redirect_to}${item.id}`

      return (
        <div className="search-results cursor" id={idx}>
          <a
            href={url}
            onClick={(e) => handleLink(e, url)}
            className="flexer">
            <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
            <p className="text is-light line-margin-0">{item.name}</p>
          </a>
        </div>
      )
    })

    view_more = <Link to={`/${isActive}/${keyword}`} >
      <div className="search-results cursor flexer" onClick={()=>{setHasTags(false)}}>
        <i className="fa fa-angle-right line-margin-0 mr-10 is-light"></i>
        <p className="text is-light line-margin-0">View more...</p>
      </div>
    </Link>
  }


  var runWizardBtn = <div className="case-btn full-width-mobile" onClick={() => { setIsOpenRunWizard(true) }}>
    <p className="subheading text-white cursor"><i className="fas fa-plus" />Run Wizard</p>
  </div>


  return (
    <>
      <div className="header">
        <div className="row flexer flexer-vcenter">
          <div className='ml-20'>
            <CidTorchMenu />
          </div>
          <div className="col-2 center-mobile">
            <img src={Logo} className="logo" alt="toolkit-logo" />
          </div>
          <div className="col-6 relative">

            <SearchBar
              placeholder={"Search anything..."}
              handleOpen={() => setHasTags(true)}
              tags={hasTags}
              handleClose={closeTagsSearchBar}
              handleActive={handleActive}
              isActive={isActive}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />

            <div className="absolute full-width on-top">
              {isActive === 'tags' && lists_results}
              {isActive === 'tags' && tools_results}
              {isActive !== 'tags' && results}
              {/* {(results && results.length) || (lists_results && lists_results.length) || (tools_results && tools_results.length) ? view_more : null} */}
              { (searchResults && searchResults.length > 3) || (searchResultsTools && searchResultsTools.length > 3) || (searchResultsLists && searchResultsLists.length > 3) ? view_more : null}
            </div>
          </div>
          <div className="col-3 flexer flexer-spaced flexer-vcenter right">
            {/* <ThemeToggle onChange={setTheme} /> */}
            <div className="hidden mobile-shown center-mobile" onClick={handleExpandable}>
              <p className="mx-10">Menu<i className="fa fa-bars mx-10"></i></p>
            </div>
            <ProfileMenu />
          </div>
        </div>
        <div className="flexer flexer-spaced header-tabs flexer-mobile">
          <div id="expandable-menu" className="flexer flexer-mobile mobile-hidden">
            <Link to={`/tools`} className="line-margin-0">
              <MenuTabItem text="Tools" icon="fa fa-suitcase" active={path === `/tools` ? true : false} />
            </Link>
            <Link to={`/lists`} className="line-margin-0">
              <MenuTabItem text="Lists" icon="far fa-list-alt" active={path === `/lists` ? true : false} />
            </Link>
            <Link to={`/wizard_sessions`} className="line-margin-0">
              <MenuTabItem text="Wizard" icon="fa fa-magic" active={path === `/wizard_sessions` ? true : false} />
            </Link>
            <Link to={`/tutorials`} className="line-margin-0">
              <MenuTabItem text="Tutorials" icon="fa fa-youtube" active={path === `/tutorials` ? true : false} />
            </Link>
          </div>
          <div className="right center-mobile">
            {runWizardBtn}
          </div>
        </div>
      </div>
      <RunWizard isOpen={isOpenRunWizard} handleClose={() => { setIsOpenRunWizard(false) }} />
    </>
  )
}

export default Header
