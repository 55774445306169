import {useContext} from 'react'

import { UserContext } from './UserContext'
import { checkPermissions } from '../../permissions-validation/checkPermissions'
import useOrgId from './useOrgId'

export default function usePermissions(
  requiredPermissions,
  checkAll,
  objectId,
  isLoading=false
) {

  const { currentUser, currentOrganizations } = useContext(UserContext)
  const orgId = useOrgId()
  console.log('isLoading', isLoading)
  if (!currentUser){
    return false
  }

  if (currentUser.role === 'SUPER_ADMIN') {
    return true
  }

  if (orgId) {
    return checkPermissions(currentUser.app_permission_policy?.policy ? currentUser.app_permission_policy.policy : {}, 
      requiredPermissions, checkAll, objectId) || 
    checkPermissions(currentOrganizations[orgId].policy?.policy ? currentOrganizations[orgId].policy.policy : {}, 
      requiredPermissions, checkAll, objectId)
  }

  return checkPermissions(currentUser.app_permission_policy?.policy ? currentUser.app_permission_policy.policy : {}, 
    requiredPermissions, checkAll, objectId)

}