import React from "react";

// Components
import Button from "../Elements/Button";
import Tool from "../Elements/Tool";

function WizardCard(props) {
  const { user_input, target, user_chosen_suggestions, tools, id } = props.data;

  let tools_ =
    tools &&
    tools.map((e) => (
      <div className="mr-20" key={e.id}>
        <Tool data={e} />
      </div>
    ));

  let inputs_;
  let suggestions_;
  if (target === "Many") {
    inputs_ = 
    (
    user_input && (
        <div className="flexer flexer-wrapper mb-20">
          {user_input.firstName && (
            <>
              <p className="text is-light mr-30 line-margin-s">
                First name:{" "}
                <span className="text is-bold line-margin-s">{user_input.firstName}</span>
              </p>
              <p className="text is-light mr-30 line-margin-s">
                Middle name:{" "}
                <span className="text is-bold line-margin-s">
                  {user_input.middleName ?? "-"}
                </span>
              </p>
              <p className="text is-light mr-30 line-margin-s">
                Last name:{" "}
                <span className="text is-bold line-margin-s">
                  {user_input.lastName ?? "-"}
                </span>
              </p>
            </>
          )}
          {user_input.email && (
            <p className="text is-light mr-30 line-margin-s">
              Email: <span className="text is-bold line-margin-s">{user_input.email}</span>
            </p>
          )}
          {user_input.phoneNumber && (
            <p className="text is-light mr-30 line-margin-s">
              Phone:{" "}
              <span className="text is-bold line-margin-s">{user_input.phoneNumber}</span>
            </p>
          )}
          {user_input.username && (
            <p className="text is-light mr-30 line-margin-s">
              Username:{" "}
              <span className="text is-bold line-margin-s">{user_input.username}</span>
            </p>
          )}
          {user_input.address && (
            <p className="text is-light mr-30 line-margin-s">
              Address:{" "}
              <span className="text is-bold line-margin-s">{user_input.address}</span>
            </p>
          )}
        </div>
      )
    );
    suggestions_ =
      user_chosen_suggestions &&
      user_chosen_suggestions.map((e, idx) => (
        <div className="flexer flexer-wrapper my-20" key={idx}>
        {e.firstName && (
          <>
            <p className="text is-light mr-20 line-margin-s">
              First name:{" "}
              <span className="text is-bold line-margin-s">{e.firstName}</span>
            </p>
            <p className="text is-light mr-20 line-margin-s">
              Middle name:{" "}
              <span className="text is-bold line-margin-s">
              {e?.middleName?.length ? e.middleName : "-"}
              </span>
            </p>
            <p className="text is-light mr-20 line-margin-s">
              Last name:{" "}
              <span className="text is-bold line-margin-s">
              {e?.lastName?.length ? e.lastName : "-"}
              </span>
            </p>
          </>
        )}
        {e.email && (
          <p className="text is-light mr-20 line-margin-s">
            Email: <span className="text is-bold line-margin-s">{e.email}</span>
          </p>
        )}
        {e.phoneNumber && (
          <p className="text is-light mr-20 line-margin-s">
            Phone:{" "}
            <span className="text is-bold line-margin-s">{e.phoneNumber}</span>
          </p>
        )}
        {e.username && (
          <p className="text is-light mr-20 line-margin-s">
            Username:{" "}
            <span className="text is-bold line-margin-s">{e.username}</span>
          </p>
        )}
        {e.address && (
          <p className="text is-light mr-20 line-margin-s">
            Address:{" "}
            <span className="text is-bold line-margin-s">{e.address}</span>
          </p>
        )}
        </div>
      ));
  }
  if (target === "Real Name") {
    inputs_ = (
      <div className="flexer flexer-wrapper">
      <p className="text is-light mr-30 line-margin-s">
        First name:{" "}
        <span className="text is-bold line-margin-s">{user_input.firstName}</span>
      </p>
      <p className="text is-light mr-30 line-margin-s">
        Middle name:{" "}
        <span className="text is-bold line-margin-s">
          {user_input.middleName ?? "-"}
        </span>
      </p>
      <p className="text is-light mr-30 line-margin-s">
        Last name:{" "}
        <span className="text is-bold line-margin-s">
          {user_input.lastName ?? "-"}
        </span>
      </p>
    </div>
    );
    suggestions_ =
      user_chosen_suggestions &&
      user_chosen_suggestions.map((e, idx) => (
        <div className="flexer flexer-wrapper" key={idx}>
            <p className="text is-light mr-20 line-margin-s">
              First name:{" "}
              <span className="text is-bold line-margin-s">{e.firstName}</span>
            </p>
            <p className="text is-light mr-20 line-margin-s">
              Middle name:{" "}
              <span className="text is-bold line-margin-s">
              {e?.middleName?.length ? e.middleName : "-"}
              </span>
            </p>
            <p className="text is-light mr-20 line-margin-s">
              Last name:{" "}
              <span className="text is-bold line-margin-s">
              {e?.lastName?.length ? e.lastName : "-"}
              </span>
            </p>
        </div>
      ));
  }
  if (target === "Email") {
    inputs_ = (
      <p className="text line-margin-s is-bold mr-30">{user_input.email}</p>
    );
    suggestions_ =
      user_chosen_suggestions &&
      user_chosen_suggestions.map((e, idx) => (
        <p className="text line-margin-s is-bold mr-30" key={idx}>
          {e.email}
        </p>
      ));
  }
  if (target === "Phone") {
    inputs_ = (
      <p className="text line-margin-s is-bold mr-30">
        {user_input.phoneNumber}
      </p>
    );
    suggestions_ =
      user_chosen_suggestions &&
      user_chosen_suggestions.map((e, idx) => (
        <p className="text line-margin-s is-bold mr-30" key={idx}>
          {e.phoneNumber}
        </p>
      ));
  }
  if (target === "User Name") {
    inputs_ = (
      <p className="text line-margin-s is-bold mr-30">{user_input.username}</p>
    );
  }
  if (target === "Address") {
    inputs_ = (
      <p className="text line-margin-s is-bold mr-30">{user_input.address}</p>
    );
  }

  return (
    <div className="wizard-card left">
      <div className="flexer flexer-spaced flexer-vcenter">
        <p className="heading is-light is-bold">
          Wizard saved number {props.data.id}{" "}
          <i
            className="fa fa-trash is-light ml-10 cursor"
            onClick={() => props.onDelete(id)}
          />{" "}
        </p>
        <Button
          text={"Launch"}
          class={"blue-btn mr-10"}
          icon={"fa fa-play is-white"}
          onClick={() => props.onClick(id)}
        />
      </div>
      <div className="my-10">
        <p className="text is-light is-bold">
          Starting Point: <span className="text is-bold"> {target}</span>
        </p>
        {/* <div className="flexer flexer-vcenter"> */}
        <div className={target === "Many" ? 'my-20' :"flexer flexer-vcenter"}>
          <p className="text line-margin-s is-light mr-20 is-bold">
            Your search input:
          </p>
          {inputs_}
        </div>
      </div>
      {user_chosen_suggestions.length ? (
        <div className={(target === "Many" || target === 'Real Name') ? 'my-10' :"flexer"}>
          <p className="text line-margin-s is-light is-bold mr-20 mt-20">
            Your chosen suggestions:
          </p>
          <div className={(target === "Many" || target === 'Real Name')  ? 'my-10' :"flexer flexer-wrapper flexer-vcenter"}>
            {suggestions_}
          </div>
        </div>
      ) : null}
      <div className="my-20">
        <p className="text is-light is-bold">Tools selected:</p>
        <div className="flexer flexer-wrapper">{tools_}</div>
      </div>
    </div>
  );
}

export default WizardCard;
