import React, {useState} from 'react'

function Checkbox(props) {


    let checked_value = false
    if(props.checked) {
        checked_value = props.checked
    } 

    const [checked, setChecked] = useState(checked_value)

    function handleClick(e){
        setChecked(!checked)
        props.onSelect && props.onSelect()
    }



    let input_element
    if (props.value) {
        input_element = <input className="checkbox" type="checkbox" name={props.name} required={props.required? true : false} value={props.value} checked={checked} onClick={handleClick}/>
    }
    else{
        input_element = <input className="checkbox" type="checkbox" name={props.name} required={props.required? true : false}/>
    }

    return (
        <div className="">
            <label className="subheading is-light">
                {input_element}{props.label} {props.children}
            </label>
            <p className="error-message">{props.error}</p>
        </div>
    )
    
}

export default Checkbox