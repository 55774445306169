import React from 'react'

// Components
import StepOneForm from './StepOneForm'

function StepOne(props) {
    return (
        <React.Fragment>
            <div className="flexer flexer-spaced">
                <h1 className="title is-dark line-margin-0">{props.edit ? 'Edit List' : 'List Creation'}</h1>
                <p className="special-text is-blue line-margin-0">Step 1</p>
            </div>
            <StepOneForm editData={props.editData} edit={props.edit} onUpdate={props.onUpdate} data={props.data} errors={props.errors}/>
        </React.Fragment>
    )
}

export default StepOne