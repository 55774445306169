import React, {useState} from 'react'

function Spinnerr(props) {

    return (
        <p className='fa fa-spinner fa-3x fa-spin'></p>
    )
}

export default Spinnerr
