import React from 'react'

function MenuButton(props) {
    var ClassName = "list-menu cursor"

    if (props.active) {
        ClassName ="list-menu list-menu__active cursor"
    }

    var projectClass = props.project ? "menu-tab__project" : ""

    if (props.icon) {
        return (
            <div className={`${ClassName} ${projectClass} flexer flexer-vcenter`} onClick={props.onClick}>
                <p className="subheading is-light ml-5"><i className={`${props.icon} text-icon`} />{props.text}</p>
            </div>
        )
    }

    return (
        <div className={`${ClassName} ${projectClass}`} onClick={props.onClick}>
            <p className="subheading is-light ml-5">{props.text}</p>
        </div>
    )
}

export default MenuButton