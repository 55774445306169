import { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../components/Hooks/UserContext'
import styled from 'styled-components'

function ProfileIdsCard({ profileInfo }) {
   const [showCard, setShowCard] = useState(false)
   const [showOrganizationsIds, setshowOrganizationsIds] = useState(false)
   const [organizationsIdsTitle, setOrganizationsIdsTitle] = useState('Organization ID')
   const { currentUser } = useContext(UserContext)

   useEffect(() => {
      if (profileInfo.cognito_id) {
         validateShowCard()
         validateShowOrganizationsIds()
      }
   }, [profileInfo])

   function validateShowCard() {
      if (currentUser.role === 'SUPER_ADMIN' || profileInfo.cognito_id === currentUser.cognito_id) {
         setShowCard(true)
      }
   }
   function validateShowOrganizationsIds() {
      if (profileInfo?.organizations_ids?.length) {
         setshowOrganizationsIds(true)
         if (profileInfo.organizations_ids.length > 1) {
            setOrganizationsIdsTitle('Organizations IDs')
         }
      }
   }

   if (!showCard) return null

   return (
      <Card>
         <strong>ID(s) Information</strong>
         <div className='left'>
            <section className='mb-15'>
               <span><strong>User ID</strong></span>
               <li className='ml-10 text is-light line-margin-s'>
                  {profileInfo.cognito_id}
               </li>
            </section>

            {profileInfo.eag_id ? (
               <section className='mb-15'>
                  <span><strong>EAG ID</strong></span>
                  <li className='ml-10 text is-light line-margin-s'>
                     {profileInfo.eag_id}
                  </li>
               </section>
            ) : null}

            {showOrganizationsIds ? (
               <section>
                  <span><strong>{organizationsIdsTitle}</strong></span>
                  {profileInfo.organizations_ids.map(id => (
                     <li key={id} className='ml-10 text is-light line-margin-s'>{id}</li>
                  ))}
               </section>
            ) : null}
         </div>
      </Card>
   )
}

const Card = styled.div`
	border: #c1c1c1 1px solid;
	padding: 10px;
	border-radius: 5px;
`

export default ProfileIdsCard