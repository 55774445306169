import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import StepTwoWizard from './StepTwoWizard'
import WizardResults from './WizardResults'
import {wizardService} from '../../api'
import Modal from '../Elements/Modal'
import StartPoint from './StartPoint'
import LauchWizard from './LaunchWizard'

import PluginTutorial from './PluginTutorial'

const queryString = require( 'query-string' )

function RunWizard( props ) {
  const parsed = queryString.parse( window.location.search )

  const [ refresh, setRefresh ] = useState( 1 )
  const [ wizardId, setWizardId ] = useState( null )
  const [stage, setStage] = useState("pluginTutorial");
  const [data, setData] = useState({});
  const [toolsSelected, setToolsSelected] = useState([]);
  const [userInput, setUserInput] = useState({});
  const [errors, setErrors] = useState({});
  const [havePlugin, setHavePlugin] = useState(false);
  const [newOptions, setNewOptions] = useState([]);

  if (props.id && props.id !== wizardId) {
    setWizardId(props.id);
    setUserInput(props.userInput);
    let new_options = props.wizardOptions;
    setData({
      tool_target: props.wizardTarget,
      tool_target_inputs: new_options,
    });
    let newTools = props.tools && props.tools;
    setToolsSelected(newTools);
  }

  const history = useHistory();

  const path = window.location.pathname;

  let redirect = path !== "/wizard_sessions" ? "/wizard_sessions" : "/tools";

  useEffect(() => {
    let valid_keys = [
      "firstName",
      "middleName",
      "lastName",
      "username",
      "email",
      "phoneNumber",
      "address",
    ];
    if (!parsed || !parsed.tool_target) {
      return;
    }
    let url_data = { tool_target: parsed.tool_target };
    let url_input = {};
    for (var value in parsed) {
      if (valid_keys.includes(value)) {
        url_input[value] = parsed[value];
      }
    }
    setUserInput(url_input);
    setData(url_data);
  }, []);

  const launch = () => {
    const { tool_target, tools, tool_target_inputs } = data;
    const tool_inputs = tool_target_inputs
      ? [...tool_target_inputs, userInput]
      : [userInput];
    let local_body = { tool_target, tools, tool_target_inputs: tool_inputs };
    local_body.tool_target_inputs.forEach((x, i) => {
      if ("phoneNumber" in x) {
        local_body.tool_target_inputs[i].phone = x.phoneNumber;
      }
    });
    //toolController(local_body.tool_target, local_body.tool_target_inputs, local_body.tools)
    var event = new CustomEvent("pluginSearch", { detail: local_body });
    document.dispatchEvent(event);
  };

  useEffect(() => {
    const pingCidPlugin = new CustomEvent("pingCidPlugin");
    document.dispatchEvent(pingCidPlugin);

    if (sessionStorage.getItem("plugin")) {
      setHavePlugin(true);
      setStage("startPoint");
    }
  }, [props.isOpen]);

  function changeData(new_data) {
    setData({ ...data, ...new_data });
  }

  function handleSubmit() {
    let form_element = document.getElementById("run-wizard-form");
    let form_data = [];
    if (stage !== "completed") {
      form_data = new FormData(form_element);
    }
    let body_input = stage !== "startPoint" ? userInput : {};
    let body = data;
    let tool_target_inputs = [];
    let tools = [];
    let tools_ids = [];
    form_data.forEach((value, key) => {
      if (value) {
        if (key === "options") {
          tool_target_inputs.push(JSON.parse(value));
        } else if (key === "tools") {
          tools.push(value);
        } else if (key === "tools_ids") {
          tools_ids.push(parseInt(value));
        } else if (key === "tool_target") {
          body[key] = value;
        } else {
          body_input[key] = value;
        }
      }
    });

    !newOptions?.length && setNewOptions(tool_target_inputs);

    if (stage === "startPoint" || stage === "stepTwo" || stage === "lastStep") {
      if (tool_target_inputs.length) {
        body = { ...body, tool_target_inputs };
      }
      if (stage === "stepTwo" && !tool_target_inputs.length) {
        body = { ...body, tool_target_inputs: [] };
      }
    } else {
      body = { ...body, tool_target_inputs: newOptions };
    }

    if (tools.length) {
      body = { ...body, tools };
    }
    if (tools_ids.length) {
      setToolsSelected(tools_ids);
    }

    setData(body);
    setUserInput(body_input);

    if (stage === "startPoint") {
      let errors_ = {};

      if (data.tool_target === "Real Name" && !body_input.firstName) {
        errors_["firstName"] = "First name is required";
      }
      if (data.tool_target === "Many") {
        if (body_input.middleName && !body_input.firstName) {
          errors_["firstName"] = "First name is required";
        }
        if (body_input.lastName && !body_input.firstName) {
          errors_["firstName"] = "First name is required";
        }
      }
      if (Object.keys(body_input).length === 0) {
        errors_["errors"] = "At least one field is required";
      }
      if (Object.keys(body_input).length === 0) {
        errors_["errors"] = "At least one field is required";
      }
      if (body_input?.phoneNumber?.length < 11) {
        errors_["phoneNumber"] =
          "Phone number must contain at least 11 characters";
      }
      if (Object.values(errors_).length === 0) {
        setStage("stepTwo");
        setErrors({});
      } else {
        setErrors({ ...errors_ });
      }
    } else if (stage === "stepTwo") {
      setStage("lastStep");
      setErrors({});
    } else if (stage === "lastStep") {
      if (!body.tools) {
        setErrors({ errors: "At least one option is required" });
      } else {
        setStage("completed");
        setErrors({});
      }
    } else if (stage === "completed") {
      let body = {
        user_input: userInput,
        user_chosen_suggestions: data.tool_target_inputs ?? [],
        target: data.tool_target,
        tools: toolsSelected,
      };
      if (wizardId) {
        wizardService
          .update(body, wizardId)
          .then(() => {
            props.handleClose();
            launch();
            history.push(redirect);
          })
          .catch((error) => {
            console.log("Error updating wizard", error);
          });
      } else {
        wizardService
          .create(body)
          .then(() => {
            setRefresh(refresh + 1);
            props.handleClose();
            launch();
            history.push(redirect);
          })
          .catch((error) => {
            console.log("Error creating wizard", error);
          });
      }
    }
  }

  const hdlClose = () => {
    setWizardId(null);
    setStage("startPoint");
    setData({});
    setToolsSelected([]);
    setUserInput({});
    setErrors({});
    setNewOptions([]);
    props.handleClose();
  };

  var button1 = {
    pluginTutorial: { text: "Cancel", funct: props.handleClose },
    startPoint: { text: "Cancel", funct: hdlClose },
    stepTwo: {
      text: "Previous Step",
      funct: () => {
        setStage("startPoint");
      },
    },
    lastStep: {
      text: "Previous Step",
      funct: () => {
        setStage("stepTwo");
      },
    },
    completed: {
      text: "Previous Step",
      funct: () => {
        setStage("lastStep");
      },
    },
  };

  var button2 = {
    'pluginTutorial': {
      text: 'Next Step', funct: () => {
      }, disabled: true,
    },
    startPoint: { text: 'Next Step', funct: handleSubmit, disabled: false },
    stepTwo: { text: 'Next Step', funct: handleSubmit, disabled: false },
    lastStep: { text: 'Next Step', funct: handleSubmit, disabled: false },
    completed: { text: 'Run Wizard', funct: handleSubmit, disabled: false },
  };

  var stageComponent;
  if (stage === "pluginTutorial") {
    stageComponent = <PluginTutorial />;
  } else if (stage === "startPoint") {
    stageComponent = (
      <StartPoint
        onUpdate={changeData}
        data={data}
        userInput={userInput}
        errors={errors}
        wizardId={wizardId}
        onRefresh={hdlClose}
      />
    );
  } else if (stage === "stepTwo") {
    stageComponent = (
      <StepTwoWizard
        onUpdate={changeData}
        data={data}
        userInput={userInput}
        errors={errors}
        originalUserInput={wizardId ? props.userInput : userInput}
      />
    );
  } else if (stage === "lastStep") {
    stageComponent = (
      <WizardResults
        onUpdate={changeData}
        data={data}
        toolsSelected={toolsSelected}
        userInput={userInput}
        errors={errors}
      />
    );
  } else if (stage === "completed") {
    stageComponent = (
      <LauchWizard
        onUpdate={changeData}
        data={data}
        toolsSelected={toolsSelected}
        userInput={userInput}
        errors={errors}
      />
    );
  }

  return <Modal
    isOpen={ props.isOpen }
    fullScreen={ true }
    buttons={ { button1: button1[ stage ], button2: button2[ stage ] } }
    handleClose={ hdlClose }
  >
    { stageComponent }
  </Modal>

}

export default RunWizard;
