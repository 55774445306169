import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'

// Assets
import './assets/styles/grid/simple-grid.min.css'
import './assets/styles/variables.scss'
import './assets/styles/main.scss'

// Pages

import Profile from './pages/Profile'
import LandingCid from './pages/LandingCid'
import Lists from './pages/Lists'
import ListDetail from './pages/ListDetail'
import Tags from './pages/Tags'
import Tools from './pages/Tools'
import Wizard from './pages/Wizard'
import Tutorials from './pages/Tutorials'

// Hooks
import { UserContext } from './components/Hooks/UserContext'
import useFindUser from './components/Hooks/useFindUser'

// Theme mode
import ThemeMode from './components/ThemeMode'

function App() {

	const { currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading } = useFindUser()

	return (
		<ThemeMode>
			<BrowserRouter>
				<UserContext.Provider value={{ currentUser, setCurrentUser, currentOrganizations, setCurrentOrganizations, isLoading }}>
					<Switch>
						<PrivateRoute exact path='/tutorials' component={Tutorials} />
						<PrivateRoute exact path='/wizard' component={LandingCid} />
						<PrivateRoute exact path='/wizard_sessions' component={Wizard} />
						<PrivateRoute exact path='/lists' component={Lists} />
						<PrivateRoute exact path='/lists/:keyword' component={Lists} />
						<PrivateRoute exact path='/tools' component={Tools} />
						<PrivateRoute exact path='/tools/:keyword' component={Tools} />
						<PrivateRoute exact path='/tags/:tag' component={Tags} />
						<PrivateRoute exact path='/lists/detail/:list_id' component={ListDetail} />
						<PrivateRoute exact path='/tools/detail/:tool_id' component={Tools} />
						<PrivateRoute exact path="/profile/:user_id" component={Profile} />
						<PrivateRoute exact path='/' component={Tools} />
					</Switch>
				</UserContext.Provider>	
			</BrowserRouter>
		</ThemeMode>
	)
}

export default App;
