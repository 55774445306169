import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

export const wizardService = {
    list,
    listByUrl,
    retrieve,
    create,
    update,
    remove
}

async function list() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_wizard/`, requestOptions)
        .then(handleResponse)
        .then(wizard => {
            return wizard
        })
}

async function listByUrl(url) {
	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(url, requestOptions)
	.then(handleResponse)
	.then(wizard => {
			return wizard
	})
}

async function retrieve(wizard_id) {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_wizard/${wizard_id}/`, requestOptions)
        .then(handleResponse)
        .then(wizard => {
            return wizard
        })
}

async function create(body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body:JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_wizard/`, requestOptions)
        .then(handleResponse)
        .then(wizard => {
            return wizard
        })
}

async function update(body, wizard_id) { 
    body = fixBody(body)
    const requestOptions = { method: 'PATCH', credentials: 'include', headers:{'Content-Type': 'application/json'}, body:JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_wizard/${wizard_id}/`, requestOptions)
        .then(handleResponse)
        .then(wizard => {
            return wizard
        })
}

async function remove(wizard_id) {
    const requestOptions = { method: 'DELETE', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_wizard/${wizard_id}/`, requestOptions)
        .then(handleResponse)
        .then(wizard => {
            return wizard
        })
}
