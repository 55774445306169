import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../Hooks/UserContext";
import { useParams, useHistory } from "react-router-dom";

// APi
import { listService } from "../../api";
import config from "../../api/config";

//Components
import Button from "../Elements/Button";
import InputField from "../Elements/InputField";
import Modal from "../Elements/Modal";
import ThreeDots from "../Elements/ThreeDots";
import Tool from "../Elements/Tool";
import Tag from "../Elements/Tag";
import ReviewContent from "../../containers/Content/ReviewContent";

function ListDetailCard(props) {
	const { list_id } = useParams();
	const history = useHistory();
	const { currentUser } = useContext(UserContext);

	const [listDetail, setListDetail] = useState(null);
	const [refresh, setRefresh] = useState(1);

	const [isOpenAddTags, setIsOpenAddTags] = useState(false);
	const [tags, setTags] = useState([]);
	const [tagInputValue, setTagInputValue] = useState("");

	useEffect(() => {
		listService
			.retrieve(list_id)
			.then((response) => {
				setListDetail(response);
				setTags(response.tags);
			})
			.catch((error) => {
				console.log("Error loading list detail", error);
			});
	}, [refresh, list_id]);

	// info
	let list_name = listDetail && listDetail.name ? listDetail.name : null;
	let list_author =
		listDetail && listDetail.created_by.public_name
			? listDetail.created_by.public_name
			: null;
	let list_description =
		listDetail && listDetail.description ? listDetail.description : null;

	// tools
	let discover =
		listDetail &&
		listDetail.tools.filter(
			(e) => e.categories === "discover" || e.categories === "Discover"
		);
	let tools_discover =
		discover &&
		discover.map((e) => (
			<div className={`flexer my-20 ${e.active && "cursor"}`} key={e.id}>
				{<Tool data={e} onUpdate={() => setRefresh(refresh + 1)}/>}
			</div>
		));

	let develop =
		listDetail &&
		listDetail.tools.filter(
			(e) => e.categories === "develop" || e.categories === "Develop"
		);
	let tools_develop =
		develop &&
		develop.map((e) => (
			<div className={`flexer my-20 ${e.active && "cursor"}`} key={e.id}>
				{<Tool data={e} onUpdate={() => setRefresh(refresh + 1)}/>}
			</div>
		));

	let monitor =
		listDetail &&
		listDetail.tools.filter(
			(e) => e.categories === "monitor" || e.categories === "Monitor"
		);
	let tools_monitor =
		monitor &&
		monitor.map((e) => (
			<div className={`flexer my-20 ${e.active && "cursor"}`} key={e.id}>
				{<Tool data={e} onUpdate={() => setRefresh(refresh + 1)}/>}
			</div>
		));

	let resources =
		listDetail &&
		listDetail.tools.filter(
			(e) => e.categories === "resources" || e.categories === "Resources"
		);
	let tools_resources =
		resources &&
		resources.map((e) => (
			<div className={`flexer my-20 ${e.active && "cursor"}`} key={e.id}>
				{<Tool data={e} onUpdate={() => setRefresh(refresh + 1)}/>}
			</div>
		));

	let training_support =
		listDetail &&
		listDetail.tools.filter(
			(e) =>
				e.categories === "training_support" ||
				e.categories === "Training & Support"
		);
	let tools_training =
		training_support &&
		training_support.map((e) => (
			<div className={`flexer my-20 ${e.active && "cursor"}`} key={e.id}>
				{<Tool data={e} onUpdate={() => setRefresh(refresh + 1)}/>}
			</div>
		));

	let others =
		listDetail &&
		listDetail.tools.filter(
			(e) =>
				e.categories !== "training_support" &&
				e.categories !== "Training & Support" &&
				e.categories !== "resources" &&
				e.categories !== "Resources" &&
				e.categories !== "monitor" &&
				e.categories !== "Monitor" &&
				e.categories !== "develop" &&
				e.categories !== "Develop" &&
				e.categories !== "discover" &&
				e.categories !== "Discover"
		);
	let tools_others =
		others &&
		others.map((e) => (
			<div className={`flexer my-20 ${e.active && "cursor"}`} key={e.id}>
				{<Tool data={e} onUpdate={() => setRefresh(refresh + 1)}/>}
			</div>
		));

	let tags_ =
		listDetail && listDetail.tags.map((e, idx) => <Tag name={e} key={idx} />);

	function handleUpdate() {
		history.push("/lists");
	}

	// add tags
	function handleOpenAdd() {
		setIsOpenAddTags(true);
	}

	function handleAdd() {
		let body = { tags: tags };
		listService.update(body, list_id).then((response) => {
			setIsOpenAddTags(false);
			setRefresh(refresh + 1);
		});
	}

	function handleDeleteTag(tag) {
		let new_tags = tags.reduce((a, v) => ({ ...a, [v]: v }), {});
		delete new_tags[tag];
		let new_tags_updated = Object.keys({ ...new_tags });
		setTags(new_tags_updated);
	}

	function handleChange(e) {
		setTagInputValue(e.value);
	}

	function handleKeyDown(e) {
		if (e.key === "Enter") {
			let value = e.target.value;
			let new_tags = tags;
			new_tags.push(value);
			setTags([...new_tags]);
			setTagInputValue("");
		}
	}

	let tag_elements =
		tags &&
		tags.map((e, idx) => (
			<React.Fragment>
				<Tag tag={e} delete={true} onDelete={handleDeleteTag} key={idx} />
				<input type="hidden" name="tags" value={e} />
			</React.Fragment>
		));

	let three_dots = listDetail ? (
		<ThreeDots
			id={list_id}
			data={listDetail}
			currentUser={currentUser}
			onUpdate={handleUpdate}
			onAdd={handleOpenAdd}
			isDetail={true}
		/>
	) : null;

	return (
		<React.Fragment>
			<div className="list-detail-card ">
				<div className="simple-profile__sections">
					<div className="flexer flexer-spaced flexer-vcenter">
						<div className="">
							<h1 className="title is-bold line-margin-s">{list_name}</h1>
							<p className="text is-light">
								Author: <span className="text is-bold">{list_author}</span>
							</p>
						</div>
						{three_dots}
					</div>
				</div>
				<div className="simple-profile__sections">
					<div className="">
						<h1 className="subtitle">List Description</h1>
						<p className="text is-light line-margin-0">{list_description}</p>
					</div>
				</div>
				<div className="simple-profile__sections">
					<div className="">
						<p className="subtitle">Tags</p>
						<div className="flexer flexer-wrapper my-20">{tags_}</div>
					</div>
				</div>
				<div className="simple-profile__sections">
					<div className="">
						<h1 className="subtitle">Tools used</h1>
						{discover && discover.length ? (
							<p className="text-xs is-bold my-20">Discover</p>
						) : null}
						<div className="flexer flexer-wrapper">{tools_discover}</div>
						{develop && develop.length ? (
							<p className="text-xs is-bold my-20">Develop</p>
						) : null}
						<div className="flexer flexer-wrapper">{tools_develop}</div>
						{monitor && monitor.length ? (
							<p className="text-xs is-bold my-20">Monitor</p>
						) : null}
						<div className="flexer flexer-wrapper">{tools_monitor}</div>
						{resources && resources.length ? (
							<p className="text-xs is-bold my-20">Resources</p>
						) : null}
						<div className="flexer flexer-wrapper">{tools_resources}</div>
						{training_support && training_support.length ? (
							<p className="text-xs is-bold my-20">Training & Support</p>
						) : null}
						<div className="flexer flexer-wrapper">{tools_training}</div>
						{others && others.length ? (
							<p className="text-xs is-bold my-20">Other categories</p>
						) : null}
						<div className="flexer flexer-wrapper">{tools_others}</div>
					</div>
				</div>
				<div className="simple-profile__sections">
					<ReviewContent
						element={"list"}
						element_id={list_id}
						onUpdate={() => setRefresh(refresh + 1)}
					/>
				</div>
			</div>
			<Modal
				isOpen={isOpenAddTags}
				handleClose={() => {
					setIsOpenAddTags(false);
				}}
			>
				<div className="center">
					<p className="heading is-dark">Add tags for your list</p>
					<InputField
						placeholder="Write a tag and press enter"
						value={tagInputValue}
						onKeyDown={handleKeyDown}
						onChange={handleChange}
						forceValue={true}
					/>
					<div className="flexer flexer-wrapper my-20">{tag_elements}</div>
					<Button
						text="Add"
						class="mt-30 one-third-width blue-btn subheading-light"
						type="button"
						onClick={handleAdd}
					/>
				</div>
			</Modal>
		</React.Fragment>
	);
}

export default ListDetailCard;
