import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router'
import { UserContext } from '../../components/Hooks/UserContext'

// Api
import { listService, tagService } from '../../api'

// Components
import Button from '../../components/Elements/Button'
import InputField from '../../components/Elements/InputField'
import Modal from '../../components/Elements/Modal'
import Tag from '../../components/Elements/Tag'
import ListsFilters from '../../components/Lists/ListsFilters'
import ListsDashboard from '../../components/Lists/ListsDashboard'
import Spinnerr from  '../../components/Elements/Spinner'

function ListsContent(){
    const { keyword } = useParams()
    const { currentUser } = useContext(UserContext)

    const [searchKeyword, setsearchKeyword] = useState('')

    const [data, setData] = useState(null)
    const [searchResults, setSearchResults] = useState([])
    const [topLists, setTopLists] = useState(null)
    const [sharedLists, setSharedLists] = useState(null)
    const [isActive, setIsActive] = useState('all-lists')
    const [refresh, setRefresh] = useState(1)

    const [next, setNext] = useState(null)
    const [prev, setPrev] = useState(null)
    const [count, setCount] = useState(null)

    // filters
    const [searchTags, setSearchTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])
    const [isFilterActive, setIsFilterActive] = useState(false)

    // add tags
    const [isOpenAddTags, setIsOpenAddTags] = useState(false)
    const [tags, setTags] = useState([])
    const [tagInputValue, setTagInputValue] = useState("")
    const [idList, setIdList] = useState(null)
    const [isLoading, setLoading] = useState(false)


    useEffect (() => {

        let filters = {
            categories: selectedCategories,
            tags: selectedTags,
            keyword: searchKeyword
        }

        if (filters.categories.length === 0) {
            delete filters['categories']
        }
        if (filters.tags.length === 0) {
            delete filters['tags']
        }
        if (filters.keyword.length === 0) {
            delete filters['keyword']
        }

        if (keyword) {
            setsearchKeyword(keyword)
            setIsFilterActive(true)
        }
            if (isActive === 'all-lists') {
                setLoading(true)
                listService.filter(filters)
                .then(response=>{
                    setData(response.results)
                    setNext(response.next)
                    setPrev(response.previous)
                    setCount(response.count)
                    setLoading(false)
                })
                .catch(error=>{
                    console.log("Error loading lists", error)
                })

            } 
            else if (isActive === "by-me") {
                setLoading(true)
                listService.filter({...filters, created_by: currentUser.cognito_id})
                .then(response=>{
                    setData(response.results)
                    setNext(response.next)
                    setPrev(response.previous)
                    setCount(response.count)
                    setLoading(false)
                })
                .catch(error=>{
                    console.log("Error loading lists", error)
                })
            }
            else if (isActive === "subscribed") {
                setLoading(true)
                listService.filter({...filters, subscribers: currentUser.cognito_id})
                .then(response=>{
                    setData(response.results)
                    setNext(response.next )
                    setPrev(response.previous)
                    setCount(response.count)
                    setLoading(false)
                })
                .catch(error=>{
                    console.log("Error loading lists", error)
                })
            }
            else if (isActive === 'shared') {
                setLoading(true)
                listService.listsShared()
                .then(response=>{
                    setSharedLists(response.results)
                    setNext(response.next )
                    setPrev(response.previous)
                    setLoading(false)
                })
                .catch(error=>{
                    console.log("Error loading shared lists", error)
                })
            }
    }, [refresh, isActive, searchKeyword, selectedCategories, selectedTags, keyword, currentUser.cognito_id])

    useEffect(() => {
        tagService.toolsListTags()
          .then(response => {    
              const sorted = [...response.results].sort((a, b) => a.name > b.name ? 1 : -1)
              setSearchTags(sorted)
           })
            .catch(error => {
              console.log("Error loading list tags", error)
            })
      }, [])
      
    useEffect(() => {
        setLoading(true)
        listService.listTop()
        .then(response=>{
            setTopLists(response.results)
            setLoading(false)
        })
        .catch(error=>{
            console.log("Error loading top lists", error)
        })
    }, [])

    function handleSearch(e) {
        setLoading(true)
        if (e.target.value.length) {
            setsearchKeyword(e.target.value)
            setIsFilterActive(true)
            setLoading(false)
        } else {
            setsearchKeyword('')
        }
    }

    function handleActive (active_menu) {
        setIsActive(active_menu)
        setRefresh(refresh + 1)
    }

    function handlePagination(url){
        let is_active = isActive === 'subscribed' ? { subscribers: currentUser.cognito_id } : isActive === 'by-me' ? { created_by: currentUser.cognito_id } : {}
        listService.filterByUrl(url, is_active)
        .then(response=>{
            setData(response.results)
            setNext(response.next && response.next)
            setPrev(response.previous && response.previous)
        })
        .catch(error=>{
            console.log("Error loading lists", error)
        })
    }

    function handleCategories(categories) { 
        setSelectedCategories(categories);
        setIsFilterActive(true)
        setRefresh(refresh + 1)
    }

    function handleTagSearch(keyword) {
        if(keyword.length){
           
            let tags = searchTags.filter((tag) => tag.name.toLowerCase().includes(keyword.toLowerCase()))
			setSearchTags(tags)
		} else {
            
            tagService.toolsListTags()
             .then(response => {    
              const sorted = [...response.results].sort((a, b) => a.name > b.name ? 1 : -1)
              setSearchTags(sorted)
           })
            .catch(error => {
               console.log("Error loading list tags", error)
            })
        }
    }

    // Multiselect
    function handleSelect(selected) {
        let tag_ = selected.map( e => e.id)
        setSelectedTags( tag_ );
        setIsFilterActive(true)
        setRefresh(refresh + 1)
    }
    
    function handleReset() {
        setSelectedTags([])
        setSelectedCategories([])
        setSearchTags([])
        setSearchResults([])
        setIsFilterActive(false)
        setRefresh(refresh + 1)
        setsearchKeyword('')
    }

    // add tags
    useEffect(() => {
        if(idList) {
            setLoading(true)
            listService.retrieve(idList)
            .then(response => {
                setTags(response.tags)
                setLoading(false)
            })
            .catch(error=>{
                console.log("Error loading list tags", error)
            })
        }
    },[idList])

    function handleOpenAdd(list_id) {
        setIdList(list_id)
        setIsOpenAddTags(true)
    }

    function handleCloseAdd() {
        setIsOpenAddTags(false)
        setIdList(null)
        setTags([])
    }
    
    function handleAdd() {
        let body = {tags: tags}
        listService.update(body, idList)
        .then(response => {
            setIsOpenAddTags(false)
            setTags([])
            setIdList(null)
            setRefresh(refresh + 1)
        })
    }

    function handleDeleteTag(tag){
        let new_tags = tags.reduce((a, v) => ({ ...a, [v]: v}), {})
        delete new_tags[tag]
        let new_tags_updated = Object.keys({...new_tags})
        setTags(new_tags_updated)
    }

    function handleChange(e){
        setTagInputValue(e.value)
    }

    function handleKeyDown(e){
        if(e.key === 'Enter'){
            let value = e.target.value
            let new_tags = tags
            new_tags.push(value)
            setTags([...new_tags])
            setTagInputValue("")
        }
    }

    let tag_elements
    if (isOpenAddTags) {
        tag_elements = tags.length && tags.map((e, idx) => 
        <React.Fragment>
            <Tag tag={e} delete={true} onDelete={handleDeleteTag}/>
            <input type="hidden" name="tags" value={e}/>
        </React.Fragment> 
        )
    }

    return(
        <React.Fragment>
        <div className="flexer flexer-mobile">
            <div className="flexer-3 center">
                <div className="simple-profile">
                    <ListsFilters 
                        keyword={keyword} 
                        data={data}
                        isActive={isActive}
                        handleSearch={handleSearch}
                        handleActive={handleActive}
                        handleCategories={handleCategories}
                        handleSelect={handleSelect}
                        handleTagSearch={handleTagSearch}
                        searchTags={searchTags}
                        selectedTags={selectedTags}
                        handleReset={handleReset}
                        isFilterActive={isFilterActive}
                        />
                </div>
            </div>
            <div className="flexer-9 center">
                <div className="flexer center-mobile">
                { isLoading==true ? <div className="flexer-3 center">{Spinnerr()}</div>: 
                    <ListsDashboard 
                        keyword={keyword} 
                        data={data}
                        shared={sharedLists}
                        searchResults={searchResults}
                        top={topLists}
                        isActive={isActive}
                        isFilterActive={isFilterActive}
                        next={next}
                        prev={prev}
                        handlePagination={handlePagination}
                        count={count}
                        onUpdate={() => setRefresh(refresh + 1)}
                        currentUser={currentUser}
                        onAdd={handleOpenAdd}
                    />}
                </div>
            </div>
        </div>
        <Modal isOpen={isOpenAddTags} handleClose={handleCloseAdd}>
            <div className="center">
                <p className="heading is-dark">Add tags for your list</p>
                <InputField placeholder="Write a tag and press enter" value={tagInputValue} onKeyDown={handleKeyDown} onChange={handleChange} forceValue={true}/>
                <div className="flexer flexer-wrapper my-20">
                    {tag_elements}
                </div>
                <Button text="Add" class="mt-30 one-third-width blue-btn subheading-light" type="button" onClick={handleAdd} />
            </div>
        </Modal>
    </React.Fragment>
    )
}

export default ListsContent
