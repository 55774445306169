import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Containers
import Content from '../containers/Content'
import Header from '../containers/Header';
import RunWizard from '../components/Wizard/RunWizard';

function LandingCid() {
  const history = useHistory()
  const [isOpenRunWizard, setIsOpenRunWizard] = useState(true)

  const handleClose= () => {
    history.push('/tools')
    setIsOpenRunWizard(false)
  }

  return (
    <React.Fragment>
      <Header />
        <Content>
          <RunWizard isOpen={isOpenRunWizard} handleClose={handleClose} />
        </Content>
    </React.Fragment>
  )
}

export default LandingCid
