import React, { useRef } from "react";
import { Multiselect } from 'multiselect-react-dropdown'


function FilterByTags (props) {

    const multiselectRef = useRef(null)

    if (multiselectRef.current && !props.isFilterActive) { 
        multiselectRef.current.resetSelectedValues()
    }

    return (
        <React.Fragment>
            <div className="">
                <p className='text left ml-10 is-bold'>Tags</p>
                <Multiselect
                    options={props.searchTags}
                    onSearch={(keyword) => props.handleTagSearch(keyword)}
                    displayValue="name" 
                    showCheckbox={true}
                    avoidHighlightFirstOption={true}
                    hidePlaceholder={true}
                    onSelect={props.handleSelect} 
                    onRemove={props.handleSelect}
                    ref={multiselectRef}
                    isFilterActive={props.isFilterActive}
                />
            </div>
        </React.Fragment>
    )
}

export default FilterByTags