import React, { useState} from 'react'


// Components
import Button from '../Elements/Button'
import CreateTool from './CreateTool'
import FilterByCategories from '../Lists/FilterByCategories'
import MenuButton from '../Elements/MenuButton'
import Modal from '../Elements/Modal'
import FilterByTags from '../Lists/PopularTags'
import SearchBar from '../Elements/SearchBar'

function ToolsFilters (props) {
    const { currentUser, data, handleActive, isActive  } = props
    const [isOpen, setIsOpen] = useState(false)

    let tool_data = data ? data : null 
    let is_admin = currentUser.role === 'SUPER_ADMIN' ? true : false

    let menu_tools = null

    if (is_admin) {
        menu_tools =
        <React.Fragment>
            <MenuButton text='Approved Tools' icon="fa fa-check" onClick={()=>{handleActive('approved')}} active={isActive === 'approved' ? true : false} />
            <MenuButton text='To approve Tools' icon="far fa-edit" onClick={()=>{handleActive('to-approve')}} active={isActive === 'to-approve' ? true : false} />
        </React.Fragment>

    }

    return(
        <React.Fragment>
            <div className="simple-profile">
                <div className="simple-profile__sections">
                    <div className='my-20'> 
                        <SearchBar placeholder={"Search by keywords..."} onChange={props.handleSearch} />
                    </div>
                    {menu_tools}

                    <div className="my-20" >
                        <Button class="blue-btn full-width" type="button" text="Add Tool" icon="fa fa-plus is-white" onClick={()=>{setIsOpen(true)}} />
                    </div>
                </div>

                <div className="simple-profile__sections">
                {props.isFilterActive ?
                            <div onClick={ props.handleReset}><p className="text is-light mt-30 mr-20 right cursor"> Reset Filters<i className='is-light fa fa-refresh cursor mx-10' onClick={ props.handleReset}/></p></div>
                            : null}
                    <FilterByCategories 
                        handleCategories={props.handleCategories}
                        isFilterActive={props.isFilterActive}
                    />
                </div>
                <div className="simple-profile__sections">
                    <FilterByTags 
                        handleTagSearch={props.handleTagSearch}
                        searchTags={props.searchTags}
                        handleSelect={props.handleSelect}
                        selectedTags={props.selectedTags}
                        isFilterActive={props.isFilterActive}
                    />
                </div>
                <Modal isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
                    <CreateTool currentUser={currentUser} data={tool_data} onClose={() => setIsOpen(false)} reset={props.onUpdateOrCreate} />
                </Modal>
            </div>

        </React.Fragment>
    )
}

export default ToolsFilters