import {authenticationService} from '.'

// export function authHeader() {
//     // return authorization header with jwt token
//     const currentUser = authenticationService.currentUserValue
//      // cambiar por el contexto de useContext que trae el token despues de llamar al refresh
//     if (currentUser && currentUser.IdToken) {
//         return { Authorization: `Bearer ${currentUser.IdToken}` }
//     } else {
//         return {}
//     }
// }

export async function checkToken() {

    const tokens = authenticationService.currentUserValue
    const expiry = tokens['expiry']
    if (!expiry){
        authenticationService.logout()
    }

    const now = Date.now()/1000
    if (now > expiry) {
        // Refresh
        await authenticationService.refreshToken()
    }
}

export function fixBody(body) {
    if (body instanceof FormData){
        body.forEach((value, key) => {
            if(typeof(value) ===  "string") {
                body.set(key, value.replaceAll("/", "\\/"))
            }
        })
    }
    else{
        for(const [key, value] of Object.entries(body)) {
            if(typeof(value) ===  "string") {
                body[key] = value.replaceAll("/", "\\/")
            }
            else if(typeof(value) ===  "object") {
                body[key] = fixBody(value)
            }
        }
    }

    return body
}

