import { useEffect, useState } from "react";

// Api
import { toolService } from "../../api";

// Components
import Tool from "../Elements/Tool";

function LauchWizard(props) {
  const [selectedTools, setSelectedTools] = useState([]);
  const tools_ids = props.toolsSelected ? props.toolsSelected : null;

  useEffect(() => {
    toolService
      .filter({ id: tools_ids })
      .then((response) => {
        setSelectedTools(response);
      })
      .catch((error) => {
        console.error("Not found", error);
      });
  }, [tools_ids]);

  let user_inputs;
  if (props.data.tool_target === "Real Name") {
    user_inputs = props.userInput && (
      <div className="flexer flexer-wrapper">
        <p className="text is-light mr-30">
          First name:{" "}
          <span className="text is-bold">{props.userInput.firstName}</span>
        </p>
        <p className="text is-light mr-30">
          Middle name:{" "}
          <span className="text is-bold">
            {props.userInput.middleName ?? "-"}
          </span>
        </p>
        <p className="text is-light mr-30">
          Last name:{" "}
          <span className="text is-bold">
            {props.userInput.lastName ?? "-"}
          </span>
        </p>
      </div>
    );
  } else if (props.data.tool_target === "Many") {
    user_inputs = props.userInput && (
      <div className="flexer flexer-wrapper">
        {props.userInput.firstName && (
          <>
            <p className="text is-light mr-30">
              First name:{" "}
              <span className="text is-bold">{props.userInput.firstName}</span>
            </p>
            <p className="text is-light mr-30">
              Middle name:{" "}
              <span className="text is-bold">
                {props.userInput.middleName ?? "-"}
              </span>
            </p>
            <p className="text is-light mr-30">
              Last name:{" "}
              <span className="text is-bold">
                {props.userInput.lastName ?? "-"}
              </span>
            </p>
          </>
        )}
        {props.userInput.email && (
          <p className="text is-light mr-30">
            Email: <span className="text is-bold">{props.userInput.email}</span>
          </p>
        )}
        {props.userInput.phoneNumber && (
          <p className="text is-light mr-30">
            Phone:{" "}
            <span className="text is-bold">{props.userInput.phoneNumber}</span>
          </p>
        )}
        {props.userInput.username && (
          <p className="text is-light mr-30">
            Username:{" "}
            <span className="text is-bold">{props.userInput.username}</span>
          </p>
        )}
        {props.userInput.address && (
          <p className="text is-light mr-30">
            Address:{" "}
            <span className="text is-bold">{props.userInput.address}</span>
          </p>
        )}
      </div>
    );
  } else {
    user_inputs = props.userInput && (
      <div className="flexer flexer-wrapper">
        <p className="text is-light mr-30">
          {" "}
          <span className="text is-bold">{Object.values(props.userInput)}</span>
        </p>
      </div>
    );
  }

  let selected_options;
  if (props.data.tool_target === "Real Name") {
    selected_options =
      props.data.tool_target_inputs &&
      props.data.tool_target_inputs.map((e) => (
        <div className="flexer flexer-wrapper">
          <p className="text is-light mr-30">
            First name: <span className="text is-bold">{e.firstName}</span>
          </p>
          <p className="text is-light mr-30">
            Middle name:{" "}
            <span className="text is-bold">
              {e?.middleName?.length ? e.middleName : "-"}
            </span>
          </p>
          <p className="text is-light mr-30">
            Last name:{" "}
            <span className="text is-bold">
              {e?.lastName?.length ? e.lastName : "-"}
            </span>
          </p>
        </div>
      ));
  }
  if (props.data.tool_target === "Email") {
    selected_options =
      props.data.tool_target_inputs &&
      props.data.tool_target_inputs.map((e) => (
        <div className="flexer flexer-wrapper my-20">
          <p className="text line-margin-0 is-bold mr-30">{e.email}</p>
        </div>
      ));
  }
  if (props.data.tool_target === "Phone") {
    selected_options =
      props.data.tool_target_inputs &&
      props.data.tool_target_inputs.map((e) => (
        <div className="flexer flexer-wrapper my-20">
          <p className="text line-margin-0 is-bold mr-30">{e.phoneNumber}</p>
        </div>
      ));
  }
  if (props.data.tool_target === "Many") {
    selected_options =
      props.data.tool_target_inputs &&
      props.data.tool_target_inputs.map((e) => (
        <>
          <div className="flexer flexer-wrapper my-20">
            {e.firstName && (
              <>
                <p className="text is-light mr-30">
                  First name:{" "}
                  <span className="text is-bold">{e.firstName}</span>
                </p>
                <p className="text is-light mr-30">
                  Middle name:{" "}
                  <span className="text is-bold">
                    {e?.middleName?.length ? e.middleName : "-"}
                  </span>
                </p>
                <p className="text is-light mr-30">
                  Last name:{" "}
                  <span className="text is-bold">
                    {e?.lastName?.length ? e.lastName : "-"}
                  </span>
                </p>
              </>
            )}
            {e.email && (
              <p className="text is-light mr-30">
                Email: <span className="text is-bold">{e.email}</span>
              </p>
            )}
            {e.phoneNumber && (
              <p className="text is-light mr-30">
                Phone: <span className="text is-bold">{e.phoneNumber}</span>
              </p>
            )}
            {e.username && (
              <p className="text is-light mr-30">
                Username: <span className="text is-bold">{e.username}</span>
              </p>
            )}
            {e.address && (
              <p className="text is-light mr-30">
                Address: <span className="text is-bold">{e.address}</span>
              </p>
            )}
          </div>
        </>
      ));
  }

  let selected_tools =
    selectedTools &&
    selectedTools.map((e) => {
      return (
        <>
          <Tool data={e} key={e.id} />
        </>
      );
    });

  let tab_number = props.data.tools && props.data.tools.length;
  if (props.data?.tool_target_inputs && props.data.tool_target_inputs.length) {
    tab_number =
      (props.data.tool_target_inputs.length + 1) * props.data.tools.length;
  }

  return (
    <>
      <div className="">
        <h1 className="title-l line-margin-s">Hunting wizard</h1>
        <div className="center">
          <div className="form-wrapper left">
            <p className="title-3">Confirm to launch Wizard</p>
            <div className="mb-20 mt-30">
              <p className="heading is-light">
                Starting Point:{" "}
                <span className="text is-bold"> {props.data.tool_target}</span>
              </p>
              {user_inputs}
            </div>
            {props.data.tool_target_inputs &&
              props.data.tool_target_inputs.length >= 1 && (
                <div className="my-20">
                  <div className="wizzard-resume-line" />
                  <p className="heading is-light">Your chosen options:</p>
                  <div className="my-20">{selected_options}</div>
                </div>
              )}
            <div className="wizzard-resume-line" />

            <div className="my-20">
              <p className="heading is-light">Tools selected:</p>
              <div className="flexer flexer-wrapper">{selected_tools}</div>
            </div>

            <p className="text is-light">
              This action will open{" "}
              <span className="text is-ligth is-bold">{tab_number}</span> new
              tabs in your browser
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LauchWizard;
