import React, { useState } from 'react'

// Components
import ListCard from '../Lists/ListCard'
import Tool from '../Elements/Tool'
import Modal from '../Elements/Modal'

// Containers
import ReviewContent from '../../containers/Content/ToolsContent'

function TagsDashboard(props) {
    const { dataLists, dataTools,nextLists, nextTools, prevLists, prevTools, handlePaginationLists, handlePaginationTools, countLists, countTools } = props

    const [isOpen, setIsOpen] = useState(false)
    const [refresh, setRefresh] = useState(1)
    const [toolId, setToolId] = useState(null)

    function handleClick(tool_id) {
        setToolId(tool_id)
        setIsOpen(true)
    }

    function handleClose() {
        setIsOpen(false)
        setRefresh(refresh + 1)
        props.onUpdate()
    }

    if (dataLists) {
        var data_lists = dataLists.map((e, idx) => 
        <ListCard data={e} key={idx} />
        )
        
        if(data_lists.length === 0) {
            data_lists = <p>No lists found with this tag. Try a different one.</p>
        }
    }

    if (dataTools) {
        var data_tools = dataTools.map((e, idx) => 
            (<div className='my-30 cursor' key={idx} onClick={() => handleClick(e.id)}>
                <Tool data={e}  />
            </div>)
        )

        if(data_tools.length === 0) {
            data_tools = <p>No tools found with this tag. Try a different one.</p>
        }
    }
    return (
        <React.Fragment>
            <div className='full-width'>
            <div className="simple-profile__sections">
            <div className="flexer left flexer-mobile">
                            <div className='full-width'>
                                <p className="subtitle left is-bold">Lists<span className="text is-light ml-10"></span> </p>
                                {data_lists}
                            </div>
                        </div>
                        <div className="flexer right">
                            {prevLists? <p className="subheading is-dark button blue-btn cursor" onClick={()=>handlePaginationLists(prevLists)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
                            <p className="subheading is-light mx-10"></p>
                            {nextLists? <p className="subheading is-dark button blue-btn cursor" onClick={()=>handlePaginationLists(nextLists)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
                        </div>
            </div>
            <div className="simple-profile__sections">
                <div className="flexer left flexer-mobile">
                    <div className='full-width'>
                        <p className="subtitle left is-bold mb-30">Tools<span className="text is-light ml-10"></span> </p>
                        <div className="flexer flexer-wrapper flexer-mobile mr-40">
                                { data_tools }
                        </div>
                    </div>
                </div>
                    <div className="flexer right">
                        {prevTools? <p className="subheading is-dark button blue-btn cursor" onClick={()=>handlePaginationTools(prevTools)}><i className="fa fa-arrow-left mr-10 is-white"></i>Previous</p> : null}
                        <p className="subheading is-light mx-10"></p>
                        {nextTools? <p className="subheading is-dark button blue-btn cursor" onClick={()=>handlePaginationTools(nextTools)}>Next<i className="fa fa-arrow-right ml-10 is-white"></i></p> : null}
                    </div>
                </div>
                <Modal reviews={true} isOpen={isOpen} handleClose={handleClose}>
                    <ReviewContent element={'tool'} element_id={toolId} onUpdate={() => setRefresh(refresh + 1)} />
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default TagsDashboard