import React, {useState} from 'react'
import SwitchButton from "react-switch"

function Switch(props) {
    
    const [checkbox, setCheckbox] = useState(false)

    function handleChange(e){
        setCheckbox(!checkbox)
    }
    return (
        <React.Fragment>
            <label className="flexer flexer-vcenter">
                <SwitchButton onChange={handleChange} checked={checkbox} height={20} width={40} handleDiameter={15} onColor="#0065FD" />
                <span className="text is-dark mx-10">{props.label}</span>
                <input type="hidden" name={props.name} value={checkbox} />
            </label>
        </React.Fragment>
    )
}

export default Switch