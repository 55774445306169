import config from './config';
import { handleResponse } from './handle-response';
import { fixBody } from './helpers'

//mixpanel 
import { mixpanelService } from '../api/mixpanel-service.js'

export const listService = {
    list,
    listByMe,
    subscribe,
    unsubscribe,
    listTop,
    listByUrl,
    retrieve,
    search,
    searchByTag,
    filter,
    filterByUrl,
    create,
    update,
    remove,
    add_tools,
    remove_tools,
    listsShared,
    listSharing,
    acceptShared,
    denyShared,
}

async function list() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function listByMe() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/my_tool_lists/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function subscribe(list_id) {
    const requestOptions = { method: 'POST', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/subscribe/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function unsubscribe(list_id) {
    const requestOptions = { method: 'POST', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/unsubscribe/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function listTop() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/top/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function listByUrl(url) {
	const requestOptions = {method: 'GET', credentials: 'include'}
	return fetch(url, requestOptions)
	.then(handleResponse)
	.then(list => {
			return list
	})
}

async function filterByUrl(url, body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body:JSON.stringify(body)}
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function retrieve(list_id) {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function search(keyword) {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/search/?keyword=${keyword}`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function searchByTag(tag) {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list/search_by_tag/?tag=${tag}`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function filter(body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body:JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_list/filters/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function create(body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_list/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            mixpanelService.ToolListAdded(body)
            return list
        })
}

async function update(body, list_id) {
    body = fixBody(body)
    const requestOptions = { method: 'PATCH', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function remove(list_id) {
    const requestOptions = {method: 'DELETE', credentials: 'include'}
    
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function add_tools(list_id, body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/add_tools/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function remove_tools(body, list_id) {
    const requestOptions = {method: 'DELETE', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    
    return fetch(`${config.apiUrl}/cid/tools_list/${list_id}/delete_tool/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function listsShared() {
    const requestOptions = { method: 'GET', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list_invitation/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function listSharing(body) { 
    body = fixBody(body)
    const requestOptions = { method: 'POST', credentials: 'include', headers:{'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    return fetch(`${config.apiUrl}/cid/tools_list_invitation/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function acceptShared(invitation_id) {
    const requestOptions = { method: 'POST', credentials: 'include'}
    return fetch(`${config.apiUrl}/cid/tools_list_invitation/${invitation_id}/accept/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}

async function denyShared(invitation_id) {
    const requestOptions = {method: 'DELETE', credentials: 'include'}
    
    return fetch(`${config.apiUrl}/cid/tools_list_invitation/${invitation_id}/`, requestOptions)
        .then(handleResponse)
        .then(list => {
            return list
        })
}
