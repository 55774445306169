import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Pagination } from '@mui/material'

import Modal from '../Elements/Modal'
import Tool from '../Elements/Tool'
import ReviewContent from '../../containers/Content/ReviewContent'
import _ from 'lodash'


function ToolsDashboard(props) {
  const { 
    currentUser,
    header_keyword,
    data,
    isActive,
    isFilterActive,
    top,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [refresh, setRefresh] = useState(1)
  const [toolId, setToolId] = useState(null)
  
  const { tool_id } = useParams()

  useEffect(() => {
    if (tool_id) { // tool detail from header searchbar
      setToolId(tool_id)
      setIsOpen(true)
    }
  }, [tool_id])

  let sections = []

  for (const [sectionKey, sectionArr] of Object.entries(data)){
    if(sectionArr.length > 0) {
      sections.push([
        <p className="subtitle left is-bold mb-30">{sectionKey} ({sectionArr.length})</p>,
        <div className="flexer flexer-wrapper flexer-mobile wcc-tools">{sectionArr}</div>,
      ])
    }
  }


  if (top) {
    var top_tools = top.slice(0, 10).map((e, idx) =>
    (<div className='cursor wcc-tool' key={idx} >
      <Tool data={e}   onUpdate={props.onUpdate} />
      {/* <Tool data={e}   onUpdate={() => {}} /> */}
    </div>)
    )
  }

  let tool_title = 'All tools'

  if (currentUser.role === 'SUPER_ADMIN') {
    if (isActive === 'approved') {
      tool_title = 'Approved tools'
    } else if (isActive === "to-approve") {
      tool_title = 'Tools to approve'
    } else {
      tool_title = 'All tools'
    }
  }

  return (
    <div className='full-width'>
      {(isActive === 'approved' && !isFilterActive && !header_keyword) &&
        <div className="simple-profile__sections">
          <div className='ml-20'>
            <p className="subtitle left is-bold mb-30">Top Tools</p>    
            <div className="flexer flexer-wrapper flexer-mobile wcc-tools">
              {top_tools}
            </div>
          </div>
        </div>
      }

      <div className="simple-profile__sections">
        <div className="flexer left flexer-mobile">
          <div className='ml-20'>
            <p className="subtitle left is-bold mb-30">{tool_title}<span className="text is-light ml-10"></span> </p>
              {(isActive === "to-approve" && !Object.entries(data).length) && <span className="text is-light my-30">Found no tools to approve</span>}
              {sections}
          </div>
        </div>

      </div>

      <Modal reviews={true} isOpen={isOpen} handleClose={()=>{setIsOpen(false)}}>
          <ReviewContent
          element={'tool'}
          element_id={toolId}
          onUpdate={() => setRefresh(refresh + 1)}
          onClose={()=>{setIsOpen(false)}} />
      </Modal>
    </div>
  )
}

export default ToolsDashboard
